import env from "../../config/env.json";
import APIFetch from "../../utils/APIFetch";
import { ExerciseType, ImageType, ScenarioType } from "./unit";

// export const getImages = async () => {
//   var imageList = await fetch(env.backend_host + "/image/images", {
//     "method": "GET",
//     "mode": "cors",
//     "credentials": "include"
//   }).then(res => res.json())
//   return imageList
// };

export const PutImageAPI = async (
  file: string,
  exerciseId: string
): Promise<ExerciseType> => {
  var data = new FormData();
  data.append("files", file);
  console.log(data);

  var req = await fetch(env.backend_host + "/image/exercise/" + exerciseId, {
    credentials: "include",
    // headers: {
    //     'Content-Type': 'multipart/mixed',
    //     'Accept': '*/*'
    // },
    body: data,
    method: "PUT",
    mode: "cors",
  });

  var json = await req.json();

  // var json = APIFetch(env.backend_host + "/image/exercise/" + exerciseId, {
  //   "body": data,
  //   "method": "PUT",
  // })

  return json;
};

export const PutImageScenarioAPI = async (
  file: string,
  scenarioId: string
): Promise<ScenarioType> => {
  var data = new FormData();
  data.append("files", file);
  console.log(data);

  var req = await fetch(env.backend_host + "/image/scenario/" + scenarioId, {
    credentials: "include",
    // headers: {
    //     'Content-Type': 'multipart/mixed',
    //     'Accept': '*/*'
    // },
    body: data,
    method: "PUT",
    mode: "cors",
  });

  var json = await req.json();

  // var json = APIFetch(env.backend_host + "/image/scenario/" + scenarioId, {
  //   body: data,
  //   method: "PUT",
  // });

  return json;
};
