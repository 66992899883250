import "./MUP.css";
import { useEffect, useState } from "react";
import { IscreenSize } from "../../../interfaces";
import { useAppSelector } from "../../../hooks/hooks";
import MUPLocal from "./MUPLocal";
import MUPGlobal from "./MUPGlobal";

const MUP = ({ ...props }) => {
  const [screenSize, getDimension] = useState<IscreenSize>({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });

  const loginState = useAppSelector((state) => state.login);

  const IS_TEACHER = loginState.type === "teacher";

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  return (
    <div className="mup_back_image">
      <div
        className="mup_canvas"
        style={
          (screenSize.dynamicHeight * 16) / 9 < screenSize.dynamicWidth
            ? {
                height: screenSize.dynamicHeight,
              }
            : {
                width: screenSize.dynamicWidth,
              }
        }
      >
        {props.localMap && (
          <MUPLocal
            biomeName={props.biomeName}
            studentId={props.localMapStudentId}
            campusHandler={props.campusHandler}
            energyLabHandler={props.energyLabHandler}
            toggleMap={props.localHandler}
            workshopHandler={props.workshopHandler}
            homeHandler={props.homeHandler}
            challengesHandler={props.challengesHandler}
            ownerId={loginState.id}
          />
        )}
        {!props.localMap && (
          <MUPGlobal
            myId={loginState.id}
            biomeName={props.biomeName}
            IS_TEACHER={IS_TEACHER}
            toggleMap={(studentId: string, plotId: string) =>
              props.localHandler(studentId, plotId)
            }
          />
        )}
      </div>
    </div>
  );
};

export default MUP;
