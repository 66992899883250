import "./DragDropArrows.css";
import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import {
  Arrows,
  Background,
  ConfirmButton,
  ExerciseName,
  HelpMenu,
  Professor,
  TextBox,
} from "./_components";
import longArrow from "../../../assets/exercises/long_arrow.svg";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Idirection, IscreenSize } from "../../../interfaces";
import { ExerciseType } from "../../../_newapios/content/unit";
import _ from "lodash";
import { PlayFunction } from "use-sound/dist/types";
import { useAppSelector } from "../../../hooks/hooks";

const DragDropArrows = (props: {
  exercise: ExerciseType;
  totalExercises: number;
  exerciseIndex: number;
  maxExercise: number;
  research: boolean;
  rightAnswerSound: PlayFunction;
  wrongAnswerSound: PlayFunction;
  updateLevel: (
    direction: Idirection,
    answer: string[] | null,
    correct: boolean | null
  ) => void;
  incorrectHandler: () => void;
  tipHandler: () => void;
}) => {
  const [screenSize, getDimension] = useState<IscreenSize>({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });

  const isTeacher = useAppSelector((state) => state.login).type === "teacher";

  const [answers, setAnswers] = useState<string[][]>([[], [], [], [], []]);

  const text = props.exercise.text;
  const helpText = props.exercise.help_text;
  const solutions = props.exercise.solutions as number[];
  const labels = props.exercise.labels as string[];
  const options = props.exercise.options as string[];

  const [selectedExercise, setselectedExercise] = useState(props.exerciseIndex);
  const [maxExercise, setmaxExercise] = useState(props.maxExercise);

  const [research, setResearch] = useState(props.research);
  const [incorrectFeedback, setIncorrectFeedback] = useState(false);
  const [incorrectIndexes, setIncorrectIndexes] = useState<string[]>([]);

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  useEffect(() => {
    orderAnswers();
  }, [selectedExercise, maxExercise]);

  useEffect(() => {
    setselectedExercise(props.exerciseIndex);
    setmaxExercise(props.maxExercise);
  }, [props.exerciseIndex]);

  const orderAnswers = () => {
    var answers0 = [];
    var answers1 = [];
    for (var i = 0; i < options.length; i++) {
      if (solutions[i] > 0) {
        answers0.push(options[i]);
      } else if (solutions[i] < 0) {
        answers1.push(options[i]);
      }
    }
    var orderedAnswers = [answers0, answers1];
    if (props.research) {
      if (selectedExercise < maxExercise) {
        setAnswers(distributeSolution(orderedAnswers));
      } else {
        setAnswers(distributeOptions());
      }
    } else {
      if (isTeacher) {
        setAnswers(distributeSolution(orderedAnswers));
      } else {
        setAnswers(distributeOptions());
      }
    }
  };

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  const distributeOptions = () => {
    var temp_options = [...options];
    var newAnswers: any[] = [[], [], [], [], []];
    var j = 2;
    const shuffledOptions = _.shuffle(temp_options);
    for (var i = 0; i < shuffledOptions.length; i++) {
      if (j === 4) {
        newAnswers[j].push(shuffledOptions[i]);
        j = 2;
      } else {
        newAnswers[j].push(shuffledOptions[i]);
        j++;
      }
    }
    return newAnswers;
  };

  const distributeSolution = (orderedAnswers: string[][]) => {
    var newSolution: any[] = [[], [], [], [], []];
    newSolution[0] = orderedAnswers[0];
    newSolution[1] = orderedAnswers[1];
    return newSolution;
  };

  const confirmHandler = () => {
    var newAnswers = [...answers];
    var correct = true;
    var index0: number;
    var index1: number;
    var wrongIndex0: string[] = [];
    var wrongIndex1: string[] = [];

    // THIS CODE IS ONLY TO SAVE THE OPTIONS OF THE WRONG ANSWERS
    var answers0 = [];
    var answers1 = [];
    for (var i = 0; i < options.length; i++) {
      if (solutions[i] > 0) {
        answers0.push(options[i]);
      } else if (solutions[i] < 0) {
        answers1.push(options[i]);
      }
    }
    ////////////////////////////////////////////////////////////

    if (
      newAnswers[2].length > 0 ||
      newAnswers[3].length > 0 ||
      newAnswers[4].length > 0
    ) {
      correct = false;
    }

    // Check if both option arrays are the same length
    if (newAnswers[0].length !== newAnswers[1].length) {
      correct = false;
    }

    // In this case, options[0] and options[1] are the same length so one loop is enough
    for (var i = 0; i < newAnswers[0].length; i++) {
      index0 = solutions[options.indexOf(newAnswers[0][i])];
      index1 = solutions[options.indexOf(newAnswers[1][i])];

      // Check if both answers are in the correct table
      if (index0 === undefined || index1 === undefined) {
        correct = false;
        break;
      }

      if (index0 < 0 || index1 > 0) {
        correct = false;
        wrongIndex0.push(answers0[Math.abs(index0) - 1]);
        wrongIndex1.push(answers1[Math.abs(index1) - 1]);

        // break;
      }

      // Check if both indices are the same
      if (Math.abs(index0) !== Math.abs(index1)) {
        correct = false;
        wrongIndex0.push(answers0[Math.abs(index0) - 1]);
        wrongIndex1.push(answers1[Math.abs(index1) - 1]);

        // break;
      }
    }

    if (research) {
      if (correct) {
        props.updateLevel("right", newAnswers[0].concat(newAnswers[1]), true);
        props.rightAnswerSound();
      } else {
        props.incorrectHandler();
        props.wrongAnswerSound();
        setIncorrectFeedback(true);
        setIncorrectIndexes(wrongIndex0.concat(wrongIndex1));
      }
    } else {
      if (correct) {
        props.updateLevel("right", newAnswers[0].concat(newAnswers[1]), true);
      } else {
        props.updateLevel("right", newAnswers[0].concat(newAnswers[1]), false);
      }
    }
  };

  const updateDashboard = (
    srcC: string,
    desC: string,
    srcI: number,
    desI: number
  ) => {
    let copiedDashboards = [...answers];
    if (srcC === desC) {
      const [removed] = copiedDashboards[parseInt(srcC)].splice(srcI, 1);
      copiedDashboards[parseInt(srcC)].splice(desI, 0, removed);
    } else {
      copiedDashboards[parseInt(desC)].splice(
        desI,
        0,
        copiedDashboards[parseInt(srcC)][srcI]
      );
      copiedDashboards[parseInt(srcC)].splice(srcI, 1);
    }

    setAnswers(copiedDashboards);
  };

  const renderDragDrop = () => {
    return (
      <div className="drag_drop_arrows_background">
        <DragDropContext
          onDragEnd={(param) => {
            const srcC = param.source.droppableId.slice(-1);
            const desC =
              param.destination === null || param.destination === undefined
                ? param.source.droppableId.slice(-1)
                : param.destination.droppableId.slice(-1);

            const srcI = param.source.index;
            const desI =
              param.destination === null || param.destination === undefined
                ? param.source.index
                : param.destination.index;

            updateDashboard(srcC, desC, srcI, desI);
          }}
        >
          <div className="drag_drop_arrows_top">
            <Droppable key={"droppable-0"} droppableId={"droppable-0"}>
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="drag_drop_arrows_droppable_0"
                >
                  <div className="drag_drop_arrows_label">
                    <div className="center_container">
                      <div
                        className={
                          isTeacher
                            ? "text_white_small_bold"
                            : "text_white_medium_bold"
                        }
                      >
                        {labels[0]}
                      </div>
                    </div>
                  </div>
                  {answers[0].map((item, i) => (
                    <Draggable
                      key={"draggable-0" + item + i}
                      draggableId={"draggable-0" + item + i}
                      index={i}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="drag_drop_arrows_draggable"
                          style={{
                            top: 2 + 4 * i + "%",
                            ...provided.draggableProps.style,
                            backgroundColor:
                              incorrectFeedback === true
                                ? incorrectIndexes.includes(item)
                                  ? "#F6A495"
                                  : ""
                                : "",
                          }}
                        >
                          <motion.div
                            className="center_container"
                            whileHover={{ scale: 1.04 }}
                            transition={{
                              type: "spring",
                              stiffness: 400,
                              damping: 10,
                            }}
                          >
                            <div
                              className={
                                isTeacher
                                  ? item.length < 20
                                    ? "text_black_very_small_bold"
                                    : "text_black_super_small_bold"
                                  : item.length < 20
                                  ? "text_black_small_bold"
                                  : "text_black_very_small_bold"
                              }
                            >
                              {item}
                            </div>
                          </motion.div>
                        </div>
                      )}
                    </Draggable>
                  ))}

                  {provided.placeholder}
                  <div className="draggable_background_color">
                    {_.fill(Array(options.length / 2), 0).map((item, i) => (
                      <div
                        className="draggable_color"
                        style={{ top: 5 + 4 * i + "%" }}
                      ></div>
                    ))}
                  </div>
                </div>
              )}
            </Droppable>

            <div className="drag_drop_arrows_middle">
              {_.fill(Array(options.length / 2), 0).map((item, i) => (
                <div className="drag_drop_arrows_middle_containers">
                  <img
                    className="icon"
                    src={longArrow}
                    alt="long"
                    style={{ maxWidth: "90%" }}
                  />
                </div>
              ))}
            </div>

            <Droppable droppableId={"droppable-1"} key={"droppable-1"}>
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="drag_drop_arrows_droppable_1"
                >
                  <div className="drag_drop_arrows_label">
                    <div className="center_container">
                      <div
                        className={
                          isTeacher
                            ? "text_white_small_bold"
                            : "text_white_medium_bold"
                        }
                      >
                        {labels[1]}
                      </div>
                    </div>
                  </div>
                  {answers[1].map((item, i) => (
                    <Draggable
                      key={"draggable-1" + item + i}
                      draggableId={"draggable-1" + item + i}
                      index={i}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="drag_drop_arrows_draggable"
                          style={{
                            top: 2 + 4 * i + "%",
                            ...provided.draggableProps.style,
                            backgroundColor:
                              incorrectFeedback === true
                                ? incorrectIndexes.includes(item)
                                  ? "#F6A495"
                                  : ""
                                : "",
                          }}
                        >
                          <motion.div
                            className="center_container"
                            whileHover={{ scale: 1.04 }}
                            transition={{
                              type: "spring",
                              stiffness: 400,
                              damping: 10,
                            }}
                          >
                            <div
                              className={
                                isTeacher
                                  ? item.length < 20
                                    ? "text_black_very_small_bold"
                                    : "text_black_super_small_bold"
                                  : item.length < 20
                                  ? "text_black_small_bold"
                                  : "text_black_very_small_bold"
                              }
                            >
                              {item}
                            </div>
                          </motion.div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                  <div className="draggable_background_color">
                    {_.fill(Array(options.length / 2), 0).map((item, i) => (
                      <div
                        className="draggable_color"
                        style={{ top: 5 + 4 * i + "%" }}
                      ></div>
                    ))}
                  </div>
                </div>
              )}
            </Droppable>
          </div>

          <div className="drag_drop_arrows_bottom">
            <Droppable droppableId={"droppable-2"} key={"droppable-2"}>
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="drag_drop_arrows_droppable_2"
                >
                  {answers[2].map((item, i) => (
                    <Draggable
                      key={"draggable-2" + item + i}
                      draggableId={"draggable-2" + item + i}
                      index={i}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="drag_drop_arrows_draggable_bottom"
                          style={{
                            top: 7 + 5.5 * i + "%",
                            ...provided.draggableProps.style,
                          }}
                        >
                          <motion.div
                            className="center_container"
                            whileHover={{ scale: 1.04 }}
                            transition={{
                              type: "spring",
                              stiffness: 400,
                              damping: 10,
                            }}
                          >
                            <div
                              className={
                                isTeacher
                                  ? item.length < 20
                                    ? "text_black_very_small_bold"
                                    : "text_black_super_small_bold"
                                  : item.length < 20
                                  ? "text_black_small_bold"
                                  : "text_black_very_small_bold"
                              }
                            >
                              {item}
                            </div>
                          </motion.div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>

            <Droppable droppableId={"droppable-3"} key={"droppable-3"}>
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="drag_drop_arrows_droppable_2"
                >
                  {answers[3].map((item, i) => (
                    <Draggable
                      key={"draggable-3" + item + i}
                      draggableId={"draggable-3" + item + i}
                      index={i}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="drag_drop_arrows_draggable_bottom"
                          style={{
                            top: 7 + 5.5 * i + "%",
                            ...provided.draggableProps.style,
                          }}
                        >
                          <motion.div
                            className="center_container"
                            whileHover={{ scale: 1.04 }}
                            transition={{
                              type: "spring",
                              stiffness: 400,
                              damping: 10,
                            }}
                          >
                            <div
                              className={
                                isTeacher
                                  ? item.length < 20
                                    ? "text_black_very_small_bold"
                                    : "text_black_super_small_bold"
                                  : item.length < 20
                                  ? "text_black_small_bold"
                                  : "text_black_very_small_bold"
                              }
                            >
                              {item}
                            </div>
                          </motion.div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>

            <Droppable droppableId={"droppable-4"} key={"droppable-4"}>
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="drag_drop_arrows_droppable_2"
                >
                  {answers[4].map((item, i) => (
                    <Draggable
                      key={"draggable-4" + item + i}
                      draggableId={"draggable-4" + item + i}
                      index={i}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="drag_drop_arrows_draggable_bottom"
                          style={{
                            top: 7 + 5.5 * i + "%",
                            ...provided.draggableProps.style,
                          }}
                        >
                          <motion.div
                            className="center_container"
                            whileHover={{ scale: 1.04 }}
                            transition={{
                              type: "spring",
                              stiffness: 400,
                              damping: 10,
                            }}
                          >
                            <div
                              className={
                                isTeacher
                                  ? item.length < 20
                                    ? "text_black_very_small_bold"
                                    : "text_black_super_small_bold"
                                  : item.length < 20
                                  ? "text_black_small_bold"
                                  : "text_black_very_small_bold"
                              }
                            >
                              {item}
                            </div>
                          </motion.div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        </DragDropContext>
      </div>
    );
  };

  return (
    <div className="drag_drop_arrows">
      <Background
        incorrectFeedback={incorrectFeedback}
        setIncorrectFeedback={setIncorrectFeedback}
      />
      <Professor width={screenSize.dynamicWidth} />
      <TextBox text={text} contractTextBox={true} />
      <Arrows
        selectedExercise={selectedExercise}
        maxExercise={maxExercise}
        updateLevel={props.updateLevel}
        research={isTeacher ? true : research}
      />
      <ConfirmButton confirmHandler={confirmHandler} />
      <HelpMenu helpText={helpText} tipHandler={props.tipHandler} />
      <ExerciseName
        type={props.exercise.type}
        smallMargin={true}
        imageExists={true}
      />
      {renderDragDrop()}
    </div>
  );
};

export default DragDropArrows;
