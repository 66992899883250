import "./ProfessorEvaluationGeneralInfo.css";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { LazyMotion, m, motion } from "framer-motion";
import RadarChart from "./RadarChart";
import { setEvaluationMenu } from "../../../../reducers/teacherSlice";
import IconoAsignaturas from "../../../../assets/professor/IconoAsignaturas.tsx";
import { colors, simpleGlobalCompetences } from "../../../../constants";
import { GlobalCompetencePoints } from "../../../../_newapios/progress/student_global_competences.ts";
import { getGlobalCompetenceAggregates } from "../../../utils/evaluation.ts";
import { gl } from "date-fns/locale";
import _ from "lodash";
import { StudentCoursePoints } from "../../../../_newapios/progress/student_course_points.ts";
import { CourseType } from "../../../../_newapios/content/course.ts";
import { setSelectedCourseId } from "../../../../reducers/mainSlice.ts";
import { BundleType } from "../../../../_newapios/user/class.ts";
import SchoolIcon from "../../../../assets/professor/school.tsx";
import StarIcon from "../../../../assets/professor/star.tsx";
import { calc } from "@chakra-ui/react";
import { calculateAccessCourse } from "../../../../utils/licenses.ts";

const ProfessorEvaluationGeneralInfo = ({ ...props }) => {
  const { t } = useTranslation(["common", "evaluation"]);

  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });

  const dispatch = useAppDispatch();

  const teacherSlice = useAppSelector((state) => state.teacher);

  const student = teacherSlice.selectedStudent;
  const classInfo = teacherSlice.selectedClass;
  const userLicense = teacherSlice.userLicense;
  const loadFeatures = () =>
    import("../../../../hooks/framerMotionHooks.ts").then((res) => res.default);

  const [globalCompetences, setGlobalCompetences] =
    useState<GlobalCompetencePoints>({});
  const [schoolCompetences, setSchoolCompetences] =
    useState<GlobalCompetencePoints>({});
  const [coursePoints, setCoursePoints] = useState<StudentCoursePoints>({});
  const [studentCourses, setStudentCourses] = useState<CourseType[]>([]);
  const restrictGlobalComp = !userLicense?.license.allow_global_competences;
  const [showHoverText, setShowHoverText] = useState("none");

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    setGlobalCompetences(props.globalCompetences);
    setSchoolCompetences(props.schoolCompetences);
    setCoursePoints(props.coursePoints);
    setStudentCourses(props.studentCourses);
  }, [
    student,
    props.globalCompetences,
    props.schoolCompetences,
    props.coursePoints,
  ]);

  const globalCompetencesHandler = () => {
    dispatch(setEvaluationMenu("globalCompetences"));
  };

  const curricularContentHandler = (courseId: string) => {
    dispatch(setEvaluationMenu("curricularContent"));
    dispatch(setSelectedCourseId(courseId));
  };

  const renderLeft = () => {
    if (
      classInfo?.regulation_id === "regulation_GB" ||
      classInfo?.regulation_id === "regulation_GMyS"
    ) {
      return null;
    } else {
      return (
        <div className="professor_evaluation_general_info_left">
          {renderGlobalCompetences()}
        </div>
      );
    }
  };

  const renderRight = () => {
    return (
      <div className="professor_evaluation_general_info_right">
        {renderCurricularContent()}
      </div>
    );
  };

  const renderGlobalCompetences = () => {
    const labels = !_.isEmpty(globalCompetences)
      ? Object.keys(getGlobalCompetenceAggregates(globalCompetences)).map(
        (str) => t(str + "_label", { ns: "evaluation" })
      )
      : simpleGlobalCompetences.map((gc) =>
        t(gc[0] + "_label", { ns: "evaluation" })
      );
    const studentData = !_.isEmpty(globalCompetences)
      ? Object.values(getGlobalCompetenceAggregates(globalCompetences))
      : _.fill(Array(simpleGlobalCompetences.length), 0);

    const schoolData = !_.isEmpty(schoolCompetences)
      ? Object.values(getGlobalCompetenceAggregates(schoolCompetences))
      : _.fill(Array(simpleGlobalCompetences.length), 0);

    let baselinedStudGlComp: number[] = [];
    let baselinedSchoolGlComp: number[] = [];

    studentData.forEach((spComp) => {
      if (spComp > 12500) {
        baselinedStudGlComp.push(12499);
      } else {
        baselinedStudGlComp.push(spComp);
      }
    });

    schoolData.forEach((spComp) => {
      if (spComp > 12500) {
        baselinedSchoolGlComp.push(12499);
      } else {
        baselinedSchoolGlComp.push(spComp);
      }
    });
    // Hardcoded student data when restrictGlobalComp is not true
    const hardcodedStudentData = [
      1000, 5000, 8000, 2000, 3500, 4000, 1000, 7500,
    ];

    return (
      <LazyMotion features={loadFeatures}>
        <m.div
          className="professor_evaluation_general_info_global_competences"
          onClick={
            restrictGlobalComp
              ? () => {
                window.open("https://zynergic.education/eutopia-precios/#5");
              }
              : () => globalCompetencesHandler()
          }
          style={{ display: "inline-block" }}
          whileHover={{ scale: 1.02, boxShadow: "#5090F0 0px 0px 10px 2px" }}
          transition={{ type: "spring", stiffness: 400, damping: 10 }}
          onMouseEnter={() => setShowHoverText("global_comp")}
          onMouseLeave={() => setShowHoverText("none")}
        >
          {showHoverText === "global_comp" && <div className="professor_evaluation_general_info_global_competences_hover">
            <div className="text_white_small_bold">{t("see_detail_global_comp_hover")}</div>
          </div>}
          <div className="professor_evaluation_general_info_title">
            <div className="left_container">
              <div
                className="text_black_small_bold"
                style={{ textDecoration: "underline" }}
              >
                {t("global_competences")}
              </div>
              <div style={{ width: "5px" }} />
              {restrictGlobalComp ? (
                <SchoolIcon size={16} color={"#064187"} />
              ) : null}
            </div>
          </div>
          <div
            className="professor_evaluation_general_info_left_octagon"
            style={
              restrictGlobalComp
                ? { filter: "blur(4px)" }
                : { filter: "blur(0)" }
            }
            ref={props.ref}
          >
            <RadarChart
              // max={Math.max(12500, ...studentData, ...schoolData)}
              max={12500}
              full={false}
              labels={labels}
              studentData={
                restrictGlobalComp ? hardcodedStudentData : baselinedStudGlComp
              }
              schoolData={baselinedSchoolGlComp}
            // studentData={
            //   restrictGlobalComp ? hardcodedStudentData : studentData
            // }
            // schoolData={schoolData}
            />
          </div>
          {restrictGlobalComp && (
            <div className="center_container">
              <motion.div
                whileHover={{
                  scale: 1.05,
                  boxShadow: "0px 0px 10px 0px #5090F0",
                }}
                transition={{ type: "spring", stiffness: 400, damping: 10 }}
                style={{
                  cursor:
                    'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                }}
                className="professor_evaluation_general_info_left_freemium_button"
                onClick={() => {
                  window.open("https://zynergic.education/eutopia-precios/#5");
                }}
              >
                <div className="center_container">
                  <SchoolIcon size={16} color={"white"} />
                  <div style={{ width: "5px" }} />
                  <div
                    className="text_white_really_small_bold"
                    style={{ lineHeight: "1em" }}
                  >
                    {t("freemium_global_competences")}
                  </div>
                </div>
              </motion.div>
            </div>
          )}
        </m.div>
      </LazyMotion>
    );
  };

  const renderCurricularContent = () => {
    return (
      <div
        className="professor_evaluation_general_info_right_content"
        key={"content"}
      >
        <div className="professor_evaluation_general_info_title">
          <div className="left_container">
            <div
              className="text_black_small_bold"
              style={{ textDecoration: "underline" }}
            >
              {t("subjects")}
            </div>
            <div style={{ width: "5px" }} />
            {userLicense?.license.course_evaluate_number !== -1 ? (
              <StarIcon size={18} />
            ) : null}
          </div>
        </div>
        <div className="professor_evaluation_general_info_right_content_list">
          {studentCourses.map((course, courseIndex) => {
            if (course != null && userLicense != null) {
              const accessCourse = calculateAccessCourse(
                course.id,
                userLicense,
                teacherSlice.isStudentFree
              );

              return (
                <LazyMotion features={loadFeatures}>
                  <m.div
                    key={courseIndex}
                    className="professor_evaluation_general_info_right_content_subject"
                    style={{
                      backgroundColor: course.color + "50",
                      cursor:
                        'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                    }}
                    onClick={() => curricularContentHandler(course.id)}
                    whileHover={{
                      scale: 1.01,
                      boxShadow: course.color + "50" + " 0px 0px 10px 2px",
                    }}
                    transition={{
                      type: "spring",
                      stiffness: 400,
                      damping: 10,
                    }}
                    onMouseEnter={() => setShowHoverText(course.id)}
                    onMouseLeave={() => setShowHoverText("none")}
                  >
                    {showHoverText === course.id && <div className="professor_evaluation_general_info_right_content_subject_hover">
                      <div className="text_white_small_bold">{t("see_detail_subject_hover")}</div>
                    </div>}
                    <div
                      className="professor_evaluation_general_info_right_content_subject_left"
                      style={{ backgroundColor: course.color + "80" }}
                    >
                      <IconoAsignaturas size={"6vh"} />
                    </div>
                    <div className="professor_evaluation_general_info_right_content_subject_right">
                      <div className="professor_evaluation_general_info_right_content_subject_right_name">
                        <div
                          className="text_black_small_bold"
                          style={{ textDecoration: "underline" }}
                        >
                          {t(course.acronym + "_name", { ns: "evaluation" })}
                        </div>
                      </div>
                      <div
                        className="professor_evaluation_general_info_right_content_subject_right_points"
                        style={
                          accessCourse
                            ? { filter: "blur(0px)" }
                            : { filter: "blur(3px)" }
                        }
                      >
                        <div className="text_black_very_small">
                          <b>
                            {accessCourse
                              ? coursePoints[course.acronym] || 0
                              : 100}
                          </b>
                          {/* {"/" + 500} */}
                        </div>
                      </div>
                    </div>
                  </m.div>
                </LazyMotion>
              );
            }
          })}
        </div>
      </div>
    );
  };

  return (
    <div
      className="professor_evaluation_general_info"
      style={{
        gridTemplateColumns:
          classInfo?.regulation_id === "regulation_GB" ||
            classInfo?.regulation_id === "regulation_GMyS"
            ? "100%"
            : "50% 50%",
      }}
    >
      {classInfo?.regulation_id === "regulation_GB" ||
        classInfo?.regulation_id === "regulation_GMyS"
        ? renderRight()
        : renderLeft()}
      {classInfo?.regulation_id !== "regulation_GB" &&
        classInfo?.regulation_id !== "regulation_GMyS" &&
        renderRight()}
    </div>
  );
};

export default ProfessorEvaluationGeneralInfo;
