import "./ProfessorWelcome.css";
import { useTranslation } from "react-i18next";
import arrow from "../../assets/professor/arrow.svg";
import { calculateCreateClass } from "../../utils/licenses.ts";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import UserMenu from "./UserMenu/UserMenu.tsx";
import BackArrow from "../../assets/exercises/back_arrow.tsx";
import welcomeBar from "../../assets/professor/welcome_bar.svg";
import { LazyMotion, motion } from "framer-motion";
import { useState } from "react";

const ProfessorWelcome = () => {
  const { t } = useTranslation();
  const teacherState = useAppSelector((state) => state.teacher);
  const userLicense = teacherState.userLicense;
  const teacherClasses = teacherState.classes;
  const isAdmin = teacherState.isAdmin;
  const [showHoverText, setShowHoverText] = useState("none");

  // console.log(teacherClasses.length, userLicense);
  const loadFeatures = () =>
    import("../../hooks/framerMotionHooks.ts").then((res) => res.default);

  const renderVideo = (
    text: string,
    url: string,
    image_url: string,
    text_state: string
  ) => {
    return (
      <div className="professor_welcome_video_container">
        <div className="professor_welcome_video_title">
          <div
            className="text_white_small"
            style={{ textDecoration: "underline" }}
          >
            {t(text)}
          </div>
        </div>
        {/* <div 
          // onMouseEnter={() => setShowHoverText(true)}
          // onMouseLeave={() => setShowHoverText(false)}
          onClick={() => window.open(url, '_blank')}> */}

        <motion.div
          className="professor_welcome_video"
          whileHover={{ scale: 1.02 }}
          style={{
            display: "inline-block",
            cursor:
              'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
          }}
          transition={{ type: "spring", stiffness: 400, damping: 10 }}
          onClick={() => window.open(url, "_blank")}
          onMouseEnter={() => setShowHoverText(text_state)}
          onMouseLeave={() => setShowHoverText("none")}
        >
          {showHoverText === text_state && (
            <div className="professor_welcome_video_hover center_container">
              <div className="text_white_medium_bold">{t("watch_video")}</div>
            </div>
          )}
          <img
            src={image_url}
            alt="Video Thumbnail"
            style={{
              borderRadius: "5px",
              width: "28vw",
              height: "auto",
            }}
          />
        </motion.div>
        {/* </div> */}
      </div>
    );
  };

  return (
    <div className="professor_welcome">
      <div
        className="professor_welcome_arrows_left"
        style={{
          gridTemplateRows:
            isAdmin && calculateCreateClass(userLicense, teacherClasses.length)
              ? "7% 5% 88%"
              : "7% 93%",
        }}
      >
        <div />
        {isAdmin &&
          calculateCreateClass(userLicense, teacherClasses.length) && (
            <div className="left_container">
              <BackArrow color={"white"} size={"30"} />
              <div className="text_white_small_bold">
                {t("welcome_create_class")}
              </div>
            </div>
          )}
        <div
          className="top_left_container"
          style={{ marginLeft: "10px", marginTop: "5%" }}
        >
          <img
            className="professor_welcome_bar"
            src={welcomeBar}
            alt="welcomeBar"
          />
          <div
            className="text_white_small_bold"
            style={{ marginTop: "7px", marginLeft: "0.4em" }}
          >
            {t("welcome_access_class")}
          </div>
        </div>
      </div>
      <div className="professor_welcome_top">
        <div className="professor_welcome_left">
          {/* {calculateCreateClass(userLicense, teacherClasses.length) && (
            <div className="left_container"
              style={{ marginTop: "4%" }}>
              <div className="text_white_small_bold">
                {t("welcome_create_class")}
              </div>
            </div>
          )}

          {calculateCreateClass(userLicense, teacherClasses.length) && (
            <div className="left_container">
              <div className="text_white_small_bold"
                style={{ marginTop: "3%" }}>
                {t("welcome_access_class")}
              </div>
            </div>
          )} */}
        </div>

        <div className="professor_welcome_right">
          <div className="left_container">
            <div className="text_white_medium_bold">
              {t("professor_welcome_text2") + ", "}
              <span style={{ textDecoration: "underline" }}>
                {teacherState.fullName.split(" ")[0]}
              </span>
            </div>
          </div>

          <div className="left_container">
            <div className="text_white_medium_small">
              {t("professor_welcome_text3")}
            </div>
          </div>
        </div>
      </div>
      <div className="professor_welcome_bottom">
        <div className="left_container">
          <div
            className="text_white_medium_small_bold"
            style={{ marginRight: "10px" }}
          >
            {t("tutorial_how_start_1")}
          </div>
          <div className="text_white_medium_small">
            {t("tutorial_how_start_2")}
          </div>
        </div>
        <div
          className="professor_welcome_video_grid_container"
        >
          {renderVideo(
            "tutorial_create_class",
            "https://www.youtube.com/watch?v=mffB_axhISI&ab_channel=Zynergic",
            "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Professor/Tutorial/tutorial_create_class.png",
            "create_class"
          )}
          {renderVideo(
            "tutorial_program_content",
            "https://www.youtube.com/watch?v=Ez5xV1I7XIk&ab_channel=Zynergic",
            "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Professor/Tutorial/tutorial_program_content.png",
            "program_content"
          )}
          {renderVideo(
            "tutorial_create_content",
            "https://www.youtube.com/watch?v=Oz5H6jXrySw&ab_channel=Zynergic",
            "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Professor/Tutorial/tutorial_create_content.png",
            "create_content"
          )}
          {renderVideo(
            "tutorial_see_island",
            "https://www.youtube.com/watch?v=HPmBBi_gUso&ab_channel=Zynergic",
            "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Professor/Tutorial/tutorial_see_island.png",
            "see_island"
          )}
          {renderVideo(
            "tutorial_manage_students",
            "https://www.youtube.com/watch?v=mAi3T-aQwrM&ab_channel=Zynergic",
            "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Professor/Tutorial/tutorial_manage_students.png",
            "manage_students"
          )}

          {renderVideo(
            "tutorial_evaluation",
            "https://www.youtube.com/watch?v=Gr7uuzZq3ms",
            "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Professor/Tutorial/tutorial_evaluation.png",
            "evaluation"
          )}
          {renderVideo(
            "tutorial_how_students_access",
            "https://www.youtube.com/watch?v=bvHiOfRvl-k",
            "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Professor/Tutorial/tutorial_how_students_access.png",
            "students_access"
          )}
          {renderVideo(
            "tutorial_how_students_play",
            "https://www.youtube.com/watch?v=mr7z00itBfc",
            "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Professor/Tutorial/tutorial_how_students_play.png",
            "students_play"
          )}
        </div>
      </div>
      <UserMenu color={"white"} />
    </div>
  );
};

export default ProfessorWelcome;
