import "./MyScenarios.css";

import { useAppSelector, useAppDispatch } from "../../../hooks/hooks";
import { useTranslation } from "react-i18next";
import { useState, useEffect, ChangeEvent } from "react";
import { LazyMotion, m, motion } from "framer-motion";
import BackArrow from "../../../assets/exercises/back_arrow.tsx";
import {
  setGlobalAuthorValueMyScenarios,
  setGlobalLanguageValueMyScenarios,
  setGlobalRegulationValueMyScenarios,
  setGlobalSearchValueMyScenarios,
  setGlobalSubjectValueMyScenarios,
  setscenarioMenuParent,
  submenu,
  updateSelectedBundle,
  updateSelectedScenario,
} from "../../../reducers/teacherSlice";
import { Input, HStack } from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import Loading from "../../loading_screen/LoadingDots.tsx";
import _ from "lodash";
import {
  GetTeacherResourcesAPI,
  ModifiedScenarioType,
  ModifiedScenarioBundleType,
} from "../../../_newapios/content/scenario.ts";

const MyScenarios = ({ ...props }) => {
  const teacherState = useAppSelector((state) => state.teacher);
  const mainState = useAppSelector((state) => state.main);
  const loginState = useAppSelector((state) => state.login);

  const { t, i18n } = useTranslation(["common", "evaluation"]);
  const coursesList = mainState.courses.filter(
    (course) => course.acronym !== "CM_EP"
  );

  const regulations = mainState.regulations;

  const authorOptions = [
    {
      value: 0,
      label: t("created_by_me"),
      colorScheme: "yellow",
    },
    {
      value: 1,
      label: t("programmed_by_me"),
      colorScheme: "red",
    },
  ];

  const languages = [
    { value: "es", label: t("spanish") },
    { value: "en", label: t("english") },
    { value: "val", label: t("valencian") },
    { value: "cat", label: t("catalonian") },
    { value: "gal", label: t("galician") },
    { value: "eus", label: t("basque") },
    { value: "de", label: t("deutsch") },
    { value: "fr", label: t("french") },
  ];

  const loadFeatures = () =>
    import("../../../hooks/framerMotionHooks.ts").then((res) => res.default);
  const dispatch = useAppDispatch();

  const searchValue = teacherState.globalSearchValueMyScenarios;
  const regulationValue = teacherState.globalRegulationValueMyScenarios;
  const subjectValue = teacherState.globalSubjectValueMyScenarios;

  const authorValue = teacherState.globalAuthorValueMyScenarios.map(
    (author) => ({
      value: author.value,
      label: t(author.label),
      colorScheme: author.colorScheme,
    })
  );
  const languageValue = teacherState.globalLanguageValueMyScenarios;
  const [loading, setLoading] = useState(true);

  const [resources, setResources] = useState<ModifiedScenarioBundleType[]>([]);

  const getCoursesByRegulation = (regulationIds: string[]) => {
    if (!regulationIds || regulationIds.length === 0) return [];
    return coursesList.filter((course) =>
      regulationIds.includes(course.regulation_id)
    );
  };

  const mappedSubjects = getCoursesByRegulation(regulationValue).map(
    (course) => ({
      ...course,
      label: t(course.acronym + "_name", { ns: "evaluation" }),
      value: course.id,
      colorScheme: course.color,
    })
  );

  useEffect(() => {
    fetchResources();
  }, []);

  useEffect(() => {
    if (regulationValue.length === 0) {
      dispatch(setGlobalSubjectValueMyScenarios([]));
    } else {
      const selectedSubjects = subjectValue.filter((subject) => {
        const course = coursesList.find(
          (course) => course.id === subject.value
        );
        const regulationId = course?.regulation_id;

        return regulationId && regulationValue.includes(regulationId);
      });
      dispatch(setGlobalSubjectValueMyScenarios(selectedSubjects));
    }
  }, [regulationValue]);

  const fetchResources = async () => {
    setLoading(true);

    if (teacherState.selectedClass === null) {
      return;
    }

    const fetchedResources = await GetTeacherResourcesAPI();
    if (fetchedResources) {
      setResources(fetchedResources);
    } else {
      return;
    }

    setLoading(false);
  };

  const searchHandler = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setGlobalSearchValueMyScenarios(event.target.value));
  };

  const regulationHandler = (value: any) => {
    const regs = value as {
      label: string;
      value: string;
    }[];
    dispatch(setGlobalRegulationValueMyScenarios(regs.map((reg) => reg.value)));
  };

  const subjectHandler = (value: any) => {
    dispatch(setGlobalSubjectValueMyScenarios(value));
  };

  const authorHandler = (value: any) => {
    dispatch(setGlobalAuthorValueMyScenarios(value));
  };

  const languageHandler = (value: any) => {
    dispatch(setGlobalLanguageValueMyScenarios(value));
  };

  const scenarioHandler = (scenario: ModifiedScenarioType) => {
    dispatch(updateSelectedScenario(scenario));
    dispatch(updateSelectedBundle(null));
    dispatch(submenu("scenario"));
    dispatch(setscenarioMenuParent("my_scenarios"));
  };

  const filterSearchResources = (scenario: ModifiedScenarioBundleType) => {
    if (searchValue && scenario.main_know_how) {
      if (
        scenario.main_know_how
          .toLowerCase()
          .includes(searchValue.toString().toLowerCase())
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const filterSubjectResources = (scenario: ModifiedScenarioBundleType) => {
    var isSubject = true;
    if (subjectValue !== undefined && subjectValue.length > 0) {
      isSubject = false;
      for (var i = 0; i < subjectValue?.length; i++) {
        if (scenario.course_id === subjectValue[i].id) {
          isSubject = true;
        }
      }
    }
    return isSubject;
  };

  const filterAuthorResources = (scenario: ModifiedScenarioBundleType) => {
    var isAuthor = true;

    let isProgrammedByMe = false;

    if (authorValue !== undefined && authorValue.length > 0) {
      isAuthor = false;
      const isMine = scenario.owner?.id === loginState.id;
      const isProgrammedByMe = scenario.bundles.some(
        (bundle) => bundle.teacher_id === loginState.id
      );

      for (var i = 0; i < authorValue.length; i++) {
        switch (authorValue[i].value) {
          case 0:
            if (isMine) {
              isAuthor = true;
            }
            break;
          case 1:
            if (isProgrammedByMe) {
              isAuthor = true;
            }
            break;
        }
      }
    }
    return isAuthor;
  };

  const filterLanguageResources = (scenario: ModifiedScenarioBundleType) => {
    if (languageValue.length !== 0) {
      if (
        languageValue.filter(
          (lang) => lang.value.toLowerCase() === scenario.language.toLowerCase()
        ).length !== 0
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const filterRegulationResources = (scenario: ModifiedScenarioBundleType) => {
    var isRegulation = true;
    if (regulationValue !== undefined && regulationValue.length > 0) {
      isRegulation = false;
      for (var i = 0; i < regulationValue.length; i++) {
        if (scenario.course.regulation_id === regulationValue[i]) {
          isRegulation = true;
        }
      }
    }
    return isRegulation;
  };

  const renderBack = () => {
    return (
      <div className="professor_my_scenarios_top">
        <div
          className="left_container"
          style={{ width: "65%", justifyContent: "space-between" }}
        >
          <LazyMotion features={loadFeatures}>
            <m.div
              className="professor_my_scenarios_title"
              whileHover={{
                scale: 1.05,
                textShadow: "rgba(80, 144, 240, 0.50) 1px 0px 10px",
              }}
              transition={{ type: "tween", duration: 0.2 }}
              onClick={() => dispatch(submenu("marketplace_selector"))}
              style={{
                cursor:
                  'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
              }}
            >
              <BackArrow color={"#000000"} size={"8vh"} />
              <div className="text_black_big_bold">{t("go_back")}</div>
            </m.div>
          </LazyMotion>
          <div className="text_black_big_bold">{t("my_situations")}</div>
        </div>
      </div>
    );
  };

  const renderFilters1 = () => {
    const filteredCourses = mappedSubjects.filter((course) =>
      resources.some(
        (scenario) =>
          scenario.course_id === course.value && filterAuthorResources(scenario)
      )
    );

    return (
      <HStack spacing={8}>
        <Input
          placeholder={t("search_placeholder")}
          size="lg"
          style={{ fontFamily: "Causten" }}
          as={motion.input}
          type="search"
          value={searchValue}
          onChange={searchHandler}
          borderRadius={"3px"}
          w={"20vw"}
          minW={"15vw"}
          whileHover={{ scale: 1.01, boxShadow: "0px 0px 10px 1px #5090F0" }}
          transition="0.1s ease"
        />

        <Select
          isMulti
          name="subjects"
          useBasicStyles
          options={filteredCourses}
          placeholder={t("subjects_placeholder")}
          noOptionsMessage={() => t("no_subjects")}
          closeMenuOnSelect={false}
          onChange={subjectHandler}
          variant="outline"
          value={subjectValue}
          chakraStyles={{
            control: (provided) => ({
              ...provided,
              minWidth: "15vw",
              fontFamily: "Causten",
              cursor:
                'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
            }),
            menuList: (provided) => ({
              ...provided,
              fontFamily: "Causten",
            }),
            multiValue: (provided, state) => {
              return {
                ...provided,
                borderRadius: "50px",
                color: "white",
                background: state.data.color,
              };
            },
            clearIndicator: (provided) => ({
              ...provided,
              color: "#F0684F",
            }),
            option: (provided, state) => ({
              ...provided,
              backgroundColor: "white",
              color: regulations.find(
                (reg) => reg.id === state.data.regulation_id
              )?.color,
              fontFamily: "Causten",
              fontWeight: "bold",
            }),
          }}
        />

        <Select
          isMulti
          name="languages"
          useBasicStyles
          options={languages}
          placeholder={t("languages_placeholder")}
          closeMenuOnSelect={false}
          onChange={languageHandler}
          value={languageValue}
          chakraStyles={{
            multiValue: (provided) => ({
              ...provided,
              borderRadius: "50px",
            }),
            control: (provided) => ({
              ...provided,
              minWidth: "15vw",
              fontFamily: "Causten",
              cursor:
                'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
            }),
            menuList: (provided) => ({
              ...provided,
              fontFamily: "Causten",
            }),
            clearIndicator: (provided) => ({
              ...provided,
              color: "#F0684F",
            }),
          }}
        />
      </HStack>
    );
  };

  const renderFilters2 = () => {
    const filteredRegulations = regulations.filter((regulation) =>
      getCoursesByRegulation([regulation.id]).some((course) =>
        resources.some((scenario) => scenario.course_id === course.id)
      )
    );

    return (
      <HStack spacing={8}>
        <Select
          isMulti
          name="authors"
          useBasicStyles
          options={authorOptions}
          placeholder={t("authors_placeholder")}
          closeMenuOnSelect={false}
          onChange={authorHandler}
          value={authorValue}
          chakraStyles={{
            multiValue: (provided) => ({
              ...provided,
              borderRadius: "50px",
            }),
            control: (provided) => ({
              ...provided,
              minWidth: "15vw",
              fontFamily: "Causten",
              cursor:
                'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
            }),
            menuList: (provided) => ({
              ...provided,
              fontFamily: "Causten",
            }),
            clearIndicator: (provided) => ({
              ...provided,
              color: "#F0684F",
            }),
          }}
        />
        <Select
          isMulti
          name="regulations"
          options={filteredRegulations.map((reg) => {
            return {
              label: t(reg.name, { ns: "evaluation" }),
              value: reg.id,
            };
          })}
          useBasicStyles
          placeholder={t("regulation_placeholder")}
          closeMenuOnSelect={false}
          onChange={regulationHandler}
          value={regulationValue.map((regId) => {
            return {
              label: t(
                regulations.find((reg) => reg.id === regId)?.name ||
                  "No labels?",
                { ns: "evaluation" }
              ),
              value: regId,
            };
          })}
          chakraStyles={{
            multiValue: (provided, state) => ({
              ...provided,
              borderRadius: "50px",
              color: "white",
              backgroundColor: regulations.find(
                (reg) => reg.id === state.data.value
              )?.color,
            }),
            control: (provided) => ({
              ...provided,
              minWidth: "15vw",
              fontFamily: "Causten",
              cursor:
                'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
            }),
            menuList: (provided) => ({
              ...provided,
              fontFamily: "Causten",
            }),
            clearIndicator: (provided) => ({
              ...provided,
              color: "#F0684F",
            }),
          }}
        />
      </HStack>
    );
  };

  const renderScenario = (scenario: ModifiedScenarioBundleType) => {
    if (
      filterSearchResources(scenario) &&
      filterSubjectResources(scenario) &&
      filterLanguageResources(scenario) &&
      filterRegulationResources(scenario) &&
      filterAuthorResources(scenario)
    ) {
      var scenarioRegulation = regulations.find(
        (regulation) => regulation.id === scenario.course.regulation_id
      );

      const bannerUrl = _.isUndefined(scenario.banner.url)
        ? "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/ES/Tutorial/Tutorial+-+Banner.jpg"
        : scenario.banner.url;

      return (
        <motion.div
          className="professor_my_scenarios_content"
          key={scenario.id}
          whileHover={{
            scale: 1.01,
            boxShadow: "0px 0px 10px 2px " + scenario?.course.color,
          }}
          style={{
            backgroundImage: `linear-gradient(to right, rgba(128, 128, 128, 0.9), rgba(128, 128, 128, 0) 70%), url(${bannerUrl})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
          transition={{ type: "spring", stiffness: 400, damping: 10 }}
          onClick={() => scenarioHandler(scenario)}
        >
          <div className="professor_my_scenarios_content_mid_name">
            <div
              className="text_white_medium_bold"
              style={{ overflow: "hidden", textOverflow: "ellipsis" }} // TODO - copy this elsewhere
            >
              {(scenario.main_know_how !== null ||
                scenario.main_know_how !== "") &&
                scenario.main_know_how}
            </div>
          </div>

          <div className="professor_my_scenarios_content_bot">
            <div className="text_white_small">
              {t("author") + ": " + scenario.owner?.fullname}
            </div>
            <div></div>
          </div>

          <div className="professor_my_scenarios_content_mid_rest">
            <div
              className="professor_my_scenarios_content_mid_subject"
              style={{
                background: scenario.course.color,
              }}
            >
              <div className="center_container">
                <div className="text_white_super_small">
                  {t(scenario.course.acronym + "_short", { ns: "evaluation" })}
                </div>
              </div>
            </div>

            <div
              className="professor_my_scenarios_content_bot_regulation"
              style={{
                backgroundColor: scenarioRegulation?.color,
              }}
            >
              <div className="center_container">
                <div className="text_white_hiper_small">
                  {t(scenarioRegulation?.name || "ESO_name", {
                    ns: "evaluation",
                  })}
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      );
    }
  };

  const renderScenarios = () => {
    let resourcesToDisplay = 0;

    resources.forEach((resource) => {
      if (
        resource != undefined &&
        resource.course_id !== "" &&
        filterSearchResources(resource) &&
        filterSubjectResources(resource) &&
        filterLanguageResources(resource) &&
        filterRegulationResources(resource) &&
        filterAuthorResources(resource)
      ) {
        resourcesToDisplay += 1;
      }
    });
    return (
      <div
        className="professor_my_scenarios_container"
        style={{
          gridTemplateRows: "repeat(" + resourcesToDisplay + ", 25%",
        }}
      >
        {resources.map((resource) => {
          return renderScenario(resource);
        })}
      </div>
    );
  };

  return (
    <div className={loading ? "center_container" : "professor_my_scenarios"}>
      {loading && <Loading />}
      {!loading && renderBack()}
      {!loading && (
        <div className="professor_my_scenarios_body">
          {renderFilters1()}
          {renderFilters2()}
          {renderScenarios()}
        </div>
      )}
    </div>
  );
};

export default MyScenarios;
