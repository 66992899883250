import "./TutorialEutonsGlobalMap.css";
import { useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../../hooks/hooks.ts";
import { useTranslation } from "react-i18next";
import { m, LazyMotion } from "framer-motion";
import { increaseTutorialEutonsStep } from "../../../reducers/studentSlice.ts";
import closeButton from "../../assets/student/tutorial/close.svg";
import confirmButton from "../../assets/student/tutorial/ok.svg";

const TutorialEutonsGlobalMap = ({ ...props }) => {
  const { t } = useTranslation();

  const loadFeatures = () =>
    import("../../../hooks/framerMotionHooks.ts").then((res) => res.default);

  const dispatch = useAppDispatch();
  const studentState = useAppSelector((state) => state.student);
  const mainState = useAppSelector((state) => state.main);
  const tutorialEutonsStep = studentState.tutorialEutonsStep;

  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  const advanceStepHandler = () => {
    dispatch(increaseTutorialEutonsStep());
  };

  const renderProfessor = () => {
    return (
      <div className="tutorial_professor">
        <div className="bottom_left_container">
          <img
            className="image"
            style={mainState.ultraPerformanceMode ? { top: "30%" } : {}}
            src={
              window.innerWidth > 991
                ? "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Exercises/professor_big.svg"
                : "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Exercises/professor_cropped.svg"
            }
            alt="professor"
          />
        </div>
      </div>
    );
  };

  const renderSanny = () => {
    return (
      <div className="tutorial_sanny">
        <div className="bottom_right_container">
          <img
            className="image"
            src={
              "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Student/gaviotacolor2.png"
            }
            alt="sanny"
          />
        </div>
      </div>
    );
  };

  const renderBubble = () => {
    if (tutorialEutonsStep <= 4) {
      return (
        <LazyMotion features={loadFeatures}>
          <div className="tutorial_bubble">
            <div className="center_container">
              <div className="text_black_big_bold">
                {tutorialEutonsStep === 0 ? (
                  <div>
                    <div>{t("tutorial_text_1_1")}</div>
                    <div>{t("tutorial_text_1_2")}</div>
                  </div>
                ) : null}
                {tutorialEutonsStep === 1 ? (
                  <div>{t("tutorial_text_eutons_1")}</div>
                ) : null}
                {tutorialEutonsStep === 2 ? (
                  <div>{t("tutorial_text_eutons_2")}</div>
                ) : null}
                {tutorialEutonsStep === 3 ? (
                  <div>
                    {t("tutorial_text_eutons_3_1")}
                    <br />
                    {t("tutorial_text_eutons_3_2")}
                  </div>
                ) : null}
                {tutorialEutonsStep === 4 ? (
                  <div>{t("tutorial_text_3")}</div>
                ) : null}
              </div>
            </div>
            <m.div
              className="tutorial_button"
              onClick={() => advanceStepHandler()}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              transition={{ type: "spring", stiffness: 300, damping: 10 }}
            >
              <div className="center_container">
                <div className="text_white_big_bold">{t("confirm")}</div>
              </div>
            </m.div>
          </div>
        </LazyMotion>
      );
    }
  };

  return (
    <div>
      {renderProfessor()}
      {renderBubble()}
      {tutorialEutonsStep > 0 && tutorialEutonsStep < 4 && renderSanny()}
    </div>
  );
};

export default TutorialEutonsGlobalMap;
