import "./ProfessorMenus.css";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import evaluation_icon from "../../../assets/professor/evaluation_icon.svg";
import classmap_icon from "../../../assets/professor/classmap_icon.svg";
import persons_icon from "../../../assets/professor/persons_icon.svg";
import ActivitiesIcon from "../../../assets/professor/activities_icon.tsx";
import ProfessorCalendarMenu from "./ProfessorCalendarMenu";
import ProfessorClassMapMenu from "./ProfessorClassMapMenu";
import ProfessorEvaluationMenu from "./ProfessorEvaluationMenu";
import ProfessorCredentialsMenu from "./ProfessorCredentialsMenu";
import ProfessorActivitiesMenu from "./ProfessorActivitiesMenu";
import { GetClassStudentsAPI } from "../../../_newapios/user/class.ts";
import UserMenu from "../UserMenu/UserMenu.tsx";
import {
  setEvaluationMenu,
  setTeacherTutorial,
  setTeacherTutorialStep,
  submenu,
} from "../../../reducers/teacherSlice";
import { setStudents } from "../../../reducers/mainSlice";
import { motion } from "framer-motion";
import { ClassType } from "../../../_newapios/user/class.ts";
import { Button, Center } from "@chakra-ui/react";
import StarIcon from "../../../assets/professor/star.tsx";
import SchoolIcon from "../../../assets/professor/school.tsx";
import {
  FinishTutorialPartAPI,
  GetTeacherTutorialAPI,
} from "../../../_newapios/user/teacher.ts";

const ProfessorMenus = () => {
  const { t } = useTranslation();

  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });

  const teacherMenus = ["activities", "evaluation", "classmap", "persons"];

  const dispatch = useAppDispatch();

  const professorState = useAppSelector((state) => state.teacher);
  const menu = professorState.submenu;
  const selectedClass = professorState.selectedClass;
  const classes = professorState.classes;
  const userLicense = professorState.userLicense;

  const [selectedMenu, setSelectedMenu] = useState(menu);
  const restrictGlobalComp = !userLicense?.license.allow_global_competences;

  useEffect(() => {
    setSelectedMenu(menu);
  }, [menu]);

  const handleTutorial1_1Finish = async () => {
    await FinishTutorialPartAPI("1_1");
    dispatch(setTeacherTutorialStep(0));
  };
  useEffect(() => {
    if (professorState.teacherTutorial === "1_1") {
      handleTutorial1_1Finish();
    }
  }, []);

  useEffect(() => {
    window.addEventListener("resize", setDimension);
    const getStudentData = async (current_class: string) => {
      var studentData = await GetClassStudentsAPI(current_class);
      if (studentData === null) {
        studentData = [];
      }
      dispatch(setStudents(studentData));
    };
    getStudentData(selectedClass?.id || "");
    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize, selectedClass]);

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  const changeMenu = (menu: string) => {
    dispatch(submenu(menu));
    dispatch(setEvaluationMenu("studentList"));
    setSelectedMenu(menu);
  };

  const selectIcon = (menu: string) => {
    var icon;
    switch (menu) {
      case "evaluation":
        icon = evaluation_icon;
        break;
      case "classmap":
        icon = classmap_icon;
        break;
      case "persons":
        icon = persons_icon;
        break;
      default:
        icon = persons_icon;
    }

    return icon;
  };

  const className = () => {
    if (selectedClass === null) {
      return "Select a class";
    }
    return selectedClass?.name;
  };

  const classPin = () => {
    if (selectedClass === null) {
      return "";
    }
    return selectedClass?.pin;
  };

  const renderTop = () => {
    return (
      <div className="professor_menus_top">
        {renderTitles()}
        {renderNavigationMenu()}
      </div>
    );
  };

  const renderTitles = () => {
    const themeNumber = selectedClass?.theme_id === "theme_id" ? "1" : "2";

    return (
      <div className="professor_menus_titles">
        <div className="left_container">
          {selectedClass?.name !== undefined && (
            <div
              className={
                selectedClass?.name.length > 15
                  ? "text_black_mediumish_bold"
                  : screenSize.dynamicWidth > 991
                  ? "text_black_big_bold"
                  : "text_black_small_bold"
              }
            >
              {className()}
            </div>
          )}
        </div>
        <div className="left_container">
          <div
            className={
              screenSize.dynamicWidth > 991
                ? "text_black_big"
                : "text_black_medium"
            }
            style={{ userSelect: "text" }}
          >
            Pin: {classPin()}
          </div>
        </div>
        <div
          className="professor_menus_theme"
          style={{
            backgroundColor: themeNumber === "1" ? "#FFF9E7" : "#EEF8ED",
          }}
        >
          <div className="center_container">
            <div
              className={
                screenSize.dynamicWidth > 991
                  ? "text_black_medium_bold"
                  : "text_black_small_bold"
              }
            >
              {t("theme") + " " + themeNumber}
            </div>
          </div>
          <div className="center_container">
            <div
              className={
                screenSize.dynamicWidth > 991
                  ? "text_black_super_small"
                  : "text_black_hiper_small"
              }
            >
              {t("theme_" + themeNumber + "_description" || "")}
            </div>
          </div>
          {renderSustainableGoals()}
        </div>
      </div>
    );
  };

  const renderSustainableGoals = () => {
    const themeNumber = selectedClass?.theme_id === "theme_id" ? 1 : 2;

    const sg_thumbnails = [
      "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Professor/sustainable_goals/sg_1.svg",
      "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Professor/sustainable_goals/sg_2.svg",
      "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Professor/sustainable_goals/sg_3.svg",
      "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Professor/sustainable_goals/sg_4.svg",
      "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Professor/sustainable_goals/sg_5.svg",
      "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Professor/sustainable_goals/sg_6.svg",
      "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Professor/sustainable_goals/sg_7.svg",
      "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Professor/sustainable_goals/sg_8.svg",
      "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Professor/sustainable_goals/sg_9.svg",
      "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Professor/sustainable_goals/sg_10.svg",
      "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Professor/sustainable_goals/sg_11.svg",
      "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Professor/sustainable_goals/sg_12.svg",
      "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Professor/sustainable_goals/sg_13.svg",
      "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Professor/sustainable_goals/sg_14.svg",
      "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Professor/sustainable_goals/sg_15.svg",
      "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Professor/sustainable_goals/sg_16.svg",
      "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Professor/sustainable_goals/sg_17.svg",
      "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Professor/sustainable_goals/sg_18.svg",
    ];

    const themeGoals = {
      1: [6, 10, 11, 12, 16],
      2: [2, 12, 13, 14, 16],
    };

    const selectedThumbnails = themeGoals[themeNumber].map(
      (index) => sg_thumbnails[index]
    );

    return (
      <div
        className="professor_menus_theme_goals"
        style={{
          gridTemplateColumns:
            "repeat(" +
            selectedThumbnails.length +
            ", " +
            100 / selectedThumbnails.length +
            "%)",
        }}
      >
        {selectedThumbnails.map((goal, index) => renderGoal(index + 1, goal))}
      </div>
    );
  };

  const renderGoal = (index: number, icon: string) => {
    return (
      <div key={index} className="center_container">
        <img className="image" src={icon} alt={"sg" + index} />
      </div>
    );
  };

  const renderNavigationMenu = () => {
    return (
      <div className="professor_menus_nav_menu">
        <ul style={{ display: "flex", width: "100%" }}>
          {teacherMenus.map((teacherMenu, index) => {
            return (
              <motion.li
                whileHover={
                  menu === teacherMenu
                    ? { scale: 1.0 }
                    : { scale: 1.1, fontWeight: "bold" }
                }
                transition={{
                  type: "spring",
                  stiffness: 400,
                  damping: 10,
                  duration: 1,
                }}
                key={index}
                layout
                className="professor_menus_nav_menu_button"
                onClick={() => changeMenu(teacherMenu)}
              >
                {teacherMenu !== "activities" && (
                  <img
                    className="icon"
                    src={selectIcon(teacherMenu)}
                    alt={teacherMenu}
                  />
                )}
                {teacherMenu === "activities" && (
                  <ActivitiesIcon size={17} color={"black"} />
                )}
                <div style={{ width: "10px" }} />
                <div
                  className={
                    selectedMenu === teacherMenu
                      ? "text_black_very_small_bold"
                      : "text_black_very_small"
                  }
                >
                  {t(teacherMenu)}
                </div>
                {selectedMenu === teacherMenu ? (
                  <motion.div
                    className="professor_menus_nav_menu_underline"
                    layoutId="underline"
                  />
                ) : null}
              </motion.li>
            );
          })}

          {/* FREEMIUM BUTTONS */}
          <div
            className="freemium_buttons"
            style={{
              gap: "10px",
              display: "flex",
              flexDirection: "row",
              position: "relative",
              width: "45%",
              height: "6vh",
              bottom: "3.5vh",
            }}
          >
            {userLicense?.license.course_evaluate_number !== -1 && (
              <motion.div
                whileHover={{
                  scale: 1.05,
                  boxShadow: "0px 0px 10px 0px #5090F0",
                }}
                transition={{ type: "spring", stiffness: 400, damping: 10 }}
                style={{
                  cursor:
                    'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                  background: "#E2EEFF",
                }}
                className="professor_menus_freemium_button"
                onClick={() => {
                  window.open(
                    "https://zynergic.education/comprar-eutopia-ultimate/"
                  );
                }}
              >
                <div className="center_container">
                  <StarIcon size={20} />
                  <div style={{ width: "5px" }} />
                  <div className="text_blue_really_small_bold">
                    {t("freemium_teacher_button")}
                  </div>
                </div>
              </motion.div>
            )}

            {restrictGlobalComp && (
              <motion.div
                whileHover={{
                  scale: 1.05,
                  boxShadow: "0px 0px 10px 0px #5090F0",
                }}
                transition={{ type: "spring", stiffness: 400, damping: 10 }}
                style={{
                  cursor:
                    'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                  background: "#064187",
                }}
                className="professor_menus_freemium_button"
                onClick={() => {
                  window.open(
                    "https://calendly.com/ernesto-zynergic/reunion-con-ernesto?month=2024-07"
                  );
                }}
              >
                <div className="center_container">
                  <SchoolIcon size={16} color={"white"} />
                  <div style={{ width: "5px" }} />
                  <div
                    className="text_white_really_small_bold"
                    style={{ lineHeight: "1em" }}
                  >
                    {t("freemium_school_button")}
                  </div>
                </div>
              </motion.div>
            )}
          </div>
        </ul>
      </div>
    );
  };

  return (
    <div className="professor_menus">
      {renderTop()}
      {menu === "activities" && (
        <ProfessorActivitiesMenu selectedClass={selectedClass?.id} />
      )}
      {menu === "calendar" && (
        <ProfessorCalendarMenu selectedClass={selectedClass?.id} />
      )}
      {menu === "evaluation" && <ProfessorEvaluationMenu />}
      {menu === "classmap" && (
        <ProfessorClassMapMenu selectedClass={selectedClass?.id} />
      )}
      {menu === "persons" && <ProfessorCredentialsMenu />}
      <UserMenu color={"black"} />
    </div>
  );
};

export default ProfessorMenus;
