import "./ProfessorEvaluationCurricularContent.css";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks";
import { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { AnimatePresence, motion } from "framer-motion";
import BackArrow from "../../../../assets/exercises/back_arrow";
import RadarChart from "./RadarChart";

import {
  setEvaluationMenu,
  updateSelectedScenarioId,
} from "../../../../reducers/teacherSlice";
import Loading from "../../../loading_screen/LoadingDots";
import {
  CourseType,
  GetCourseSpecificCompetencesAPI,
} from "../../../../_newapios/content/course";
import {
  prettifyCourseAcronyms,
  specificCompetencesBooleanArray,
} from "../../../../utils/competences";
import { ModifiedBundleType } from "../../../../_newapios/user/class";
import { SpecificCompetenceType } from "../../../../_newapios/content/unit";
import { ModifiedScenarioType } from "../../../../_newapios/content/scenario";
import {
  GetStudentScenarioPointsAPI,
  StudentCoursePoints,
} from "../../../../_newapios/progress/student_course_points";
import _ from "lodash";
import {
  GetClassSpecificCompetencesAPI,
  GetStudentSpecificCompetencesAPI,
  SpecificCompetencePoints,
} from "../../../../_newapios/progress/student_specific_competences";
import StarIcon from "../../../../assets/professor/star";
import { calculateAccessCourse } from "../../../../utils/licenses";

const ProfessorEvaluationCurricularContent = ({ ...props }) => {
  const { t } = useTranslation(["common", "evaluation"]);

  const teacherState = useAppSelector((state) => state.teacher);
  const mainState = useAppSelector((state) => state.main);

  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });

  const dispatch = useAppDispatch();

  const [studentSpecificCompetences, setStudentSpecificCompetences] =
    useState<SpecificCompetencePoints>({} as SpecificCompetencePoints);
  const [classSpecificCompetences, setClassSpecificCompetences] =
    useState<SpecificCompetencePoints>({} as SpecificCompetencePoints);
  const [courseBundles, setCourseBundles] = useState<ModifiedBundleType[]>([]);
  const [specificCompetences, setSpecificCompetences] = useState<
    SpecificCompetenceType[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [scenarioPoints, setScenarioPoints] = useState<StudentCoursePoints>({});

  const selectedCourse = mainState.courses.find(
    (course) => course.id === mainState.selectedCourseId
  ) as CourseType;
  const selectedColor = selectedCourse.color;

  const accessCourse = teacherState.userLicense
    ? calculateAccessCourse(
        mainState.selectedCourseId,
        teacherState.userLicense,
        teacherState.isStudentFree
      )
    : true;

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    //Load from the db the student and school global competences for the chart section
    loadSpecificCompetences();
    loadCurricularContent();
    setCourseBundles(props.courseBundles);
  }, []);

  useEffect(() => {
    loadScenarioPoints();
  }, [courseBundles, props.scenarioPoints]);

  const loadSpecificCompetences = async () => {
    const newSpecificCompetences = await GetCourseSpecificCompetencesAPI(
      selectedCourse.id
    );
    setSpecificCompetences(newSpecificCompetences);
  };

  const getScenarioPoints = async (scenario_id: string) => {
    const newScenarioPoints = await GetStudentScenarioPointsAPI(
      teacherState.selectedStudent?.id || "",
      scenario_id
    );
    return newScenarioPoints || 0;
  };

  const loadScenarioPoints = async () => {
    const propsScenarioPoints = props.scenarioPoints;
    const scenarioIdsInCourse = courseBundles.map(
      (bundle) => bundle.scenario?.id
    );
    if (scenarioIdsInCourse.includes(undefined)) return;
    var newScenarioPoints: StudentCoursePoints = {};

    for (let i = 0; i < Object.keys(propsScenarioPoints).length; i++) {
      const scenario_id = Object.keys(propsScenarioPoints)[i];
      if (scenarioIdsInCourse.includes(scenario_id)) {
        newScenarioPoints[scenario_id] = propsScenarioPoints[scenario_id];
      }
    }

    setScenarioPoints(newScenarioPoints);
  };

  const loadCurricularContent = async () => {
    setLoading(true);
    var promises = [
      GetStudentSpecificCompetencesAPI(
        teacherState.selectedStudent?.id || "",
        selectedCourse.id
      ).then((res) => {
        setStudentSpecificCompetences(res);
      }),
      GetClassSpecificCompetencesAPI(
        teacherState.selectedClass?.id || "",
        selectedCourse.id
      ).then((res) => {
        setClassSpecificCompetences(res);
      }),
    ];
    Promise.all(promises).then(() => {
      setLoading(false);
    });
  };

  const ArrayDivider = (originalArray: string[]): string[][] => {
    let dividedArrays: string[][] = [];

    let i: number;

    originalArray.forEach((item) => {
      var splitItem = item.split(" ");
      var charLength = 0;
      var arrayThatTurnsIntoString: string[] = [];
      var stringArray: string[] = [];
      for (i = 0; i < splitItem.length; i++) {
        charLength += splitItem[i].length;
        if (charLength > 10) {
          arrayThatTurnsIntoString.push(splitItem[i]);
          stringArray.push(arrayThatTurnsIntoString.join(" "));
          arrayThatTurnsIntoString = [];
          charLength = 0;
        } else {
          arrayThatTurnsIntoString.push(splitItem[i]);
          if (i + 1 === splitItem.length) {
            stringArray.push(arrayThatTurnsIntoString.join(" "));
          }
        }
      }
      dividedArrays.push(stringArray);
    });
    return dividedArrays;
  };

  const backHandler = () => {
    dispatch(setEvaluationMenu("generalInfo"));
  };

  const openScenarioHandler = (scenario: ModifiedScenarioType) => {
    dispatch(setEvaluationMenu("scenarioInfo"));
    dispatch(updateSelectedScenarioId(scenario.id));
  };

  const renderLeft = () => {
    return (
      <div className="professor_evaluation_curricular_content_left">
        {renderTitle()}
        {renderScenarios()}
      </div>
    );
  };

  const renderTitle = () => {
    return (
      <motion.div
        className="professor_evaluation_curricular_content_left_title"
        style={{
          cursor:
            'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
        }}
        onClick={() => {
          backHandler();
        }}
        whileHover={{
          scale: 1.1,
          textShadow: selectedColor + " 1px 0px 10px",
        }}
        transition={{ type: "tween", ease: "easeInOut", duration: 0.2 }}
      >
        <BackArrow color={selectedColor} size={"50"} />
        <div
          className={
            screenSize.dynamicWidth > 991
              ? "text_black_medium_bold"
              : "text_black_small_bold"
          }
          style={{ textDecoration: "underline" }}
        >
          {t(selectedCourse.acronym + "_name", { ns: "evaluation" })}
        </div>
      </motion.div>
    );
  };

  const renderRadarChart = () => {
    const labels = !_.isEmpty(studentSpecificCompetences)
      ? Object.keys(studentSpecificCompetences).map((str) =>
          t(str + "_label", { ns: "evaluation" })
        )
      : specificCompetences.map((sc) =>
          t(sc.course.acronym + sc.number + "_label", { ns: "evaluation" })
        );
    const studentData = !_.isEmpty(studentSpecificCompetences)
      ? Object.values(studentSpecificCompetences)
      : _.fill(Array(specificCompetences.length), 0);

    const classData = !_.isEmpty(classSpecificCompetences)
      ? Object.values(classSpecificCompetences)
      : _.fill(Array(specificCompetences.length), 0);

    let baselinedStudSpecComp: number[] = [];
    let baselinedClassSpecComp: number[] = [];

    studentData.forEach((spComp) => {
      if (spComp > 750) {
        baselinedStudSpecComp.push(749);
      } else {
        baselinedStudSpecComp.push(spComp);
      }
    });

    classData.forEach((spComp) => {
      if (spComp > 750) {
        baselinedClassSpecComp.push(749);
      } else {
        baselinedClassSpecComp.push(spComp);
      }
    });
    // HARCODED DATA FOR FREE LICENSES
    const minRandomValue = 50;
    const maxRandomValue = 700;
    const hardcodedStudentData = Array.from(
      { length: specificCompetences.length },
      () =>
        Math.floor(Math.random() * (maxRandomValue - minRandomValue + 1)) +
        minRandomValue
    );

    return (
      <div
        className="professor_evaluation_curricular_content_left_radar"
        style={accessCourse ? { filter: "blur(0)" } : { filter: "blur(4px)" }}
      >
        <RadarChart
          // max={Math.max(750, ...studentData, ...classData)}
          max={750}
          full={false}
          labels={ArrayDivider(labels)}
          // studentData={accessCourse ? studentData : hardcodedStudentData}
          // schoolData={classData}
          studentData={
            accessCourse ? baselinedStudSpecComp : hardcodedStudentData
          }
          schoolData={baselinedClassSpecComp}
        />
      </div>
    );
  };

  const renderRight = () => {
    return (
      <div className="professor_evaluation_curricular_content_right">
        {renderRadarChart()}
        {!accessCourse && (
          <div className="center_container">
            <motion.div
              whileHover={{
                scale: 1.05,
                boxShadow: "0px 0px 10px 0px #5090F0",
              }}
              transition={{ type: "spring", stiffness: 400, damping: 10 }}
              style={{
                cursor:
                  'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
              }}
              className="professor_evaluation_curricular_content_freemium_button_right"
              onClick={() => {
                window.open("https://zynergic.education/eutopia-precios/#4");
              }}
            >
              <div className="center_container">
                <StarIcon size={18} />
                <div style={{ width: "5px" }} />
                <div
                  className="text_blue_really_small_bold"
                  style={{ lineHeight: "1em" }}
                >
                  {t("freemium_specific_competences")}
                </div>
              </div>
            </motion.div>
          </div>
        )}
      </div>
    );
  };

  const renderScenarios = () => {
    return (
      <motion.div
        className="professor_evaluation_curricular_content_left_topics"
        layoutRoot
      >
        {courseBundles != null &&
          courseBundles.map((bundle) => {
            return renderScenario(bundle);
          })}
      </motion.div>
    );
  };

  const renderScenario = (bundle: ModifiedBundleType) => {
    var startDate = new Date(bundle.start_date);
    var endDate = new Date(bundle.end_date);
    const scenario = bundle.scenario;
    if (!scenario) return null;
    const selectedSpecificCompetences = specificCompetencesBooleanArray(
      scenario.specific_competences_ids,
      specificCompetences
    );
    return (
      <motion.div
        className="professor_evaluation_curricular_content_left_topic"
        whileHover={{
          scale: 1.01,
          boxShadow: "0px 0px 10px 1px " + selectedColor + "50",
          borderRadius: "10px",
        }}
        transition={{ type: "spring", stiffness: 500, damping: 30 }}
        key={bundle.id}
        onClick={() =>
          accessCourse
            ? openScenarioHandler(scenario)
            : window.open("https://zynergic.education/eutopia-precios/#4")
        }
      >
        <div
          className="professor_evaluation_curricular_content_left_topic_header"
          //onClick={() => openScenarioHandler(bundle.id)}
        >
          <div className="professor_evaluation_curricular_content_left_topic_header_name">
            <div className="text_black_small_bold">
              {bundle.scenario?.main_know_how}
            </div>
            <div className="text_light_grey_super_small">
              {startDate.getDate() +
                "/" +
                (startDate.getMonth() + 1) +
                "/" +
                startDate.getFullYear() +
                " - " +
                endDate.getDate() +
                "/" +
                (endDate.getMonth() + 1) +
                "/" +
                endDate.getFullYear()}
            </div>
          </div>
          <div
            className="text_blue_small"
            style={
              accessCourse ? { filter: "blur(0px)" } : { filter: "blur(4px)" }
            }
          >
            <b>{accessCourse ? scenarioPoints[scenario.id] : 100}</b>
          </div>
          <div className="text_black_super_small_bold">
            {t("research") + " | " + t("experiment")}
          </div>
        </div>
        <motion.div
          style={{
            opacity: 1,
            height: "fit-content",
          }}
        >
          <div
            className="professor_evaluation_curricular_content_left_scenarios"
            style={
              accessCourse ? { filter: "blur(0)" } : { filter: "blur(4px)" }
            }
          >
            <motion.div className="professor_evaluation_curricular_content_left_scenario">
              <img
                className="image"
                src={scenario.image.url}
                alt="scenario_image"
                style={{ borderRadius: "10px", maxHeight: "71px" }}
              />
              <div className="professor_evaluation_curricular_content_left_scenario_info">
                <div className="professor_evaluation_curricular_content_left_scenario_info_top left_container">
                  <div className="text_black_really_small">
                    <b>{scenario.name}</b> {" | " + scenario.main_know_how}
                  </div>
                </div>
                <div
                  className="professor_evaluation_curricular_content_left_scenario_info_bottom"
                  style={{
                    gridTemplateColumns:
                      "repeat(" + specificCompetences.length + ", 1fr)",
                  }}
                >
                  {selectedSpecificCompetences.map(
                    (competence: boolean, index: number) => {
                      return (
                        <div
                          className="text_white_super_small"
                          style={{
                            backgroundColor: competence
                              ? "rgba(41, 47, 76, 1)"
                              : "rgba(41, 47, 76, 0.25)",
                            borderRadius: "100px",
                            maxHeight: "1.3rem",
                            color: "white",
                            padding: "0.1rem 0.1rem 0rem 0.1rem",
                            textAlign: "center",
                            marginLeft: index !== 0 ? "0.5em" : undefined,
                          }}
                        >
                          {prettifyCourseAcronyms(selectedCourse.acronym) +
                            (index + 1).toString()}
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            </motion.div>
          </div>

          {!accessCourse && (
            <div className="center_container">
              <motion.div
                whileHover={{
                  boxShadow: "0px 0px 10px 0px #5090F0",
                }}
                transition={{ type: "spring", stiffness: 400, damping: 10 }}
                style={{
                  cursor:
                    'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                }}
                className="professor_evaluation_curricular_content_freemium_button_left"
              >
                <div className="center_container">
                  <StarIcon size={18} />
                  <div style={{ width: "5px" }} />
                  <div
                    className="text_blue_really_small_bold"
                    style={{ lineHeight: "1em" }}
                  >
                    {t("freemium_see_progress")}
                  </div>
                </div>
              </motion.div>
            </div>
          )}
        </motion.div>
      </motion.div>
    );
  };

  if (!loading) {
    return (
      <div
        className="professor_evaluation_curricular_content_background"
        style={{ boxShadow: "0px 0px 10px 1px " + selectedColor + "50" }}
      >
        {renderLeft()}
        {renderRight()}
      </div>
    );
  } else {
    return <Loading />;
  }
};

export default ProfessorEvaluationCurricularContent;
