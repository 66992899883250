import "./DragDropOptions.css";
import { useState, useEffect, Fragment } from "react";
import { motion } from "framer-motion";
import {
  Arrows,
  Background,
  ConfirmButton,
  ExerciseName,
  HelpMenu,
  MainTextBox,
  Professor,
  TextBox,
} from "./_components";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Idirection, IscreenSize } from "../../../interfaces";
import { ExerciseType } from "../../../_newapios/content/unit";
import _ from "lodash";
import { PlayFunction } from "use-sound/dist/types";
import { useAppSelector } from "../../../hooks/hooks";

const DragDropOptions = (props: {
  exercise: ExerciseType;
  totalExercises: number;
  exerciseIndex: number;
  maxExercise: number;
  rightAnswerSound: PlayFunction;
  wrongAnswerSound: PlayFunction;
  research: boolean;
  updateLevel: (
    direction: Idirection,
    answer: string[] | null,
    correct: boolean | null
  ) => void;
  incorrectHandler: () => void;
  tipHandler: () => void;
}) => {
  const [screenSize, getDimension] = useState<IscreenSize>({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });

  const isTeacher = useAppSelector((state) => state.login).type === "teacher";

  const [answers, setAnswers] = useState<string[]>([]);
  const [availableOptions, setAvailableOptions] = useState<string[]>([]);

  const text = props.exercise.text;
  const helpText = props.exercise.help_text;
  const mainText = props.exercise.main_text as string;
  const options = props.exercise.options as string[];

  const [selectedExercise, setselectedExercise] = useState(props.exerciseIndex);
  const [maxExercise, setmaxExercise] = useState(props.maxExercise);
  const [research, setResearch] = useState(props.research);

  const [incorrectFeedback, setIncorrectFeedback] = useState(false);
  const [incorrectIndexes, setIncorrectIndexes] = useState<number[]>([]);

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  useEffect(() => {
    orderOptions();
  }, [selectedExercise, maxExercise]);

  useEffect(() => {
    setselectedExercise(props.exerciseIndex);
    setmaxExercise(props.maxExercise);
  }, [props.exerciseIndex]);

  const orderOptions = () => {
    setAnswers([]);
    setAvailableOptions([]);
    if (research) {
      if (selectedExercise < maxExercise) {
        setAnswers(options);
      } else {
        setAvailableOptions(_.shuffle(options));
      }
    } else {
      if (isTeacher) {
        setAnswers(options);
      } else {
        setAvailableOptions(_.shuffle(options));
      }
    }
  };

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };
  const confirmHandler = () => {
    var correct = true;
    let wrongIndexes = [];

    for (var i = 0; i < options.length; i++) {
      if (options[i] !== answers[i]) {
        correct = false;
        wrongIndexes.push(i);
      }
    }
    if (research) {
      if (correct) {
        props.updateLevel("right", answers, true);
        props.rightAnswerSound();
      } else {
        props.incorrectHandler();
        props.wrongAnswerSound();
        setIncorrectFeedback(true);
        setIncorrectIndexes(wrongIndexes);
      }
    } else {
      if (correct) {
        props.updateLevel("right", answers, true);
      } else {
        props.updateLevel("right", answers, false);
      }
    }
  };

  const updateDashboard = (srcC: number, desC: number) => {
    if (srcC === desC) return;

    let tmpAnswers = [...answers];
    let tmpOptions = [...availableOptions];

    let srcValue = "";
    if (srcC < 1000) {
      srcValue = tmpOptions[srcC];
    } else {
      srcValue = tmpAnswers[srcC - 1000];
    }

    let desValue = "";
    if (desC < 1000) {
      tmpOptions.push(srcValue);
    } else {
      desValue = tmpAnswers[desC - 1000];
      tmpAnswers[desC - 1000] = srcValue;
    }

    if (srcC < 1000) {
      tmpOptions.splice(srcC, 1);
      if (desValue && desValue !== "") {
        tmpOptions.push(desValue);
      }
    } else {
      tmpAnswers[srcC - 1000] = desValue;
    }

    setAnswers(tmpAnswers);
    setAvailableOptions(tmpOptions);
  };
  const renderDragDrop = () => {
    return (
      <div className="drag_drop_options_background">
        <div className="center_container"></div>

        <Droppable droppableId={"droppable-" + 0} key={"droppable-" + 0}>
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className="drag_drop_options_droppable"
            >
              {availableOptions.map((column, columnIndex) => {
                return (
                  <Draggable
                    key={columnIndex}
                    draggableId={"draggable-" + columnIndex}
                    index={0}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="drag_drop_options_draggable"
                        style={{
                          ...provided.draggableProps.style,
                          backgroundColor: "",
                          // incorrectFeedback === true
                          //   ? incorrectIndexes.includes(columnIndex)
                          //     ? "#F6A495"
                          //     : ""
                          //   : "",
                        }}
                      >
                        <div
                          className="center_container"
                          style={{ padding: "4px" }}
                        >
                          <div
                            className={
                              column?.length < 15
                                ? "text_black_medium"
                                : "text_black_small"
                            }
                          >
                            {column}
                          </div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    );
  };

  console.log(mainText);

  return (
    <div className="drag_drop_options">
      <Background
        incorrectFeedback={incorrectFeedback}
        setIncorrectFeedback={setIncorrectFeedback}
      />
      <Professor width={screenSize.dynamicWidth} />
      <TextBox text={text} contractTextBox={true} />
      <Arrows
        selectedExercise={selectedExercise}
        maxExercise={maxExercise}
        updateLevel={props.updateLevel}
        research={research}
      />
      <ConfirmButton confirmHandler={confirmHandler} />
      <DragDropContext
        onDragEnd={(param) => {
          const srcC = param.draggableId.slice("droppable-".length);

          const desC =
            param.destination === null || param.destination === undefined
              ? param.draggableId.slice("droppable-".length)
              : param.destination.droppableId.slice("droppable-".length);

          updateDashboard(parseInt(srcC), parseInt(desC));
        }}
      >
        <>
          <div
            className={
              false ? "exercise_definition_text_box" : "exercise_main_text_box"
            }
          >
            <div className="center_container">
              {!_.isUndefined(mainText) && (
                <div
                  className={
                    mainText.length < 265
                      ? "text_black_medium"
                      : "text_black_small"
                  }
                >
                  {mainText.split(/(\[[^\]]+\])/).map((str, i) => {
                    console.log(str);
                    if (str.startsWith("[") && str.endsWith("]")) {
                      const index = Math.floor(i / 2);
                      return (
                        <Droppable
                          droppableId={"droppable-" + (1000 + index)}
                          key={"droppable-" + (1000 + index)}
                        >
                          {(provided, snapshot) => (
                            <div
                              style={{
                                display: "inline-block",
                                minWidth: "2em",
                                minHeight: "1.5em",
                                fontSize: "1em",
                                color: "black",
                                background: "rgba(244,239,227,0.7)",
                                boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.7)",
                                borderRadius: "5px",
                                padding: "1px 5px",
                                margin: "0px 2px",
                              }}
                              ref={provided.innerRef}
                              {...provided.droppableProps}
                            >
                              {answers[index] !== undefined && (
                                <Draggable
                                  key={1000 + index}
                                  draggableId={"draggable-" + (1000 + index)}
                                  index={0}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      style={{ display: "inline-block" }}
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      {answers[index]}
                                    </div>
                                  )}
                                </Draggable>
                              )}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      );
                    }
                    return <Fragment key={i}>{str}</Fragment>;
                  })}
                </div>
              )}
            </div>
          </div>
        </>
        <HelpMenu helpText={helpText} tipHandler={props.tipHandler} />
        <ExerciseName
          type={props.exercise.type}
          smallMargin={true}
          imageExists={true}
        />
        {renderDragDrop()}
      </DragDropContext>
    </div>
  );
};

export default DragDropOptions;
