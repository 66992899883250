import "./ProfessorEvaluationStudentList.css";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks";
import {
  setEvaluationMenu,
  selectedStudent,
  updateIsStudentFree,
} from "../../../../reducers/teacherSlice";
import { StudentType } from "../../../../_newapios/user/student";
import { m, LazyMotion, motion } from "framer-motion";
import {
  GetStudentCO2API,
  GetStudentPopulationAPI,
  GetStudentWattsAPI,
  GetStudentPointsAPI,
  GetStudentBiodiversityAPI,
  GetStudentActiveItemsAPI,
  GetStudentDestructionAPI,
} from "../../../../_newapios/progress/student_game_metrics.ts";
import { useEffect, useState } from "react";
import _ from "lodash";

import { useTranslation } from "react-i18next";
import ActivitiesIcon from "../../../../assets/professor/activities_icon.tsx";
import islandIcon from "../../../../assets/professor/island_icon.svg";
import keyCompetencesIcon from "../../../../assets/professor/key_competences_icon.svg";
import {
  GetStudentCoursePointsAPI,
  GetStudentScenarioPointsAPI,
  StudentCoursePoints,
} from "../../../../_newapios/progress/student_course_points.ts";
import { GetStudentBundlesAPI } from "../../../../_newapios/content/bundle.ts";
import {
  GetStudentGlobalCompetencesAPI,
  GlobalCompetencePoints,
} from "../../../../_newapios/progress/student_global_competences.ts";
import {
  getGlobalCompetenceAggregates,
  aggregateSubjectCompetences,
} from "../../../utils/evaluation.ts";
import { simpleGlobalCompetences } from "../../../../constants.ts";
import {
  GetStudentSpecificCompetencesAPI,
  SpecificCompetencePoints,
} from "../../../../_newapios/progress/student_specific_competences.ts";
import {
  BundleType,
  GetClassBundlesAPI,
  ModifiedBundleType,
} from "../../../../_newapios/user/class.ts";
import {
  renderKeyCompetencesTop,
  renderNoStudents,
  renderStudentContentActivities,
  renderStudentContentBundles,
  renderStudentContentGlobalCompetences,
  renderStudentContentSpecificCompetences,
  renderStudentContentVillage,
  renderStudentTopActivitiesGeneral,
  renderStudentTopScenarios,
  renderStudentTopTheme1,
  renderStudentTopTheme2,
  renderViewSelector,
  renderDetailedViewName,
  renderKeySubCompetencesTop,
  renderStudentContentGlobalSubCompetences,
  renderSpecSubCompetencesTop,
  renderStudentContentSpecificSubCompetences,
} from "./_StudentListComponents.tsx";
import { ChevronDownIcon } from "@chakra-ui/icons";

const ProfessorEvaluationMenu = ({ ...props }) => {
  const { t } = useTranslation(["common", "evaluation"]);

  const dispatch = useAppDispatch();

  const mainState = useAppSelector((state) => state.main);
  const students = mainState.students;
  const teacherState = useAppSelector((state) => state.teacher);
  const userLicense = teacherState.userLicense;
  const selectedClass = teacherState.selectedClass;
  const courses = mainState.courses;

  const [bundleStudentList, setBundleStudentList] = useState<BundleType[]>([]);

  const views = [
    "activities",
    "village",
    "key_competences_view",
    "specific_competences_view",
  ];

  const [evaluationView, setEvaluationView] = useState<string>(views[0]);

  const [sorting, setSorting] = useState<string>("name");
  const [sortedStudents, setSortedStudents] = useState<StudentType[]>(students);

  const [studentsT1Metrics, setStudentsT1Metrics] = useState<
    {
      population: number;
      power: number;
      co2: number;
      points: number;
    }[]
  >([]);

  const [studentsT2Metrics, setStudentsT2Metrics] = useState<
    {
      biodiversity: number;
      items: number;
      destruction: number;
      points: number;
    }[]
  >([]);

  const [studentsCoursePoints, setStudentsCoursePoints] = useState<{
    [student_id: string]: StudentCoursePoints;
  }>({});

  const [studentsBundles, setStudentsBundles] = useState<{
    [student_id: string]: {
      course_name: string;
      bundles: ModifiedBundleType[];
    }[];
  }>({});
  const [studentCourses, setStudentCourses] = useState<string[]>([]);
  const [selectedCourse, setSelectedCourse] = useState<string | null>(null);
  const [selectedKeyComp, setSelectedKeyComp] = useState<string | null>(null);
  const [selectedSpecCompCourse, setSelectedSpecCompCourse] = useState<
    string | null
  >(null);
  const [selectedScenario, setSelectedScenario] = useState<string | null>(null);
  const [totalSorted, setTotalSorted] = useState<boolean>(false);

  const [globalCompetences, setGlobalCompetences] = useState<
    {
      studentId: string;
      competences: GlobalCompetencePoints;
    }[]
  >([]);

  const [specCompetences, setSpecCompetences] = useState<
    {
      studentId: string;
      competences: SpecificCompetencePoints;
    }[]
  >([]);

  const [scenarioPoints, setScenarioPoints] = useState<{
    [studentId: string]: { [scenarioId: string]: number };
  }>({});

  const [maxStudentId, setMaxStudentId] = useState<string>("");

  const [filteredBundles, setFilteredBundles] = useState<BundleType[]>([]);

  const [detailedView, setDetailedView] = useState<boolean>(false);

  useEffect(() => {
    if (evaluationView === "activities") {
      loadStudentsCoursePoints();
      loadClassBundlesAndScenarioPoints();
    }
    if (evaluationView === "village") {
      if (selectedClass?.theme_id === "theme_id") {
        loadStudentsT1Metrics();
      } else {
        loadStudentsT2Metrics();
      }
    }
    if (evaluationView === "key_competences_view") {
      loadStudentsGlobalCompetences();
    }

    if (evaluationView === "specific_competences_view") {
      loadStudentsSpecificCompetences();
    }
  }, [sortedStudents, evaluationView]);

  useEffect(() => {
    const newFilteredBundles = detailedView
      ? bundleStudentList.filter(
          (bundle) => bundle.scenario?.course.acronym === selectedCourse
        )
      : [];
    setFilteredBundles(newFilteredBundles);
  }, [bundleStudentList, selectedCourse, detailedView]);

  useEffect(() => {
    sortStudents();
  }, [
    students,
    sorting,
    selectedCourse,
    selectedKeyComp,
    selectedSpecCompCourse,
    selectedScenario,
    evaluationView,
  ]);

  const activitiesViewSorting = (student: StudentType) => {
    if (!selectedCourse) {
      return -1;
    }

    let studentHasCourse = studentsBundles[student.id].some(
      (bundle) => bundle.course_name === selectedCourse
    );
    if (!studentHasCourse) {
      return 1;
    }
    let studentPoints = -studentsCoursePoints[student.id][selectedCourse] || 0;
    return studentPoints;
  };

  const scenariosViewSorting = (student: StudentType) => {
    if (!selectedScenario) {
      return -1;
    }
    let studentHasScenario =
      studentsBundles[student.id].find((studBun) => {
        return studBun.bundles.some(
          (bundle) => bundle.scenario?.id === selectedScenario
        );
      }) !== undefined;

    if (!studentHasScenario) {
      return 1;
    }
    let scenarioPointsValue = scenarioPoints[student.id][selectedScenario] || 0;
    return -scenarioPointsValue;
  };

  const totalBundlesViewSorting = (student: StudentType) => {
    return -filteredBundles.reduce((total, bundle) => {
      const scenarioId = bundle.scenario?.id;
      let studentHasCourse = studentsBundles[student.id].some(
        (bundle) => bundle.course_name === selectedCourse
      );
      if (!studentHasCourse) {
        return -1;
      }
      return (
        total +
        (scenarioId && scenarioPoints[student.id]
          ? scenarioPoints[student.id][scenarioId] || 0
          : 0)
      );
    }, 0);
  };

  const keyCompViewSorting = (student: StudentType) => {
    if (!selectedKeyComp) {
      return -1;
    }
    const selectedIndex = simpleGlobalCompetences
      .map((gc) => gc[0])
      .indexOf(selectedKeyComp);
    const studentCompetences: GlobalCompetencePoints =
      globalCompetences.find((gc) => gc.studentId === student.id)
        ?.competences || {};
    const comp = getGlobalCompetenceAggregates(studentCompetences);
    const competenceValues = Object.values(comp);

    const selectedCompetenceScore = competenceValues[selectedIndex] || 0;

    const percentage = (Math.min(selectedCompetenceScore, 12500) / 12500) * 100;

    return -percentage || 0;
  };

  const specCompViewSorting = (student: StudentType) => {
    if (!selectedSpecCompCourse) {
      return -1;
    }
    const studentCompetences: SpecificCompetencePoints =
      specCompetences.find((gc) => gc.studentId === student.id)?.competences ||
      {};
    const { aggregatedCompetences, competenceCounts } =
      aggregateSubjectCompetences(studentCompetences);

    const selectedCompetenceScore =
      aggregatedCompetences[selectedSpecCompCourse] || 0;

    const totalMaxPoints =
      (competenceCounts[selectedSpecCompCourse] || 1) * 750;
    const percentage =
      (Math.min(selectedCompetenceScore, totalMaxPoints) / totalMaxPoints) *
      100;

    return -percentage || 0;
  };

  const sortStudents = () => {
    let sorted_students = _.orderBy(students, [
      (student) => {
        switch (evaluationView) {
          case "activities":
            switch (sorting) {
              case "name":
                return student.username.toLowerCase();
              case "course":
                return activitiesViewSorting(student);
              case "scenarios":
                return scenariosViewSorting(student);
              case "total":
                return totalBundlesViewSorting(student);
            }
            break;
          case "village":
            switch (sorting) {
              case "name":
                return student.username;
              case "points":
                if (selectedClass?.theme_id === "theme_id") {
                  return -studentsT1Metrics[students.indexOf(student)].points;
                } else {
                  return -studentsT2Metrics[students.indexOf(student)].points;
                }
              case "population":
                return -studentsT1Metrics[students.indexOf(student)].population;
              case "power":
                return -studentsT1Metrics[students.indexOf(student)].power;
              case "co2":
                return -studentsT1Metrics[students.indexOf(student)].co2;
              case "biodiversity":
                return -studentsT2Metrics[students.indexOf(student)]
                  .biodiversity;
              case "items":
                return -studentsT2Metrics[students.indexOf(student)].items;
              case "destruction":
                return -studentsT2Metrics[students.indexOf(student)]
                  .destruction;
            }
            break;
          case "key_competences_view":
            switch (sorting) {
              case "name":
                return student.username.toLowerCase();
              case "key_competences":
                return keyCompViewSorting(student);
            }
            break;
          case "specific_competences_view":
            switch (sorting) {
              case "name":
                return student.username.toLowerCase();
              case "sp_competences":
                return specCompViewSorting(student);
            }
            break;

          default:
            return student.username;
        }
      },
    ]);
    setSortedStudents(sorted_students);
  };

  useEffect(() => {
    calcCourses();
  }, [studentsBundles]);

  if (students === null) {
    return;
  }

  const loadClassBundlesAndScenarioPoints = async () => {
    if (!selectedClass) return;

    try {
      const bundles = await GetClassBundlesAPI(selectedClass.id);
      setBundleStudentList(bundles);

      const newScenarioPoints: {
        [studentId: string]: { [scenarioId: string]: number };
      } = {};

      for (const student of sortedStudents) {
        const studentId = student.id;

        newScenarioPoints[studentId] = {};

        for (const bundle of bundles) {
          const scenarioId = bundle.scenario?.id;

          if (scenarioId) {
            const points = await GetStudentScenarioPointsAPI(
              studentId,
              scenarioId
            );

            if (points !== null) {
              newScenarioPoints[studentId][scenarioId] = points;
            }
          }
        }
      }

      setScenarioPoints(newScenarioPoints);
    } catch (error) {
      console.error("Failed to load class bundles or scenario points:", error);
    }
  };

  const loadStudentsCoursePoints = async () => {
    let maxStudent: {
      student_id: string;
      general_value: number;
    } = {
      student_id: "",
      general_value: 0,
    };
    let temp_students_bundles: {
      [student_id: string]: {
        course_name: string;
        bundles: ModifiedBundleType[];
      }[];
    } = {};

    let temp_students_course_points: {
      [student_id: string]: StudentCoursePoints;
    } = {};

    await Promise.all(
      sortedStudents?.map(async (student) => {
        const temp_student_course_points = await GetStudentCoursePointsAPI(
          student.id
        );
        const temp_student_bundles = await GetStudentBundlesAPI(student.id);
        const filtered_courses = courses.filter((course) =>
          temp_student_bundles.some((bundle) => {
            if (bundle.scenario?.course_id === course.id) {
              return true;
            }
          })
        );

        const treated_bundles = filtered_courses.map((course) => {
          let course_bundles = {
            course_name: course.acronym,
            bundles: temp_student_bundles.filter(
              (bundle) => bundle.scenario?.course_id === course.id
            ),
          };
          return course_bundles;
        });

        temp_students_bundles[student.id] = treated_bundles;

        const generalValue = Object.values(temp_student_course_points)
          .map((value) => value)
          .reduce((a, b) => a + b, 0);
        if (generalValue > maxStudent.general_value) {
          maxStudent = {
            student_id: student.id,
            general_value: generalValue,
          };
        }

        temp_students_course_points[student.id] = temp_student_course_points;
      })
    );

    setStudentsCoursePoints(temp_students_course_points);
    setMaxStudentId(maxStudent.student_id);
    setStudentsBundles(temp_students_bundles);
  };

  const calcCourses = () => {
    let courses: string[] = [];
    // studentsCoursePoints.forEach((studentCoursePoints) => {
    //   Object.keys(studentCoursePoints).forEach((course) => {
    //     if (!courses.includes(course)) {
    //       courses.push(course);
    //     }
    //   });
    // });
    Object.values(studentsBundles).forEach((studentBundles) => {
      studentBundles.forEach((bundle) => {
        if (!courses.includes(bundle.course_name)) {
          courses.push(bundle.course_name);
        }
      });
    });
    setStudentCourses(courses);
  };

  const loadStudentsGlobalCompetences = async () => {
    try {
      const studentsData = await Promise.all(
        sortedStudents.map(async (student) => {
          const chartStudentData = await GetStudentGlobalCompetencesAPI(
            student.id
          );
          return chartStudentData;
        })
      );
      const competencesData = sortedStudents.map((student, index) => ({
        studentId: student.id,
        competences: studentsData[index],
      }));
      setGlobalCompetences(competencesData);
    } catch (error) {
      console.error("Failed to load student competences:", error);
    }
  };

  const loadStudentsSpecificCompetences = async () => {
    try {
      const studentsData = await Promise.all(
        sortedStudents.map(async (student) => {
          const temp_student_bundles = await GetStudentBundlesAPI(student.id);
          const filtered_courses = courses.filter((course) =>
            temp_student_bundles.some((bundle) => {
              if (bundle.scenario?.course_id === course.id) {
                return true;
              }
            })
          );

          let temp_student_specific_competences: SpecificCompetencePoints = {};

          for (let i = 0; i < filtered_courses.length; i++) {
            const chartStudentData = await GetStudentSpecificCompetencesAPI(
              student.id,
              filtered_courses[i].id
            );
            temp_student_specific_competences = {
              ...temp_student_specific_competences,
              ...chartStudentData,
            };
          }

          return temp_student_specific_competences;
        })
      );
      const competencesData = sortedStudents.map((student, index) => ({
        studentId: student.id,
        competences: studentsData[index],
      }));
      setSpecCompetences(competencesData);
    } catch (error) {
      console.error("Failed to load student competences:", error);
    }
  };

  const loadStudentsT1Metrics = async () => {
    let maxStudent: {
      student_id: string;
      general_value: number;
    } = {
      student_id: "",
      general_value: 0,
    };
    const temp_students_metrics = await Promise.all(
      sortedStudents?.map(async (student) => {
        const temp_student_metrics = await calcStudentMetrics(student);
        const generalValue =
          temp_student_metrics.population +
          temp_student_metrics.power +
          temp_student_metrics.co2 +
          temp_student_metrics.points;
        if (generalValue > maxStudent.general_value) {
          maxStudent = {
            student_id: student.id,
            general_value: generalValue,
          };
        }

        return temp_student_metrics;
      })
    );
    setStudentsT1Metrics(temp_students_metrics);
    setMaxStudentId(maxStudent.student_id);
  };

  const calcStudentMetrics = async (student: StudentType) => {
    const studentPopulation = await GetStudentPopulationAPI(student.id);
    const studentPower = await GetStudentWattsAPI(student.id);
    const studentCO2 = await GetStudentCO2API(student.id);
    const studentPoints = await GetStudentPointsAPI(student.id);

    const studentPopulationValue = studentPopulation
      ? Math.round(studentPopulation * 100) / 100
      : 0;
    const studentPowerValue = studentPower
      ? Math.round(studentPower * 100) / 100
      : 0;
    const studentCO2Value = studentCO2 ? Math.round(studentCO2 * 100) / 100 : 0;
    const studentPointsValue = studentPoints || 0;

    return {
      population: studentPopulationValue,
      power: studentPowerValue,
      co2: studentCO2Value,
      points: studentPointsValue,
    };
  };

  const loadStudentsT2Metrics = async () => {
    let maxStudent: {
      student_id: string;
      general_value: number;
    } = {
      student_id: "",
      general_value: 0,
    };
    const temp_students_metrics = await Promise.all(
      sortedStudents?.map(async (student) => {
        const temp_student_metrics = await calcStudentT2Metrics(student);
        const generalValue =
          temp_student_metrics.biodiversity +
          temp_student_metrics.items +
          temp_student_metrics.destruction +
          temp_student_metrics.points;
        if (generalValue > maxStudent.general_value) {
          maxStudent = {
            student_id: student.id,
            general_value: generalValue,
          };
        }

        return temp_student_metrics;
      })
    );
    setStudentsT2Metrics(temp_students_metrics);
    setMaxStudentId(maxStudent.student_id);
  };

  const calcStudentT2Metrics = async (student: StudentType) => {
    const studentBiodiversity = await GetStudentBiodiversityAPI(student.id);
    const studentItems = await GetStudentActiveItemsAPI(student.id);
    const studentDestruction = await GetStudentDestructionAPI(student.id);
    const studentPoints = await GetStudentPointsAPI(student.id);

    const studentBiodiversityValue = studentBiodiversity
      ? Math.round(studentBiodiversity * 100) / 100
      : 0;
    const studentItemsValue = studentItems
      ? Math.round(studentItems * 100) / 100
      : 0;
    const studentDestructionValue = studentDestruction
      ? Math.round(studentDestruction * 100) / 100
      : 0;
    const studentPointsValue = studentPoints || 0;

    return {
      biodiversity: studentBiodiversityValue,
      items: studentItemsValue,
      destruction: studentDestructionValue,
      points: studentPointsValue,
    };
  };

  const calcDetailedViewName = () => {
    if (selectedCourse) {
      return selectedCourse;
    }
    if (selectedKeyComp) {
      return selectedKeyComp;
    }
    if (selectedSpecCompCourse) {
      return selectedSpecCompCourse;
    }
    return "";
  };

  const calcDetailedViewBackHandler = () => {
    if (selectedCourse) {
      return courseSelectorHandler;
    }
    if (selectedKeyComp) {
      return keyCompSelectorHandler;
    }
    if (selectedSpecCompCourse) {
      return specCompCourseSelectorHandler;
    }
    return () => {};
  };

  const loadFeatures = () =>
    import("../../../../hooks/framerMotionHooks.ts").then((res) => res.default);

  const renderIcon = (view: string) => {
    switch (view) {
      case "activities":
        return <ActivitiesIcon size={17} color={"black"} />;
      case "village":
        return <img className="icon" src={islandIcon} alt="island_icon" />;
      case "key_competences_view":
        return (
          <img className="icon" src={keyCompetencesIcon} alt="key_comp_icon" />
        );
      case "specific_competences_view":
        return (
          <img className="icon" src={keyCompetencesIcon} alt="sp_comp_icon" />
        );
      default:
        return <></>;
    }
  };

  const studentHandler = (student: StudentType) => {
    dispatch(setEvaluationMenu("generalInfo"));
    dispatch(selectedStudent(student));
    dispatch(updateIsStudentFree(student.id === maxStudentId)); //TODO - when we have the evaluation data for all students, we can update this
  };

  const evaluationViewHandler = (view: string) => {
    setEvaluationView(view);
  };

  const courseSortingHandler = (course: string) => {
    if (selectedCourse === course) {
      setSorting("name");
      setSelectedCourse(null);
      return;
    }

    setSorting("course");
    setSelectedCourse(course);
  };

  const courseSelectorHandler = (course: string | null) => {
    if (selectedCourse === course && detailedView) {
      setDetailedView(false);
      setSelectedCourse(null);
    } else {
      setDetailedView(true);
      setSelectedCourse(course);
    }
    setSelectedScenario(null);
    setTotalSorted(false);
    setSorting("name");
  };

  const specCompCourseSelectorHandler = (course: string | null) => {
    console.log(course);
    if (selectedSpecCompCourse === course && detailedView) {
      setDetailedView(false);
      setSelectedSpecCompCourse(null);
    } else {
      setDetailedView(true);
      setSelectedSpecCompCourse(course);
    }
    setSelectedScenario(null);
    setTotalSorted(false);
    setSorting("name");
  };

  const villageSortingHandler = (sort: string) => {
    if (sorting === sort) {
      setSorting("name");
      return;
    }
    setSorting(sort);
  };

  const keyCompetencesSortingHandler = (comp: string) => {
    if (selectedKeyComp === comp) {
      setSorting("name");
      setSelectedKeyComp(null);
      return;
    }

    setSorting("key_competences");
    setSelectedKeyComp(comp);
  };

  const keyCompSelectorHandler = (comp: string | null) => {
    if (selectedKeyComp === comp && detailedView) {
      setDetailedView(false);
      setSelectedKeyComp(null);
    } else {
      setDetailedView(true);
      setSelectedKeyComp(comp);
    }
    setSorting("name");
  };

  const specCompetencesSortingHandler = (comp: string) => {
    if (selectedSpecCompCourse === comp) {
      setSorting("name");
      setSelectedSpecCompCourse(null);
      return;
    }

    setSorting("sp_competences");
    setSelectedSpecCompCourse(comp);
  };

  const scenarioSortingHandler = (scenario: string) => {
    if (selectedScenario === scenario) {
      setSorting("name");
      setSelectedScenario(null);
      return;
    }

    setSorting("scenarios"); //TODO - change this to scenario?
    setSelectedScenario(scenario);
    setTotalSorted(false);
  };

  const totalSortingHandler = () => {
    if (sorting === "total") {
      setSorting("name");
      setTotalSorted(false);
      return;
    }

    setSorting("total");
    setTotalSorted(true);
    setSelectedScenario(null);
  };

  const renderStudentTop = () => {
    const detailedViewName = calcDetailedViewName();
    const detailedViewBackHandler = calcDetailedViewBackHandler();
    return (
      <div className="professor_evaluation_list_top">
        {!detailedView &&
          renderViewSelector(
            views,
            evaluationView,
            evaluationViewHandler,
            renderIcon,
            t
          )}
        {detailedView &&
          renderDetailedViewName(
            detailedViewName,
            detailedViewBackHandler,
            t,
            loadFeatures
          )}
        {!detailedView &&
          evaluationView === "activities" &&
          renderStudentTopActivitiesGeneral(
            studentCourses,
            evaluationView,
            selectedCourse,
            selectedSpecCompCourse,
            courseSelectorHandler,
            courseSortingHandler,
            specCompCourseSelectorHandler,
            specCompetencesSortingHandler,
            t,
            loadFeatures
          )}
        {detailedView &&
          evaluationView === "activities" &&
          renderStudentTopScenarios(
            filteredBundles,
            selectedScenario,
            scenarioSortingHandler,
            totalSorted,
            totalSortingHandler,
            loadFeatures
          )}
        {evaluationView === "village" &&
          selectedClass?.theme_id === "theme_id" &&
          renderStudentTopTheme1(villageSortingHandler, sorting, t)}
        {evaluationView === "village" &&
          selectedClass?.theme_id === "theme_id_2" &&
          renderStudentTopTheme2(villageSortingHandler, sorting, t)}
        {!detailedView &&
          evaluationView === "key_competences_view" &&
          renderKeyCompetencesTop(
            simpleGlobalCompetences,
            selectedKeyComp,
            keyCompetencesSortingHandler,
            keyCompSelectorHandler,
            t,
            loadFeatures
          )}
        {detailedView &&
          evaluationView === "key_competences_view" &&
          renderKeySubCompetencesTop(
            globalCompetences[0]?.competences ?? {},
            selectedKeyComp,
            t
          )}

        {!detailedView &&
          evaluationView === "specific_competences_view" &&
          renderStudentTopActivitiesGeneral(
            studentCourses,
            evaluationView,
            selectedCourse,
            selectedSpecCompCourse,
            courseSelectorHandler,
            courseSortingHandler,
            specCompCourseSelectorHandler,
            specCompetencesSortingHandler,
            t,
            loadFeatures
          )}
        {detailedView &&
          evaluationView === "specific_competences_view" &&
          renderSpecSubCompetencesTop(
            specCompetences[0]?.competences ?? {},
            selectedSpecCompCourse,
            t
          )}
      </div>
    );
  };

  const renderStudent = (student: StudentType, studentIndex: number) => {
    return (
      <LazyMotion features={loadFeatures}>
        <m.div
          className="professor_evaluation_list_body_student"
          whileHover={{ scale: 1.02 }}
          onClick={() => studentHandler(student)}
        >
          <div className="professor_evaluation_student_name_icon">
            <div className="professor_evaluation_student_icon">
              <div className="center_container">
                <div className="text_white_small_bold">
                  {student.username.charAt(0).toUpperCase()}
                </div>
              </div>
            </div>
            <div
              className="text_black_very_small_bold"
              style={{ marginLeft: "3%" }}
            >
              {student.username}
            </div>
          </div>
          <div className="professor_evaluation_list_body_student_content">
            {!detailedView &&
              evaluationView === "activities" &&
              renderStudentContentActivities(
                studentCourses,
                studentsBundles[student.id],
                studentsCoursePoints[student.id]
              )}
            {detailedView &&
              evaluationView === "activities" &&
              renderStudentContentBundles(
                selectedCourse,
                studentsBundles[student.id],
                studentsCoursePoints[student.id],
                scenarioPoints[student.id],
                filteredBundles
              )}
            {evaluationView === "village" &&
              renderStudentContentVillage(
                studentsT1Metrics[studentIndex],
                studentsT2Metrics[studentIndex],
                selectedClass?.theme_id === "theme_id"
              )}
            {!detailedView &&
              evaluationView === "key_competences_view" &&
              renderStudentContentGlobalCompetences(
                !_.isEmpty(globalCompetences[studentIndex]?.competences)
                  ? Object.values(
                      getGlobalCompetenceAggregates(
                        globalCompetences[studentIndex]?.competences
                      )
                    )
                  : _.fill(Array(simpleGlobalCompetences.length), 0),
                student.id === maxStudentId,
                userLicense?.license.name === "Free"
              )}
            {detailedView &&
              evaluationView === "key_competences_view" &&
              selectedKeyComp &&
              renderStudentContentGlobalSubCompetences(
                Object.keys(
                  globalCompetences[studentIndex]?.competences
                ).filter(
                  (key) =>
                    key.includes(selectedKeyComp) &&
                    key.length - 1 === selectedKeyComp.length
                ),
                globalCompetences[studentIndex]?.competences,
                student.id === maxStudentId,
                userLicense?.license.name === "Free"
              )}

            {!detailedView &&
              evaluationView === "specific_competences_view" &&
              renderStudentContentSpecificCompetences(
                aggregateSubjectCompetences(
                  specCompetences[studentIndex]?.competences
                ).aggregatedCompetences,
                aggregateSubjectCompetences(
                  specCompetences[studentIndex]?.competences
                ).competenceCounts,
                student.id === maxStudentId,
                userLicense?.license.name === "Free"
              )}
            {detailedView &&
              evaluationView === "specific_competences_view" &&
              renderStudentContentSpecificSubCompetences(
                Object.keys(specCompetences[studentIndex]?.competences).filter(
                  (key) =>
                    selectedSpecCompCourse &&
                    key.includes(selectedSpecCompCourse) &&
                    key.length - 1 === selectedSpecCompCourse.length
                ),
                specCompetences[studentIndex]?.competences,
                student.id === maxStudentId,
                userLicense?.license.name === "Free"
              )}
          </div>
        </m.div>
      </LazyMotion>
    );
  };

  return (
    <div className="professor_evaluation_student_list">
      {renderStudentTop()}
      {sortedStudents?.length === 0 && renderNoStudents(t)}
      <div className="professor_evaluation_student_list_body">
        {sortedStudents?.length > 0 &&
          sortedStudents?.map((student, index) => {
            return renderStudent(student, index);
          })}
      </div>
    </div>
  );
};

export default ProfessorEvaluationMenu;
