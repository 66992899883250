import "./TutorialGlobalMap.css";
import { useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../../hooks/hooks.ts";
import { useTranslation } from "react-i18next";
import { m, LazyMotion } from "framer-motion";
import { increaseTutorialStep } from "../../../reducers/studentSlice.ts";
import closeButton from "../../assets/student/tutorial/close.svg";
import confirmButton from "../../assets/student/tutorial/ok.svg";

const TutorialGlobalMap = ({ ...props }) => {
  const { t } = useTranslation();

  const loadFeatures = () =>
    import("../../../hooks/framerMotionHooks.ts").then((res) => res.default);

  const dispatch = useAppDispatch();
  const studentState = useAppSelector((state) => state.student);
  const mainState = useAppSelector((state) => state.main);
  const tutorialStep = studentState.tutorialStep;

  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  const advanceStepHandler = () => {
    dispatch(increaseTutorialStep());
  };

  const renderProfessor = () => {
    return (
      <div className="tutorial_professor">
        <div className="bottom_left_container">
          <img
            className="image"
            style={mainState.ultraPerformanceMode ? { top: "30%" } : {}}
            src={
              window.innerWidth > 991
                ? "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Exercises/professor_big.svg"
                : "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Exercises/professor_cropped.svg"
            }
            alt="professor"
          />
        </div>
      </div>
    );
  };

  const renderBubble = () => {
    if (tutorialStep <= 2) {
      return (
        <LazyMotion features={loadFeatures}>
          <div className="tutorial_bubble">
            <div className="center_container">
              <div className="text_black_big_bold">
                {tutorialStep === 0 ? (
                  <div>
                    <div>{t("tutorial_text_1_1")}</div>
                    <div>{t("tutorial_text_1_2")}</div>
                  </div>
                ) : null}
                {tutorialStep === 1 ? (
                  <div>
                    {t("tutorial_text_2_1")}
                    {t("tutorial_text_2_2")}
                  </div>
                ) : null}
                {tutorialStep === 2 ? <div>{t("tutorial_text_3")}</div> : null}
              </div>
            </div>
            <m.div
              className="tutorial_button"
              onClick={() => advanceStepHandler()}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              transition={{ type: "spring", stiffness: 300, damping: 10 }}
            >
              <div className="center_container">
                <div className="text_white_big_bold">{t("confirm")}</div>
              </div>
            </m.div>
          </div>
        </LazyMotion>
      );
      // } else if (tutorialStep > 2) {
      //   return (
      //     <div className="tutorial_bubble">
      //       <div className="center_container">
      //         <div className="text_black_big_bold">
      //           {tutorialStep === 3 ? (
      //             <div>
      //               <p>{t("tutorial_text_1_1")}</p>
      //               <p>{t("tutorial_text_1_2")}</p>
      //             </div>
      //           ) : null}
      //         </div>
      //       </div>
      //       <div className="tutorial_confirm_buttons">
      //         <div className="center_container">
      //           <div
      //             className="tutorial_button"
      //             onClick={() => confirmPlotHandler()}
      //           >
      //             <div className="center_container">
      //               <img
      //                 className="icon"
      //                 src={confirmButton}
      //                 alt="confirm_button"
      //               />
      //             </div>
      //           </div>
      //         </div>
      //         <div className="center_container">
      //           <div
      //             className="tutorial_button"
      //             onClick={() => props.setTutorial(false)}
      //           >
      //             <div className="center_container">
      //               <img className="icon" src={closeButton} alt="close_button" />
      //             </div>
      //           </div>
      //         </div>
      //       </div>
      //     </div>
      //   );
    }
  };

  return (
    <div>
      {renderProfessor()}
      {renderBubble()}
    </div>
  );
};

export default TutorialGlobalMap;
