import { useTranslation } from "react-i18next";
import "./MUP.css";
import { useEffect, useState } from "react";
import {
  GetAllPowerUpgradesAPI,
  PowerUpgradeType,
} from "../../../_newapios/gamecontent/power_upgrades";
import {
  GetStudentPowerAllAPI,
  StudentPowerType,
} from "../../../_newapios/progress/student_power";
import {
  GetStudentHouseAllAPI,
  StudentHouseAndUpgradeType,
} from "../../../_newapios/progress/student_house";
import { IscreenSize } from "../../../interfaces";

const MUPLocal = ({ ...props }) => {
  const { i18n } = useTranslation();

  const energyLab =
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/MUP/EnergyLab.png";
  const workshop =
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/MUP/Workshop.png";
  const campus =
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/MUP/Campus.png";
  const balloon =
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/MUP/Balloon.png";
  const myHome =
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/MUP/MyHome.png";
  const fair =
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/MUP/Fair.png";
  const casetas =
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/MUP/Casetas.png";
  const namesEsp =
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/MUP/Names_esp.png";
  const namesEng =
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/MUP/Names_eng.png";
  const namesCat =
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/MUP/Names_cat.png";
  const namesVal =
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/MUP/Names_val.png";
  const namesGal =
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/MUP/Names_gal.png";

  const [powerUpgrades, setPowerUpgrades] = useState<PowerUpgradeType[]>([]);
  const [studentPower, setStudentPower] = useState<StudentPowerType[]>([]);
  const [studentHouses, setStudentHouses] = useState<
    StudentHouseAndUpgradeType[]
  >([]);

  useEffect(() => {
    loadProgress();
  }, []);

  const loadProgress = async () => {
    const temp_power_upgrades = await GetAllPowerUpgradesAPI();
    setPowerUpgrades(temp_power_upgrades);
    const temp_student_power = await GetStudentPowerAllAPI(
      props.studentId == null ? props.ownerId : props.studentId
    );
    var temp_student_houses = await GetStudentHouseAllAPI(
      props.studentId == null ? props.ownerId : props.studentId
    );
    setStudentHouses(temp_student_houses);
    setStudentPower(temp_student_power);
  };

  const chooseBiomeGround = () => {
    const biomeName: string = props.biomeName;
    var ground = "";
    var ambient = "";
    switch (biomeName) {
      case "grassland":
        ground =
          "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/MUP/BiomeGrounds/GrasslandGround.png";
        ambient = "";
        break;
      case "desert":
        ground =
          "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/MUP/BiomeGrounds/DesertGround.png";
        ambient =
          "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/MUP/BiomeGrounds/DesertAmbient.png";
        break;
      case "forest":
        ground =
          "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/MUP/BiomeGrounds/ForestGround.png";
        ambient =
          "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/MUP/BiomeGrounds/ForestAmbient.png";
        break;
      case "jungle":
        ground =
          "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/MUP/BiomeGrounds/JungleGround.png";
        ambient =
          "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/MUP/BiomeGrounds/JungleAmbient.png";
        break;
      case "savannah":
        ground =
          "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/MUP/BiomeGrounds/SavannahGround.png";
        ambient =
          "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/MUP/BiomeGrounds/SavannahAmbient.png";
        break;
      case "mountain":
        ground =
          "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/MUP/BiomeGrounds/MountainGround.png";
        ambient =
          "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/MUP/BiomeGrounds/MountainAmbient.png";
        break;
      case "beach":
        ground =
          "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/MUP/BiomeGrounds/BeachGround.png";
        ambient =
          "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/MUP/BiomeGrounds/BeachAmbient.png";
        break;
      case "snow":
        ground =
          "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/MUP/BiomeGrounds/SnowGround.png";
        ambient =
          "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/MUP/BiomeGrounds/SnowAmbient.png";
        break;
      case "swamp":
        ground =
          "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/MUP/BiomeGrounds/SwampGround.png";
        ambient =
          "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/MUP/BiomeGrounds/SwampAmbient.png";
        break;
      case "volcano":
        ground =
          "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/MUP/BiomeGrounds/VolcanoGround.png";
        ambient =
          "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/MUP/BiomeGrounds/VolcanoAmbient.png";
        break;
      default:
        ground =
          "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/MUP/BiomeGrounds/GrasslandGround.png";
        ambient =
          "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/MUP/BiomeGrounds/ForestAmbient.png";
        break;
    }
    return { ground, ambient };
  };

  const renderBiomeGround = () => {
    const { ground, ambient } = chooseBiomeGround();
    return (
      <div className="mup_image_container">
        <div className="mup_image">
          <img src={ground} alt="ground" />
        </div>
        {ambient !== "" && (
          <div className="mup_image" style={{ zIndex: 3 }}>
            <img src={ambient} alt="ambient" />
          </div>
        )}
      </div>
    );
  };

  const renderNames = () => {
    const lang = i18n.language;
    let names = namesEng;
    if (lang === "es") {
      names = namesEsp;
    } else if (lang === "cat") {
      names = namesCat;
    } else if (lang === "val") {
      names = namesVal;
    } else if (lang === "gal") {
      names = namesGal;
    }
    return (
      <div className="mup_image_container">
        <div className="mup_image">
          <img src={names} alt="names" />
        </div>
      </div>
    );
  };

  const renderNonVariableImages = () => {
    return (
      <div className="mup_image_container">
        <div className="mup_image">
          <img src={energyLab} alt="energyLab" />
        </div>
        <div className="mup_image">
          <img src={workshop} alt="workshop" />
        </div>
        <div className="mup_image">
          <img src={campus} alt="campus" />
        </div>
        <div className="mup_image">
          <img src={balloon} alt="balloon" />
        </div>
        <div className="mup_image">
          <img src={myHome} alt="my_home" />
        </div>
        <div className="mup_image">
          <img src={fair} alt="fair" />
        </div>
        <div className="mup_image">
          <img src={casetas} alt="casetas" />
        </div>
      </div>
    );
  };

  const renderPowerUpgrades = () => {
    return (
      <div className="mup_image_container">
        {studentPower.map((power) => {
          const powerUpgrade = powerUpgrades.find(
            (pu) => pu.id === power.power_upgrade_id
          );
          if (powerUpgrade != null) {
            const powerUpgradeImage =
              "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/MUP/EnergyUpgrades/" +
              powerUpgrade.name +
              ".png";
            return (
              <div
                key={power.id}
                className="mup_image"
                style={{
                  zIndex:
                    powerUpgrade.name === "energy_upgrade_solar_name" ? 2 : 1,
                }}
              >
                <img src={powerUpgradeImage} alt="power_upgrade" />
              </div>
            );
          }
          return null;
        })}
      </div>
    );
  };

  const renderHouseUpgrades = () => {
    return (
      <div className="mup_image_container">
        {studentHouses.map((house) => {
          const houseUpgrade = house.house_upgrade;
          if (houseUpgrade != null) {
            const houseUpgradeImage =
              "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/MUP/HouseUpgrades/" +
              houseUpgrade.asset_id +
              ".png";
            return (
              <div key={house.id} className="mup_image">
                <img src={houseUpgradeImage} alt="house_upgrade" />
              </div>
            );
          }
          return null;
        })}
      </div>
    );
  };

  const renderInvisibleButtons = () => {
    return (
      <div className="mup_buttons_container">
        {props.studentId === undefined && (
          <div className="mup_campus_button" onClick={props.campusHandler} />
        )}
        {props.studentId === undefined && (
          <div
            className="mup_energy_lab_button"
            onClick={props.energyLabHandler}
          />
        )}
        {props.studentId === undefined && (
          <div
            className="mup_workshop_button"
            onClick={props.workshopHandler}
          />
        )}
        <div className="mup_balloon_button" onClick={() => props.toggleMap()} />
        {props.studentId === undefined && (
          <div className="mup_my_home_button" onClick={props.homeHandler} />
        )}
      </div>
    );
  };

  return (
    <>
      {renderBiomeGround()}
      {renderPowerUpgrades()}
      {renderHouseUpgrades()}
      {renderNonVariableImages()}
      {renderNames()}
      {renderInvisibleButtons()}
    </>
  );
};

export default MUPLocal;
