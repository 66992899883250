import { TeacherTutorialType } from "./../_newapios/user/teacher";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TeacherType } from "../_newapios/user/teacher";
import { BundleType, ClassType } from "../_newapios/user/class";
import { userLicenseType } from "../_newapios/user/user_license";
import { StudentType } from "../_newapios/user/student";
import { ModifiedScenarioType } from "../_newapios/content/scenario";

export interface SubjectValueType {
  value: string;
  id: string;
  label: string;
  color: string;
  colorScheme: string;
  regulation_id: string;
}

const calcSelectedRegs = (regulationId: string | undefined) => {
  if (regulationId === "regulation_GB" || regulationId === "regulation_GMyS") {
    return [regulationId, "regulation_ESO"];
  }

  return regulationId ? [regulationId] : [];
};

export interface TeacherState {
  sidebarOpen: boolean;
  fullName: string;
  isAdmin: boolean;
  userLicense: userLicenseType | null;
  menu: string;
  submenu: string;
  scenarioMenuParent: string;
  evaluationMenu: string;
  selectedStudent: StudentType | null;
  isStudentFree: boolean;
  classes: ClassType[];
  selectedClass: ClassType | null;
  selectedCourse: number;
  createClassStep: number;
  selectedCompetence: string | null;
  selectedScenarioId: string | null;
  selectedScenario: ModifiedScenarioType | null;
  selectedBundle: BundleType | null;
  deleteOnExit: boolean;
  availableThemes: any;
  authors: TeacherType[];
  globalSearchValue: string | number | readonly string[] | undefined;
  globalRegulationValue: string[];
  globalSubjectValue: SubjectValueType[];
  globalAuthorValue: {
    value: number;
    label: string;
    colorScheme?: string;
  }[];
  globalLanguageValue: {
    value: string;
    label: string;
  }[];

  authorsMyScenarios: TeacherType[];
  globalSearchValueMyScenarios: string | number | readonly string[] | undefined;
  globalRegulationValueMyScenarios: string[];
  globalSubjectValueMyScenarios: SubjectValueType[];
  globalAuthorValueMyScenarios: {
    value: number;
    label: string;
    colorScheme?: string;
  }[];
  globalLanguageValueMyScenarios: {
    value: string;
    label: string;
  }[];
  teacherTutorial: TeacherTutorialType;
  teacherTutorialStep: number;
}

export const initialState: TeacherState = {
  sidebarOpen: true,
  fullName: "",
  isAdmin: false,
  userLicense: null,
  menu: "welcome",
  submenu: "activities",
  scenarioMenuParent: "activities",
  evaluationMenu: "studentList",
  selectedStudent: null,
  isStudentFree: false,
  classes: [],
  selectedClass: null,
  selectedCourse: 0,
  createClassStep: 0,
  selectedCompetence: null,
  selectedScenarioId: null,
  selectedScenario: null,
  selectedBundle: null,
  deleteOnExit: false,
  availableThemes: null,
  authors: [],
  globalSearchValue: "",
  globalRegulationValue: [],
  globalSubjectValue: [],
  globalAuthorValue: [
    {
      value: 2,
      label: "eutopia",
      colorScheme: "blue",
    },
  ],
  globalLanguageValue: [],

  authorsMyScenarios: [],
  globalSearchValueMyScenarios: "",
  globalRegulationValueMyScenarios: [],
  globalSubjectValueMyScenarios: [],
  globalAuthorValueMyScenarios: [],
  globalLanguageValueMyScenarios: [],
  teacherTutorial: null,
  teacherTutorialStep: 0,
};

export const teacherSlice = createSlice({
  name: "teacher",
  initialState,
  reducers: {
    sidebarOpen: (state, action: PayloadAction<boolean>) => {
      state.sidebarOpen = action.payload;
    },
    teacherInfo: (state, action: PayloadAction<TeacherType>) => {
      state.isAdmin = action.payload.admin;
      state.fullName = action.payload.fullname;
    },
    setLicense: (state, action: PayloadAction<userLicenseType>) => {
      state.userLicense = action.payload;
    },
    menu: (state, action: PayloadAction<string>) => {
      state.menu = action.payload;
    },
    submenu: (state, action: PayloadAction<string>) => {
      state.submenu = action.payload;
    },
    setscenarioMenuParent: (state, action: PayloadAction<string>) => {
      state.scenarioMenuParent = action.payload;
    },
    setEvaluationMenu: (state, action: PayloadAction<string>) => {
      state.evaluationMenu = action.payload;
    },
    selectedStudent: (state, action: PayloadAction<StudentType>) => {
      state.selectedStudent = action.payload;
    },
    updateIsStudentFree: (state, action: PayloadAction<boolean>) => {
      state.isStudentFree = action.payload;
    },
    classes: (state, action: PayloadAction<Array<ClassType>>) => {
      state.classes = action.payload;
    },
    updateSelectedClass: (state, action: PayloadAction<ClassType | null>) => {
      state.selectedClass = action.payload;
      state.globalRegulationValue = calcSelectedRegs(
        action.payload?.regulation_id
      );
    },
    updateSelectedCourse: (state, action: PayloadAction<number>) => {
      state.selectedCourse = action.payload;
    },
    changeStepClassCreate: (state, action: PayloadAction<number>) => {
      state.createClassStep = action.payload;
    },
    selectedCompetence: (state, action: PayloadAction<string | null>) => {
      state.selectedCompetence = action.payload;
    },
    updateSelectedScenarioId: (state, action: PayloadAction<string | null>) => {
      state.selectedScenarioId = action.payload;
    },
    updateSelectedScenario: (
      state,
      action: PayloadAction<ModifiedScenarioType | null>
    ) => {
      state.selectedScenario = action.payload;
    },
    updateSelectedBundle: (state, action: PayloadAction<BundleType | null>) => {
      state.selectedBundle = action.payload;
    },
    setDeleteOnExit: (state, action: PayloadAction<boolean>) => {
      state.deleteOnExit = action.payload;
    },
    changeAvailableThemes: (state, action: PayloadAction<any>) => {
      state.availableThemes = action.payload;
    },
    setAuthors: (state, action: PayloadAction<TeacherType[]>) => {
      state.authors = action.payload;
    },
    setGlobalSearchValue: (
      state,
      action: PayloadAction<string | number | string[] | undefined>
    ) => {
      state.globalSearchValue = action.payload;
    },
    setGlobalRegulationValue: (state, action: PayloadAction<string[]>) => {
      state.globalRegulationValue = action.payload;
    },
    setGlobalSubjectValue: (
      state,
      action: PayloadAction<SubjectValueType[]>
    ) => {
      state.globalSubjectValue = action.payload;
    },
    setGlobalAuthorValue: (
      state,
      action: PayloadAction<
        { value: number; label: string; colorScheme?: string }[]
      >
    ) => {
      state.globalAuthorValue = action.payload;
    },
    setGlobalLanguageValue: (
      state,
      action: PayloadAction<{ value: string; label: string }[]>
    ) => {
      state.globalLanguageValue = action.payload;
    },

    setAuthorsMyScenarios: (state, action: PayloadAction<TeacherType[]>) => {
      state.authorsMyScenarios = action.payload;
    },
    setGlobalSearchValueMyScenarios: (
      state,
      action: PayloadAction<string | number | string[] | undefined>
    ) => {
      state.globalSearchValueMyScenarios = action.payload;
    },
    setGlobalRegulationValueMyScenarios: (
      state,
      action: PayloadAction<string[]>
    ) => {
      state.globalRegulationValueMyScenarios = action.payload;
    },
    setGlobalSubjectValueMyScenarios: (
      state,
      action: PayloadAction<SubjectValueType[]>
    ) => {
      state.globalSubjectValueMyScenarios = action.payload;
    },
    setGlobalAuthorValueMyScenarios: (
      state,
      action: PayloadAction<
        { value: number; label: string; colorScheme?: string }[]
      >
    ) => {
      state.globalAuthorValueMyScenarios = action.payload;
    },
    setGlobalLanguageValueMyScenarios: (
      state,
      action: PayloadAction<{ value: string; label: string }[]>
    ) => {
      state.globalLanguageValueMyScenarios = action.payload;
    },
    setTeacherTutorial: (state, action: PayloadAction<TeacherTutorialType>) => {
      state.teacherTutorial = action.payload;
    },
    setTeacherTutorialStep: (state, action: PayloadAction<number>) => {
      state.teacherTutorialStep = action.payload;
    },
  },
});

export const {
  sidebarOpen,
  teacherInfo,
  setLicense,
  menu,
  submenu,
  setscenarioMenuParent,
  setEvaluationMenu,
  selectedStudent,
  updateIsStudentFree,
  classes,
  updateSelectedClass,
  updateSelectedCourse,
  changeStepClassCreate,
  selectedCompetence,
  updateSelectedScenarioId,
  updateSelectedScenario,
  updateSelectedBundle,
  setDeleteOnExit,
  changeAvailableThemes,
  setAuthors,
  setGlobalSearchValue,
  setGlobalRegulationValue,
  setGlobalSubjectValue,
  setGlobalAuthorValue,
  setGlobalLanguageValue,
  setAuthorsMyScenarios,
  setGlobalSearchValueMyScenarios,
  setGlobalRegulationValueMyScenarios,
  setGlobalSubjectValueMyScenarios,
  setGlobalAuthorValueMyScenarios,
  setGlobalLanguageValueMyScenarios,
  setTeacherTutorial,
  setTeacherTutorialStep,
} = teacherSlice.actions;

export default teacherSlice.reducer;
