import "./MainSettings.css";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import UserMenu from "../UserMenu/UserMenu";
import {
  Checkbox,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
} from "@chakra-ui/react";
import {
  GetAllRegulationsAPI,
  RegulationType,
} from "../../../_newapios/content/regulation";
import { motion } from "framer-motion";
import { ClassType, CreateClassAPI } from "../../../_newapios/user/class";
import {
  updateSelectedClass,
  menu,
  classes,
  submenu
} from "../../../reducers/teacherSlice";
import adminIcon from "../../../assets/professor/admin_icon.svg";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { TeacherType } from "../../../_newapios/user/teacher";
import { GetTeachersInSchoolAPI } from "../../../_newapios/user/school";
import { changeStepClassCreate } from "../../../reducers/teacherSlice";

const MainSettings = () => {
  const { t } = useTranslation(["common", "evaluation"]);
  const dispatch = useAppDispatch();

  const professorState = useAppSelector((state) => state.teacher);
  const userLicense = professorState.userLicense;

  const today = new Date();
  var thisYear =
    today.getMonth() <= 7
      ? (today.getFullYear() - 1).toString() +
      "/" +
      today.getFullYear().toString().substring(2)
      : today.getFullYear().toString() +
      "/" +
      (today.getFullYear() + 1).toString().substring(2);
  var nextYear =
    today.getMonth() <= 7
      ? today.getFullYear().toString() +
      "/" +
      (today.getFullYear() + 1).toString().substring(2)
      : (today.getFullYear() + 1).toString() +
      "/" +
      (today.getFullYear() + 2).toString().substring(2);
  var nextNextYear =
    today.getMonth() <= 7
      ? (today.getFullYear() + 1).toString() +
      "/" +
      (today.getFullYear() + 2).toString().substring(2)
      : (today.getFullYear() + 2).toString() +
      "/" +
      (today.getFullYear() + 3).toString().substring(2);

  const [courseName, setcourseName] = useState("");
  const [courseYear, setcourseYear] = useState(thisYear);
  const [courseRegulationId, setcourseRegulationId] = useState("");
  const [courseLanguage, setcourseLanguage] = useState("");
  const [courseSecondLanguage, setcourseSecondLanguage] = useState(" ");
  const [IsErraneousName, setErrorName] = useState(false);
  const [IsErraneousYear, setErrorYear] = useState(false);
  const [IsErraneousLevel, setErrorLevel] = useState(false);
  const [IsErraneousLanguage, setErrorLanguage] = useState(false);
  const [IsErraneousSecondLanguage, setErrorSecondLanguage] = useState(false);
  const [courseRegulations, setCourseRegulations] = useState<RegulationType[]>(
    []
  );
  const [teachers, setTeachers] = useState<TeacherType[]>([]);
  const [selectedTeachers, setSelectedTeachers] = useState<TeacherType[]>([]);

  useEffect(() => {
    setRegulations();
    loadSchoolTeachers();
  }, []);

  const loadSchoolTeachers = async () => {
    const newTeachers = await GetTeachersInSchoolAPI();
    if (newTeachers === null) {
      return;
    }
    setTeachers(newTeachers);
    setSelectedTeachers(newTeachers.filter((teacher) => teacher.admin));
  };

  const setRegulations = async () => {
    const regulations = await GetAllRegulationsAPI();
    setCourseRegulations(regulations);
  };

  const courseHandler = (value: string) => {
    setcourseName(value);
  };

  const yearHandler = (value: string) => {
    setcourseYear(value);
  };

  const levelHandler = (value: string) => {
    setcourseRegulationId(value);
  };

  const languageHandler = (value: string) => {
    setcourseLanguage(value);
  };

  const teacherHandler = (e: any, teacher: TeacherType) => {
    e.preventDefault();
    if (teacher.admin) return;
    if (selectedTeachers.includes(teacher)) {
      setSelectedTeachers(selectedTeachers.filter((t) => t !== teacher));
    } else {
      setSelectedTeachers([...selectedTeachers, teacher]);
    }
  };
  console.log(selectedTeachers);

  const SubmitHandler = async () => {
    if (isInputCorrect()) {
      const selectedTeacherIds = selectedTeachers.map((teacher) => teacher.id);

      const themeId: string | null = userLicense?.license.name === "Free" ? "theme_id" : null;

      const newclass = await CreateClassAPI(
        courseYear,
        courseName,
        courseRegulationId,
        courseLanguage,
        courseSecondLanguage,
        themeId,
        selectedTeacherIds
      );
      if (newclass === null) {
        return;
      }
      const myclasses: ClassType[] = [...professorState.classes];
      myclasses.push({ ...newclass, teacher_in_class: true });
      dispatch(classes(myclasses));
      dispatch(updateSelectedClass(newclass));
      // dispatch(menu("menus"));
      if (userLicense?.license.name === "Free") {
        dispatch(menu("menus"));
        dispatch(submenu("activities"));
      } else {
        dispatch(changeStepClassCreate(1));
      }
    }
  };

  const isInputCorrect = () => {
    var isCorrect = true;

    if (courseName.length < 2) {
      setErrorName(true);
      isCorrect = false;
    } else {
      setErrorName(false);
    }

    if (courseYear.length === 0) {
      setErrorYear(true);
      isCorrect = false;
    } else {
      setErrorYear(false);
    }

    if (courseRegulationId === "") {
      setErrorLevel(true);
      isCorrect = false;
    } else {
      setErrorLevel(false);
    }

    if (courseLanguage === "") {
      setErrorLanguage(true);
      isCorrect = false;
    } else {
      setErrorLanguage(false);
    }

    // if (
    //   courseSecondLanguage === "" ||
    //   courseLanguage === courseSecondLanguage
    // ) {
    //   setErrorSecondLanguage(true);
    //   isCorrect = false;
    // } else {
    //   setErrorSecondLanguage(false);
    // }

    return isCorrect;
  };

  const renderTeacherIcon = (teacher: TeacherType) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {teacher.admin ? (
          <img
            className="image"
            src={adminIcon}
            alt="adminIcon"
            style={{
              maxHeight: "4vh",
            }}
          />
        ) : (
          <div className="professor_credentials_menu_teacher_icon">
            <div className="center_container">
              <div className="text_white_small_bold">
                {teacher.username.charAt(0).toUpperCase()}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderTitle = () => {
    return (
      <div className="class_creation_main_title">
        <div className="left_container">
          <div className="text_black_very_big_bold">
            {t("1. ")}
            {t("class_creation_title_1")}
          </div>
        </div>
      </div>
    );
  };

  const renderUpper = () => {
    return (
      <div className="class_creation_main_center_container">
        <div className="class_creation_main_center">
          <div className="class_creation_main_subcontainer">
            <div className="left_container">
              <div className="text_black_small_bold">
                {t("class_creation_subtitle_1")}
              </div>
            </div>
            <div className="text_field_general_container">
              <motion.div
                whileHover={{
                  scale: 1.02,
                  boxShadow: "0px 0px 10px 1px #5090F0",
                  borderRadius: "5px",
                }}
              >
                <Input
                  placeholder={t("class_creation_placeholder_1")}
                  size="lg"
                  type="text"
                  value={courseName}
                  isInvalid={IsErraneousName}
                  onChange={(event) => {
                    courseHandler(event.target.value);
                  }}
                  style={{
                    width: "100%",
                    fontFamily: "Causten",
                    fontSize: 20,
                    fontWeight: "bold",
                    cursor:
                      'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                  }}
                />
              </motion.div>
            </div>
            <div className="text_label_general_container">
              <div className="top_left_container">
                <div className="text_black_very_small">
                  {t("class_creation_text_1")}
                </div>
              </div>
            </div>
          </div>
          <div className="class_creation_main_subcontainer">
            <div />
            <div className="text_field_general_container">
              <motion.div
                whileHover={{
                  scale: 1.02,
                  boxShadow: "0px 0px 10px 1px #5090F0",
                  borderRadius: "5px",
                }}
              >
                <Select
                  value={courseYear}
                  onChange={(event) => {
                    yearHandler(event.target.value);
                  }}
                  style={{
                    width: "100%",
                    fontFamily: "Causten",
                    fontSize: 20,
                    fontWeight: "bold",
                    cursor:
                      'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                  }}
                  size="lg"
                  isInvalid={IsErraneousYear}
                >
                  <option
                    value={thisYear}
                    style={{
                      color: "#000000",
                      fontFamily: "Causten",
                      fontSize: 20,
                      cursor:
                        'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                    }}
                  >
                    {thisYear}
                  </option>
                  <option
                    value={nextYear}
                    style={{
                      color: "#000000",
                      fontFamily: "Causten",
                      fontSize: 20,
                      cursor:
                        'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                    }}
                  >
                    {nextYear}
                  </option>
                  <option
                    value={nextNextYear}
                    style={{
                      color: "#000000",
                      fontFamily: "Causten",
                      fontSize: 20,
                      cursor:
                        'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                    }}
                  >
                    {nextNextYear}
                  </option>
                </Select>
              </motion.div>
            </div>
            <div className="text_label_general_container">
              <div className="text_black_very_small">
                {t("class_creation_text_2")}
              </div>
            </div>
          </div>
          <div className="class_creation_main_subcontainer">
            <div className="left_container">
              <div className="text_black_small_bold">
                {t("class_creation_subtitle_3")}
              </div>
            </div>

            <div className="text_field_general_container">
              <motion.div
                whileHover={{
                  scale: 1.02,
                  boxShadow: "0px 0px 10px 1px #5090F0",
                  borderRadius: "5px",
                }}
              >
                <Select
                  value={courseRegulationId}
                  placeholder={t("class_creation_placeholder_3")}
                  onChange={(event) => {
                    levelHandler(event.target.value);
                  }}
                  style={{
                    width: "100%",
                    fontFamily: "Causten",
                    fontSize: 20,
                    fontWeight: "bold",
                    color: IsErraneousLevel ? "#d32f2f" : undefined,
                    backgroundColor: IsErraneousLevel ? "#d32f2f25" : undefined,
                    cursor:
                      'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                  }}
                  size="lg"
                >
                  {courseRegulations.map((regulation, _) => {
                    return (
                      <option
                        value={regulation.id}
                        style={{
                          color: "#000000",
                          fontFamily: "Causten",
                          fontSize: 20,
                          cursor:
                            'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                        }}
                      >
                        {t(regulation.country) +
                          ": " +
                          t(regulation.name, { ns: "evaluation" })}
                      </option>
                    );
                  })}
                </Select>
              </motion.div>
            </div>

            <div className="text_label_general_container">
              <div className="text_black_very_small">
                {t("class_creation_text_3")}
              </div>
            </div>
          </div>
          <div className="class_creation_main_subcontainer">
            <div className="left_container">
              <div className="text_black_small_bold">
                {t("class_creation_subtitle_4")}
              </div>
            </div>
            <div className="language_dropdown_container">
              <motion.div
                style={{ height: "fit-content" }}
                whileHover={{
                  scale: 1.02,
                  boxShadow: "0px 0px 10px 1px #5090F0",
                  borderRadius: "5px",
                }}
              >
                <Select
                  value={courseLanguage}
                  placeholder={t("main_language")}
                  onChange={(event) => {
                    languageHandler(event.target.value);
                  }}
                  style={{
                    width: "100%",
                    fontFamily: "Causten",
                    fontSize: 20,
                    fontWeight: "bold",
                    color: IsErraneousLanguage ? "#d32f2f" : undefined,
                    backgroundColor: IsErraneousLanguage
                      ? "#d32f2f25"
                      : undefined,
                    cursor:
                      'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                  }}
                  size="lg"
                >
                  <option
                    value={"en"}
                    style={{
                      color: "#000000",
                      fontFamily: "Causten",
                      fontSize: 20,
                      cursor:
                        'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                    }}
                  >
                    {t("english")}
                  </option>
                  <option
                    value={"es"}
                    style={{
                      color: "#000000",
                      fontFamily: "Causten",
                      fontSize: 20,
                      cursor:
                        'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                    }}
                  >
                    {t("spanish")}
                  </option>
                  <option
                    value={"de"}
                    style={{
                      color: "#000000",
                      fontFamily: "Causten",
                      fontSize: 20,
                      cursor:
                        'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                    }}
                  >
                    {t("deutsch")}
                  </option>
                </Select>
              </motion.div>
            </div>
            <div className="text_label_general_container">
              <div className="text_black_very_small">
                {t("class_creation_text_4")}
              </div>
            </div>
          </div>
        </div>

        <div className="class_creation_main_subcontainer_bottom">
          <div className="left_container">
            <div className="text_black_small_bold">{t("teachers")}</div>
          </div>
          <Menu closeOnSelect={false}>
            <MenuButton
              color="grey"
              style={{
                width: "100%",
                border: "1px solid #cccccc",
                boxSizing: "border-box",
                borderRadius: "5px",
                // position: "relative",
                height: "80%",
                // marginTop: "1%",
                cursor:
                  'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
              }}
              as={motion.div}
              whileHover={{
                scale: 1.02,
                boxShadow: "0px 0px 10px 1px #5090F0",
                borderRadius: "5px",
              }}
            >
              <div
                style={{
                  width: "98%",
                  marginLeft: "1%",
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "1%",
                }}
              >
                <div className="text_black_very_small_bold">
                  {selectedTeachers.length > 0
                    ? selectedTeachers
                      .map((teacher) => teacher.fullname)
                      .join(", ")
                    : t("select_teachers")}
                </div>
                <ChevronDownIcon
                  style={{
                    marginTop: "0.5%",
                  }}
                />
              </div>
            </MenuButton>
            <MenuList
              style={{
                maxHeight: "40vh",
                overflowY: "scroll",
                minWidth: "40vw",
              }}
            >
              {teachers.map((teacher) => {
                return (
                  <MenuItem
                    value={teacher.id}
                    as={motion.div}
                    style={{
                      cursor:
                        'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                    }}
                    onClick={(e) => teacherHandler(e, teacher)}
                    className="class_create_teachers_dropdown_container"
                    whileHover={{
                      // boxShadow: "0px 0px 10px 1px #BCD1EF",
                      backgroundColor: "#BCD1EF50",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: "1vh",
                        fontFamily: "Causten",
                      }}
                    >
                      {renderTeacherIcon(teacher)}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                          gap: "1vh",
                        }}
                      >
                        {teacher.fullname}
                        <Checkbox
                          isChecked={selectedTeachers.includes(teacher)}
                          size="lg"
                          style={{
                            // right: "1vw",
                            // position: "absolute",
                            cursor:
                              'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                          }}
                          onChange={(e) => teacherHandler(e, teacher)}
                        />
                      </div>
                    </div>
                  </MenuItem>
                );
              })}
            </MenuList>
          </Menu>

          {/* <Select
            value={courseSecondLanguage}
            placeholder={t("foreign_language")}
            onChange={(event) => {
              languageSecondHandler(event.target.value);
            }}
            style={{
              width: "100%",
              fontFamily: "Causten",
              fontSize: 20,
              fontWeight: "bold",
              color: IsErraneousSecondLanguage ? "#d32f2f" : undefined,
              backgroundColor: IsErraneousSecondLanguage
                ? "#d32f2f25"
                : undefined,
              cursor:
                'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
            }}
            size="lg"
          >
            <option
              value={"en"}
              style={{
                color: "#000000",
                fontFamily: "Causten",
                fontSize: 20,
                cursor:
                  'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
              }}
            >
              {t("english")}
            </option>
            <option
              value={"es"}
              style={{
                color: "#000000",
                fontFamily: "Causten",
                fontSize: 20,
                cursor:
                  'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
              }}
            >
              {t("spanish")}
            </option>
            <option
              value={"de"}
              style={{
                color: "#000000",
                fontFamily: "Causten",
                fontSize: 20,
                cursor:
                  'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
              }}
            >
              {t("deutsch")}
            </option>
          </Select> */}
        </div>
      </div>
    );
  };

  const renderBottom = () => {
    return (
      <div className="center_container">
        <motion.div
          className="submit_class_button"
          whileHover={{ scale: 1.1, boxShadow: "0px 0px 10px 1px #6EE6A7" }}
          style={{
            cursor:
              'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
          }}
          onClick={() => SubmitHandler()}
          transition={{ type: "spring", stiffness: 400, damping: 10 }}
        >
          <div className="center_container">
            <div className="text_white_small_bold">
              {t("class_creation_button_1")}
            </div>
          </div>
        </motion.div>
      </div>
    );
  };

  return (
    <div className="class_creation_main">
      {renderTitle()}
      {renderUpper()}
      {renderBottom()}
      <UserMenu color={"black"} />
    </div>
  );
};

export default MainSettings;
