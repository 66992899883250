import { useTranslation } from "react-i18next";
import "./TeacherTutorial4.css";
import { Button } from "@chakra-ui/react";
import { LazyMotion, m } from "framer-motion";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks.ts";
import { setTeacherTutorialStep } from "../../../reducers/teacherSlice.ts";
import { FinishTutorialPartAPI } from "../../../_newapios/user/teacher.ts";
import { renderProgressBar } from "./TeacherTutorial1.tsx";


const TeacherTutorial4 = ({ ...props }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const loadFeatures = () =>
        import("../../../hooks/framerMotionHooks.ts").then((res) => res.default);
    const teacherState = useAppSelector((state) => state.teacher);
    const tutorialStep = teacherState.teacherTutorialStep;
    const tutorialPart = teacherState.teacherTutorial;
    const licenseName = teacherState.userLicense?.license.name;
    const isAdmin = teacherState.isAdmin;
    console.log(tutorialPart);

    const handleNext = async () => {
        if (tutorialStep >= 3) {
            dispatch(setTeacherTutorialStep(0));
            await FinishTutorialPartAPI("4");
        }
        dispatch(setTeacherTutorialStep(tutorialStep + 1));
    };

    const renderTutorialWelcome = () => {
        return (
            <div className="teacher_tutorial_1_welcome_background">
                <div className="center_container">
                    <LazyMotion features={loadFeatures}>
                        <div className="teacher_tutorial_1_welcome_container">
                            <div className="text_black_medium_bold">
                                {t("Bienvenido/a a la pestaña de Evaluación")}
                            </div>
                            <div className="text_black_small">
                                {t("Aquí encontrarás toda la información del trabajo realizado por tus alumnos/as dentro de Eutopía")}
                            </div>
                            <Button
                                className="teacher_tutorial_1_welcome_button"
                                as={m.button}
                                whileHover={{
                                    scale: 1.01,
                                    boxShadow: "0px 0px 8px 0px #6ee6a7",
                                }}
                                _hover={{ backgroundColor: "#6ee6a7" }}
                                backgroundColor={"#6ee6a7"}
                                borderRadius={25}
                                size={"lg"}
                                onClick={() => handleNext()}
                            >
                                <div className="text_white_small">{t("lets_go!")}</div>
                            </Button>
                        </div>
                    </LazyMotion>
                </div>
            </div>
        );
    };

    const renderButton = () => {
        const text =
            tutorialStep === 3
                ? t("finish")
                : t("next");
        return (
            <LazyMotion features={loadFeatures}>
                <div className="teacher_tutorial_1_container_bottom">
                    <div className="text_black_very_small">{tutorialStep + "/3"}</div>
                    <Button
                        className="teacher_tutorial_1_welcome_button"
                        as={m.button}
                        whileHover={{
                            scale: 1.01,
                            boxShadow: "0px 0px 8px 0px #5090F0",
                        }}
                        _hover={{ backgroundColor: "#5090F0" }}
                        backgroundColor={"#5090F0"}
                        borderRadius={25}
                        size={"lg"}
                        onClick={() => handleNext()}
                    >
                        <div className="text_white_small">{text}</div>
                    </Button>
                </div>
            </LazyMotion>
        );
    };

    const renderTutorial4Text = () => {
        switch (tutorialStep) {
            case 1:
                return (
                    <div className="text_black_small">
                        <b>{t("tutorial_4_text_1")}</b>
                        <br /> {t("tutorial_4_text_2")}
                    </div>
                );
            case 2:
                return (
                    <div className="text_black_small">
                        <b>{t("tutorial_4_text_3")}</b>
                    </div>
                );
            case 3:
                return (
                    <div className="text_black_small">
                        <b>{t("tutorial_4_text_4")}</b>
                    </div>
                );
            default:
                return null;
        }
    };

    const renderTutorial4 = () => {
        return (
            <div className="teacher_tutorial_1_background">
                <div
                    className={tutorialStep === 1 ? "teacher_tutorial_4_1_background_black_bot" :
                        (tutorialStep === 2 ? "teacher_tutorial_4_2_background_black_bot" :
                            "teacher_tutorial_4_3_background_black_bot")}
                />
                <div
                    className={tutorialStep === 1 ? "teacher_tutorial_4_1_background_black_top" :
                        (tutorialStep === 2 ? "teacher_tutorial_4_2_background_black_top" :
                            "teacher_tutorial_4_3_background_black_top")}
                />
                <div
                    className={tutorialStep === 1 ? "teacher_tutorial_4_1_background_black_variable_left" :
                        (tutorialStep === 2 ? "teacher_tutorial_4_2_background_black_variable_left" :
                            "teacher_tutorial_4_3_background_black_variable_left")}
                />
                <div
                    className={tutorialStep === 1 ? "teacher_tutorial_4_1_background_black_variable_right" :
                        (tutorialStep === 2 ? "teacher_tutorial_4_2_background_black_variable_right" :
                            "teacher_tutorial_4_3_background_black_variable_right")}
                />
                <div className="center_container">
                    <div className={tutorialStep === 1 ? "teacher_tutorial_4_1_container" :
                        (tutorialStep === 2 ? "teacher_tutorial_4_2_container" :
                            "teacher_tutorial_4_3_container")}>
                        <div className="teacher_tutorial_1_container_text">
                            {renderTutorial4Text()}
                            {renderButton()}
                        </div>
                        {renderProgressBar(tutorialStep, 3)}
                    </div>
                </div>
            </div>
        );
    };


    return (
        <>
            {tutorialStep === 0 &&
                teacherState.submenu === "evaluation" &&
                renderTutorialWelcome()}
            {(tutorialStep === 1 || tutorialStep === 2 || tutorialStep === 3) && teacherState.submenu === "evaluation" &&
                renderTutorial4()}
        </>
    );
};

export default TeacherTutorial4;
