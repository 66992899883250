import { TFunction } from "i18next";
import "./ProfessorEvaluationStudentList.css";
import { FeatureBundle, LazyMotion, m } from "framer-motion";
import { ChevronDownIcon, InfoOutlineIcon } from "@chakra-ui/icons";
import BackArrow from "../../../../assets/exercises/back_arrow";
import {
  BundleType,
  ModifiedBundleType,
} from "../../../../_newapios/user/class";
import pointsIcon from "../../../../assets/student/points_icon.svg";
import co2Icon from "../../../../assets/student/co2_icon.svg";
import kilowattsIcon from "../../../../assets/student/kilowatts_icon.svg";
import populationIcon from "../../../../assets/student/population_icon.svg";
import biodiversityIcon from "../../../../assets/student/T2/biodiversity.svg";
import ecosystemsDestructionIcon from "../../../../assets/student/T2/ecosystems_destruction.svg";
import geneticMaterialIcon from "../../../../assets/student/T2/genetic_material.svg";
import { Tooltip } from "@chakra-ui/react";
import _ from "lodash";

import { StudentCoursePoints } from "../../../../_newapios/progress/student_course_points";
import { GlobalCompetencePoints } from "../../../../_newapios/progress/student_global_competences";
import { SpecificCompetencePoints } from "../../../../_newapios/progress/student_specific_competences";

export const renderStudentContentGlobalCompetences = (
  studentDataGlobal: number[],
  isMaxStudent: boolean,
  isLicenseFree: boolean
) => {
  return (
    <div
      className="professor_evaluation_list_body_student_content_data"
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(" + studentDataGlobal.length + ", 15vw)",
        filter: isLicenseFree
          ? isMaxStudent
            ? "blur(0px)"
            : "blur(4px)"
          : "blur(0)",
      }}
    >
      {studentDataGlobal.map((competenceValue: number) => (
        <div className="center_container">
          <div className="text_black_small_bold">
            {((Math.min(competenceValue, 12500) / 12500) * 100).toFixed(2) +
              "%"}
          </div>
        </div>
      ))}
    </div>
  );
};

export const renderStudentContentGlobalSubCompetences = (
  labels: string[],
  values: GlobalCompetencePoints,
  isMaxStudent: boolean,
  isLicenseFree: boolean
) => {
  return (
    <div
      className="professor_evaluation_list_body_student_content_data"
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(" + labels.length + ", 15vw)",
        filter: isLicenseFree
          ? isMaxStudent
            ? "blur(0px)"
            : "blur(4px)"
          : "blur(0)",
      }}
    >
      {labels.map((label) => (
        <div className="center_container">
          {values[label] >= 12500 / labels.length && (
            <div className="text_green_small_bold">
              {(
                (Math.min(values[label], 12500 / labels.length) /
                  (12500 / labels.length)) *
                100
              ).toFixed(2) + "%"}
            </div>
          )}
          {values[label] < 12500 / labels.length && (
            <div className="text_red_small_bold">
              {(
                (Math.min(values[label], 12500 / labels.length) /
                  (12500 / labels.length)) *
                100
              ).toFixed(2) + "%"}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export const renderStudentContentSpecificCompetences = (
  aggregatedCompetences: {
    [subject: string]: number;
  },
  competenceCounts: {
    [subject: string]: number;
  },
  isMaxStudent: boolean,
  isLicenseFree: boolean
) => {
  const numSubjects = Object.keys(aggregatedCompetences).length;

  return (
    <div
      className="professor_evaluation_list_body_student_content_data"
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(" + numSubjects + ", 15vw)",
        filter: isLicenseFree
          ? isMaxStudent
            ? "blur(0px)"
            : "blur(4px)"
          : "blur(0)",
      }}
    >
      {Object.entries(aggregatedCompetences).map(
        ([subject, competenceValue]) => {
          const totalMaxPoints = (competenceCounts[subject] || 1) * 750;
          const percentage = (
            (Math.min(competenceValue, totalMaxPoints) / totalMaxPoints) *
            100
          ).toFixed(2);
          return (
            <div className="center_container">
              <div className="text_black_small_bold">{percentage + "%"}</div>
            </div>
          );
        }
      )}
    </div>
  );
};

export const renderStudentContentSpecificSubCompetences = (
  labels: string[],
  values: SpecificCompetencePoints,
  isMaxStudent: boolean,
  isLicenseFree: boolean
) => {
  console.log(values);
  return (
    <div
      className="professor_evaluation_list_body_student_content_data"
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(" + labels.length + ", 15vw)",
        filter: isLicenseFree
          ? isMaxStudent
            ? "blur(0px)"
            : "blur(4px)"
          : "blur(0)",
      }}
    >
      {labels.map((label) => (
        <div className="center_container">
          {values[label] >= 750 && (
            <div className="text_green_small_bold">
              {((Math.min(values[label], 750) / 750) * 100).toFixed(2) + "%"}
            </div>
          )}
          {values[label] < 750 && (
            <div className="text_red_small_bold">
              {((Math.min(values[label], 750) / 750) * 100).toFixed(2) + "%"}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export const renderStudentContentActivities = (
  studentCourses: string[],
  studentBundles: {
    course_name: string;
    bundles: ModifiedBundleType[];
  }[],
  studentCoursePoints: StudentCoursePoints
) => {
  return (
    <div
      className="professor_evaluation_list_body_student_content_data"
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(" + studentCourses.length + ", 15vw)",
        // filter:
        //   userLicense?.license.name === "Free"
        //     ? student.id === maxStudentId
        //       ? "blur(0px)"
        //       : "blur(4px)"
        //     : "blur(0)",
      }}
    >
      {studentCourses.map((course) => {
        const bundleLength = studentBundles.find(
          (bundle) => bundle.course_name === course
        )?.bundles.length;
        return (
          <div
            style={{
              position: "relative",
              height: "100%",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div className="text_black_small_bold">
              {bundleLength
                ? (studentCoursePoints[course] || 0) +
                  " / " +
                  bundleLength * 100
                : "N/A"}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export const renderStudentContentBundles = (
  selectedCourse: string | null,
  studentBundles: {
    course_name: string;
    bundles: ModifiedBundleType[];
  }[],
  studentCoursePoints: StudentCoursePoints,
  scenarioPoints: { [scenarioId: string]: number },
  filteredBundles: BundleType[]
) => {
  if (!selectedCourse) return <></>;
  const totalPoints = studentCoursePoints[selectedCourse] || 0;
  const bundleLength = studentBundles.find(
    (bundle) => bundle.course_name === selectedCourse
  )?.bundles.length;
  return (
    <div
      className="professor_evaluation_list_body_student_content_data"
      style={{
        display: "grid",
        gridTemplateColumns:
          "repeat(" + (filteredBundles.length + 1) + ", 15vw)",
        // filter:
        //   userLicense?.license.name === "Free"
        //     ? student.id === maxStudentId
        //       ? "blur(0px)"
        //       : "blur(4px)"
        //     : "blur(0)",
      }}
    >
      <div
        style={{
          position: "relative",
          height: "100%",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="text_black_small_bold">
          {bundleLength ? totalPoints + " / " + bundleLength * 100 : "N/A"}
        </div>
      </div>
      {filteredBundles.map((bundle) => {
        const scenarioId = bundle.scenario?.id;
        const isBundleForStudent =
          studentBundles.find((studBuns) =>
            studBuns.bundles.map((b) => b.id).includes(bundle.id)
          ) !== undefined;
        if (!isBundleForStudent) {
          return (
            <div
              style={{
                position: "relative",
                height: "100%",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div className="text_black_small_bold">N/A</div>
            </div>
          );
        }
        return (
          <div
            style={{
              position: "relative",
              height: "100%",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div className="text_black_small_bold">
              {scenarioId && scenarioPoints
                ? scenarioPoints[scenarioId] || 0
                : 0}
              {" / "}
              {100}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export const renderStudentContentVillage = (
  studentT1Metrics: {
    population: number;
    power: number;
    co2: number;
    points: number;
  } | null,
  studentT2Metrics: {
    biodiversity: number;
    items: number;
    destruction: number;
    points: number;
  } | null,
  isTheme1: boolean
) => {
  return (
    <div
      className="professor_evaluation_list_body_student_content_data"
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(4, 25%)",
        // filter:
        //   userLicense?.license.name === "Free"
        //     ? student.id === maxStudentId
        //       ? "blur(0px)"
        //       : "blur(4px)"
        //     : "blur(0)",
      }}
    >
      <div className="center_container">
        <div className="text_black_small_bold">
          {isTheme1 ? studentT1Metrics?.points : studentT2Metrics?.points}
        </div>
      </div>

      <div className="center_container">
        <div className="text_black_small_bold">
          {isTheme1
            ? studentT1Metrics?.population
            : studentT2Metrics?.biodiversity}
        </div>
      </div>
      <div className="center_container">
        <div className="text_black_small_bold">
          {isTheme1 ? studentT1Metrics?.power : studentT2Metrics?.items}
        </div>
      </div>
      <div className="center_container">
        <div className="text_black_small_bold">
          {isTheme1 ? studentT1Metrics?.co2 : studentT2Metrics?.destruction}
        </div>
      </div>
    </div>
  );
};

export const renderNoStudents = (t: TFunction<[string, string], undefined>) => {
  return (
    <div className="professor_evaluation_no_students_container">
      <div className="center_container">
        <img
          src={
            "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Professor/nostudents_evaluation.gif"
          }
          style={{ maxHeight: "45vh" }}
        />
      </div>
      <div className="professor_evaluation_no_students_text">
        <div className="text_black_small_bold">{t("no_students_text_1")}</div>
        <div className="text_black_very_small" style={{ display: "inline" }}>
          {t("no_students_text_2")}
        </div>
        <div
          className="left_container"
          style={{
            display: "inline",
            fontSize: "calc(5px + 0.75vw)",
            fontFamily: "Causten",
          }}
        >
          <div style={{ color: "#000000" }}>{t("no_students_text_3")}</div>
          <m.div
            style={{
              color: "#5090f0",
              textDecoration: "underline",
              cursor:
                'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
            }}
            whileHover={{ textShadow: "0px 0px 10px 0px #5090F0" }}
            transition={{ type: "spring", stiffness: 400, damping: 10 }}
            onClick={() =>
              window.open(
                "https://www.youtube.com/watch?v=bvHiOfRvl-k",
                "_blank"
              )
            }
          >
            {'"' + t("tutorial_how_students_access") + '"'}
          </m.div>
        </div>
      </div>
    </div>
  );
};

export const renderViewSelector = (
  views: string[],
  evaluationView: string,
  evaluationViewHandler: (view: string) => void,
  renderIcon: (view: string) => JSX.Element,
  t: TFunction<[string, string], undefined>
) => {
  return (
    <div
      className="professor_evaluation_list_top_selector"
      style={{
        // gridTemplateColumns: "repeat(" + views.length + ", 1fr)",
        gridTemplateColumns: "repeat(2, 50%)",

        gridTemplateRows: "repeat(2, 50%)",
      }}
    >
      {views.map((view) => {
        return (
          <div
            className="professor_evaluation_list_top_selector_box"
            style={
              view === evaluationView
                ? {
                    backgroundColor: "#E1EDFF",
                    border: "1px solid #5090F0",
                  }
                : {
                    opacity: 0.5,
                    backgroundColor: "#D9D9D9",
                  }
            }
            onClick={() => evaluationViewHandler(view)}
          >
            {renderIcon(view)}
            <div
              className="text_black_super_small_bold"
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {t(view)}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export const renderStudentTopActivitiesGeneral = (
  studentCourses: string[],
  evaluationView: string,
  selectedCourse: string | null,
  selectedSpecCompCourse: string | null,
  courseSelectorHandler: (course: string | null) => void,
  courseSortingHandler: (course: string) => void,
  specCompCourseSelectorHandler: (course: string | null) => void,
  specCompetencesSortingHandler: (course: string) => void,
  t: TFunction<[string, string], undefined>,
  loadFeatures: () => Promise<FeatureBundle>
) => {
  return (
    <LazyMotion features={loadFeatures}>
      <div
        className="professor_evaluation_list_top_content"
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(" + studentCourses.length + ", 15vw)",
        }}
      >
        {studentCourses.map((course) => {
          return (
            <div className=" professor_evaluation_list_top_content_container">
              <m.div
                className="text_black_small_bold"
                style={{
                  marginLeft: "3%",
                  color:
                    (evaluationView === "activities" &&
                      selectedCourse === course) ||
                    (evaluationView === "specific_competences_view" &&
                      selectedSpecCompCourse === course)
                      ? "#5090F0"
                      : "#000000",
                }}
                onClick={() => {
                  if (evaluationView === "activities") {
                    courseSelectorHandler(course);
                  } else if (evaluationView === "specific_competences_view") {
                    specCompCourseSelectorHandler(course);
                  }
                }}
                whileHover={{
                  scale: 1.1,
                }}
              >
                {t(course + "_name", { ns: "evaluation" })}
              </m.div>
              <m.div whileHover={{ scale: 1.5 }}>
                <ChevronDownIcon
                  w={"3vh"}
                  style={{ marginLeft: "3%" }}
                  color={
                    (evaluationView === "activities" &&
                      selectedCourse === course) ||
                    (evaluationView === "specific_competences_view" &&
                      selectedSpecCompCourse === course)
                      ? "#5090F0"
                      : "#000000"
                  }
                  onClick={
                    evaluationView === "activities"
                      ? () => courseSortingHandler(course)
                      : () => specCompetencesSortingHandler(course)
                  }
                />
              </m.div>
            </div>
          );
        })}
      </div>
    </LazyMotion>
  );
};

export const renderDetailedViewName = (
  name: string,
  backHandler: (name: string) => void,
  t: TFunction<[string, string], undefined>,
  loadFeatures: () => Promise<FeatureBundle>
) => {
  return (
    <LazyMotion features={loadFeatures}>
      <div className="professor_evaluation_list_top_subject_name">
        <m.div
          whileHover={{ scale: 1.2 }}
          onClick={() => {
            backHandler(name);
          }}
          style={{
            cursor:
              'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
          }}
        >
          <BackArrow color={"black"} size={"6vh"} />
        </m.div>
        <div
          className="text_black_small_bold"
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {t(name + "_name", { ns: "evaluation" })}
        </div>
      </div>
    </LazyMotion>
  );
};

export const renderKeyCompetencesTop = (
  simpleGlobalCompetences: string[][],
  selectedKeyComp: string | null,
  keyCompetencesSortingHandler: (keyComp: string) => void,
  keyCompSelectorHandler: (keyComp: string) => void,
  t: TFunction<[string, string], undefined>,
  loadFeatures: () => Promise<FeatureBundle>
) => {
  const labels = simpleGlobalCompetences.map((gc) =>
    t(gc[0] + "_label", { ns: "evaluation" })
  );
  return (
    <LazyMotion features={loadFeatures}>
      <div
        className="professor_evaluation_list_top_content"
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(" + labels.length + ", 15vw)",
        }}
      >
        {simpleGlobalCompetences.map((gc, index) => {
          return (
            <div className=" professor_evaluation_list_top_content_container">
              <m.div
                className="text_black_small_bold"
                style={{
                  marginLeft: "3%",
                  color: selectedKeyComp === gc[0] ? "#5090F0" : "#000000",
                }}
                onClick={() => keyCompSelectorHandler(gc[0])}
                whileHover={{
                  scale: 1.1,
                }}
              >
                {t(gc[0] + "_label", { ns: "evaluation" })}
              </m.div>
              <m.div whileHover={{ scale: 1.5 }}>
                <ChevronDownIcon
                  w={"3vh"}
                  style={{ marginLeft: "3%" }}
                  color={selectedKeyComp === gc[0] ? "#5090F0" : "#000000"}
                  onClick={() => keyCompetencesSortingHandler(gc[0])}
                />
              </m.div>
            </div>
          );
        })}
      </div>
    </LazyMotion>
  );
};

export const renderKeySubCompetencesTop = (
  competences: GlobalCompetencePoints,
  selectedKeyComp: string | null,
  t: TFunction<[string, string], undefined>
) => {
  if (selectedKeyComp === null) {
    return <></>;
  }
  const labels = Object.keys(competences).filter(
    (key) =>
      key.includes(selectedKeyComp) && key.length - 1 === selectedKeyComp.length
  );
  return (
    <div
      className="professor_evaluation_list_top_content"
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(" + labels.length + ", 15vw)",
      }}
    >
      {labels.map((label, index) => {
        return (
          <div className=" professor_evaluation_list_top_content_container">
            <div
              className="text_black_small_bold"
              style={{
                marginLeft: "3%",
                color: selectedKeyComp === label ? "#5090F0" : "#000000",
              }}
              onClick={() => {}}
            >
              {t(label + "_desc", { ns: "evaluation" })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export const renderSpecSubCompetencesTop = (
  competences: SpecificCompetencePoints,
  selectedSpecComp: string | null,
  t: TFunction<[string, string], undefined>
) => {
  if (selectedSpecComp === null) {
    return <></>;
  }
  const labels = Object.keys(competences).filter(
    (key) =>
      key.includes(selectedSpecComp) &&
      key.length - 1 === selectedSpecComp.length
  );
  return (
    <div
      className="professor_evaluation_list_top_content"
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(" + labels.length + ", 15vw)",
      }}
    >
      {labels.map((label, index) => {
        return (
          <div className=" professor_evaluation_list_top_content_container">
            <div
              className="text_black_small_bold"
              style={{
                marginLeft: "3%",
              }}
              onClick={() => {}}
            >
              {t(label + "_label", { ns: "evaluation" })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export const renderStudentTopScenarios = (
  filteredBundles: BundleType[],
  selectedScenario: string | null,
  scenarioSortingHandler: (scenario: string) => void,
  totalSorted: boolean,
  totalSortingHandler: () => void,
  loadFeatures: () => Promise<FeatureBundle>
) => {
  return (
    <LazyMotion features={loadFeatures}>
      <div
        className="professor_evaluation_list_top_content"
        style={{
          display: "grid",
          gridTemplateColumns:
            "repeat(" + (filteredBundles.length + 1) + ", 15vw)",
        }}
      >
        <div className="professor_evaluation_list_top_content_container">
          <div
            className="text_black_small_bold"
            style={{
              marginLeft: "3%",
              color: totalSorted ? "#5090F0" : "#000000",
            }}
          >
            Total
          </div>
          <m.div whileHover={{ scale: 1.5 }}>
            <ChevronDownIcon
              w={"3vh"}
              style={{ marginLeft: "3%" }}
              color={totalSorted ? "#5090F0" : "#000000"}
              onClick={() => totalSortingHandler()}
            />
          </m.div>
        </div>
        {filteredBundles.map((bundle) => {
          return (
            <div className=" professor_evaluation_list_top_content_container">
              <div
                className="text_black_small_bold"
                style={{
                  marginLeft: "3%",
                  color:
                    selectedScenario === bundle.scenario?.id
                      ? "#5090F0"
                      : "#000000",
                }}
              >
                {bundle.scenario?.main_know_how}
              </div>
              <m.div whileHover={{ scale: 1.5 }}>
                <ChevronDownIcon
                  w={"3vh"}
                  style={{ marginLeft: "3%" }}
                  color={
                    selectedScenario === bundle.scenario?.id
                      ? "#5090F0"
                      : "#000000"
                  }
                  onClick={() =>
                    scenarioSortingHandler(bundle.scenario?.id || "")
                  }
                />
              </m.div>
            </div>
          );
        })}
      </div>
    </LazyMotion>
  );
};

export const renderStudentTopTheme1 = (
  villageSortingHandler: (sorting: string) => void,
  sorting: string,
  t: TFunction<[string, string], undefined>
) => {
  return (
    <div
      className="professor_evaluation_list_top_content"
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(4, 25%)",
      }}
    >
      <div className=" center_container">
        <img
          className="icon"
          src={pointsIcon}
          alt="icon"
          style={{ maxHeight: "2.5vh" }}
        />
        <div
          className="text_blue_small_bold"
          style={{
            marginLeft: "3%",
            textDecoration: sorting === "points" ? "underline" : "none",
          }}
        >
          {t("points")}
        </div>
        <ChevronDownIcon
          w={"3vh"}
          style={{ marginLeft: "3%" }}
          color={sorting === "points" ? "#5090F0" : "#000000"}
          onClick={() => villageSortingHandler("points")}
        />
        <Tooltip
          hasArrow
          label={t("points_info_tooltip")}
          bg={"#7D7D7D"}
          placement="top"
          sx={{
            fontFamily: "Causten",
            fontSize: "calc(5px + 0.5vw)",
            borderRadius: "5px",
          }}
          aria-label="Points Tooltip"
        >
          <InfoOutlineIcon
            w={"3vh"}
            style={{ marginLeft: "5%" }}
            color={"#5090f0"}
          />
        </Tooltip>
      </div>
      <div className=" center_container">
        <img
          className="icon"
          src={populationIcon}
          alt="icon"
          style={{ maxHeight: "3vh" }}
        />
        <div
          className="text_green_small_bold"
          style={{
            marginLeft: "3%",
            textDecoration: sorting === "population" ? "underline" : "none",
          }}
        >
          {t("population").charAt(0).toUpperCase() + t("population").slice(1)}
        </div>
        <ChevronDownIcon
          w={"3vh"}
          style={{ marginLeft: "3%" }}
          color={sorting === "population" ? "#6ee6a7" : "#000000"}
          onClick={() => villageSortingHandler("population")}
        />
        <Tooltip
          hasArrow
          label={t("population_info_tooltip")}
          bg={"#7D7D7D"}
          placement="top"
          sx={{
            fontFamily: "Causten",
            fontSize: "calc(5px + 0.5vw)",
            borderRadius: "5px",
          }}
          aria-label="Population Tooltip"
        >
          <InfoOutlineIcon
            w={"3vh"}
            style={{ marginLeft: "5%" }}
            color={"#6ee6a7"}
          />
        </Tooltip>
      </div>
      <div className=" center_container">
        <img
          className="icon"
          src={kilowattsIcon}
          alt="icon"
          style={{ maxHeight: "4vh" }}
        />
        <div
          className="text_yellow_small_bold"
          style={{
            marginLeft: "3%",
            textDecoration: sorting === "kilowatts" ? "underline" : "none",
          }}
        >
          {t("kilowatts").charAt(0).toUpperCase() + t("kilowatts").slice(1)}
        </div>
        <ChevronDownIcon
          w={"3vh"}
          style={{ marginLeft: "3%" }}
          color={sorting === "power" ? "#fbc412" : "#000000"}
          onClick={() => villageSortingHandler("power")}
        />
        <Tooltip
          hasArrow
          label={t("kilowatts_info_tooltip")}
          bg={"#7D7D7D"}
          placement="top"
          sx={{
            fontFamily: "Causten",
            fontSize: "calc(5px + 0.5vw)",
            borderRadius: "5px",
          }}
          aria-label="Kw Tooltip"
        >
          <InfoOutlineIcon
            w={"3vh"}
            style={{ marginLeft: "5%" }}
            color={"#fbc412"}
          />
        </Tooltip>
      </div>
      <div className=" center_container">
        <img
          className="icon"
          src={co2Icon}
          alt="icon"
          style={{ maxHeight: "3.5vh" }}
        />
        <div
          className="text_grey_small_bold"
          style={{
            marginLeft: "3%",
            textDecoration: sorting === "co2" ? "underline" : "none",
          }}
        >
          {t("t_co2")}
        </div>
        <ChevronDownIcon
          w={"3vh"}
          style={{ marginLeft: "3%" }}
          color={sorting === "co2" ? "#b9b9b9" : "#000000"}
          onClick={() => villageSortingHandler("co2")}
        />
        <Tooltip
          hasArrow
          label={t("co2_info_tooltip")}
          bg={"#7D7D7D"}
          placement="top"
          sx={{
            fontFamily: "Causten",
            fontSize: "calc(5px + 0.5vw)",
            borderRadius: "5px",
            maxWidth: "19vw",
          }}
          aria-label="Co2 Tooltip"
        >
          <InfoOutlineIcon
            w={"3vh"}
            style={{ marginLeft: "5%" }}
            color={"#b9b9b9"}
          />
        </Tooltip>
      </div>
    </div>
  );
};

export const renderStudentTopTheme2 = (
  villageSortingHandler: (sorting: string) => void,
  sorting: string,
  t: TFunction<[string, string], undefined>
) => {
  return (
    <div
      className="professor_evaluation_list_top_content"
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(4, 25%)",
      }}
    >
      <div className=" center_container">
        <img
          className="icon"
          src={pointsIcon}
          alt="icon"
          style={{ maxHeight: "2.5vh" }}
        />
        <div
          className="text_blue_small_bold"
          style={{
            marginLeft: "3%",
            textDecoration: sorting === "points" ? "underline" : "none",
          }}
        >
          {t("points")}
        </div>
        <ChevronDownIcon
          w={"3vh"}
          style={{ marginLeft: "3%" }}
          color={sorting === "points" ? "#5090F0" : "#000000"}
          onClick={() => villageSortingHandler("points")}
        />
        <Tooltip
          hasArrow
          label={t("points_info_tooltip")}
          bg={"#7D7D7D"}
          placement="top"
          sx={{
            fontFamily: "Causten",
            fontSize: "calc(5px + 0.5vw)",
            borderRadius: "5px",
          }}
          aria-label="Points Tooltip"
        >
          <InfoOutlineIcon
            w={"3vh"}
            style={{ marginLeft: "5%" }}
            color={"#5090f0"}
          />
        </Tooltip>
      </div>
      <div className=" center_container">
        <img
          className="icon"
          src={biodiversityIcon}
          alt="icon"
          style={{ maxHeight: "3vh" }}
        />
        <div
          className="text_green_small_bold"
          style={{
            marginLeft: "3%",
            textDecoration: sorting === "biodiversity" ? "underline" : "none",
          }}
        >
          {t("biodiversity").charAt(0).toUpperCase() +
            t("biodiversity").slice(1)}
        </div>
        <ChevronDownIcon
          w={"3vh"}
          style={{ marginLeft: "3%" }}
          color={sorting === "biodiversity" ? "#5090F0" : "#000000"}
          onClick={() => villageSortingHandler("biodiversity")}
        />
        <Tooltip
          hasArrow
          label={t("biodiversity_info_tooltip")}
          bg={"#7D7D7D"}
          placement="top"
          sx={{
            fontFamily: "Causten",
            fontSize: "calc(5px + 0.5vw)",
            borderRadius: "5px",
          }}
          aria-label="Biodiversity Tooltip"
        >
          <InfoOutlineIcon
            w={"3vh"}
            style={{ marginLeft: "5%" }}
            color={"#6ee6a7"}
          />
        </Tooltip>
      </div>
      <div className=" center_container">
        <img
          className="icon"
          src={geneticMaterialIcon}
          alt="icon"
          style={{ maxHeight: "4vh" }}
        />
        <div
          className="text_yellow_small_bold"
          style={{
            marginLeft: "3%",
            textDecoration: sorting === "items" ? "underline" : "none",
          }}
        >
          {t("items").charAt(0).toUpperCase() + t("items").slice(1)}
        </div>
        <ChevronDownIcon
          w={"3vh"}
          style={{ marginLeft: "3%" }}
          color={sorting === "items" ? "#5090F0" : "#000000"}
          onClick={() => villageSortingHandler("items")}
        />
        <Tooltip
          hasArrow
          label={t("items_info_tooltip")}
          bg={"#7D7D7D"}
          placement="top"
          sx={{
            fontFamily: "Causten",
            fontSize: "calc(5px + 0.5vw)",
            borderRadius: "5px",
          }}
          aria-label="Items Tooltip"
        >
          <InfoOutlineIcon
            w={"3vh"}
            style={{ marginLeft: "5%" }}
            color={"#fbc412"}
          />
        </Tooltip>
      </div>
      <div className=" center_container">
        <img
          className="icon"
          src={ecosystemsDestructionIcon}
          alt="icon"
          style={{ maxHeight: "3.5vh" }}
        />
        <div
          className="text_grey_small_bold"
          style={{
            marginLeft: "3%",
            textDecoration: sorting === "destruction" ? "underline" : "none",
          }}
        >
          {t("destruction").charAt(0).toUpperCase() + t("destruction").slice(1)}
        </div>
        <ChevronDownIcon
          w={"3vh"}
          style={{ marginLeft: "3%" }}
          color={sorting === "destruction" ? "#5090F0" : "#000000"}
          onClick={() => villageSortingHandler("destruction")}
        />
        <Tooltip
          hasArrow
          label={t("destruction_info_tooltip")}
          bg={"#7D7D7D"}
          placement="top"
          sx={{
            fontFamily: "Causten",
            fontSize: "calc(5px + 0.5vw)",
            borderRadius: "5px",
            maxWidth: "19vw",
          }}
          aria-label="Destruction Tooltip"
        >
          <InfoOutlineIcon
            w={"3vh"}
            style={{ marginLeft: "5%" }}
            color={"#b9b9b9"}
          />
        </Tooltip>
      </div>
    </div>
  );
};
