import "./Settings.css";
import { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

import close from "../../assets/student/close_user_menu.svg";
import { IscreenSize } from "../../interfaces";
import {
  setAnimations,
  setPerformanceMode,
  setShadows,
  setUltraPerformanceMode,
  volumeMusic,
  volumeSounds,
} from "../../reducers/mainSlice";
import {
  Box,
  Button,
  ButtonGroup,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
} from "@chakra-ui/react";
import SoundEq from "../../assets/sounds.tsx";

const Settings = ({ ...props }) => {
  const { t, i18n } = useTranslation();

  const dispatch = useAppDispatch();
  var mainState = useAppSelector((state) => state.main);

  const [screenSize, getDimension] = useState<IscreenSize>({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });

  const [shadows, setLocalShadows] = useState(mainState.shadows);
  const [animations, setLocalAnimations] = useState(mainState.animations);
  const [performanceMode, setLocalPerformanceMode] = useState(
    mainState.performanceMode
  );
  const [ultraPerformanceMode, setLocalUltraPerformanceMode] = useState(
    mainState.ultraPerformanceMode
  );
  const [volumeMusicLocal, setVolumeMusic] = useState(
    mainState.volumeMusic * 100
  );
  const [volumeSoundsLocal, setVolumeSounds] = useState(
    mainState.volumeSounds * 100
  );
  const [cursor, setCursor] = useState("left");

  const marks = [
    {
      value: 0,
    },
    {
      value: 100,
    },
  ];

  const handleShadows = (newShadows: boolean) => {
    setLocalShadows(newShadows);
  };

  const handleAnimations = (newAnimations: boolean) => {
    setLocalAnimations(newAnimations);
  };

  const handlePerformanceMode = (newPerformanceMode: boolean) => {
    setLocalPerformanceMode(newPerformanceMode);
  };

  const handleUltraPerformanceMode = (newUltraPerformanceMode: boolean) => {
    setLocalUltraPerformanceMode(newUltraPerformanceMode);
  }

  const handleCursor = (newCursor: string) => {
    setCursor(newCursor);
  };

  const sliderMusicHandler = (value: number) => {
    setVolumeMusic(value);
  };

  const sliderSoundsHandler = (value: number) => {
    setVolumeSounds(value);
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  useEffect(() => {
    dispatch(setShadows(shadows));
    dispatch(setAnimations(animations));
    dispatch(setPerformanceMode(performanceMode));
    dispatch(volumeMusic(volumeMusicLocal / 100));
    dispatch(volumeSounds(volumeSoundsLocal / 100));
    dispatch(setUltraPerformanceMode(ultraPerformanceMode));
  }, [
    shadows,
    animations,
    performanceMode,
    volumeMusicLocal,
    volumeSoundsLocal,
    ultraPerformanceMode,
  ]);

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  return (
    <div className="settings_background">
      <div className="settings_main">
        <div className="settings_close">
          <div className="right_container">
            <motion.img
              className="image"
              src={close}
              alt="close"
              onClick={() => props.handleSettings()}
              whileHover={{ scale: 1.2, rotate: 180 }}
              transition={{
                type: "spring",
                stiffness: 400,
                damping: 10,
                duration: 1,
              }}
            />
          </div>
        </div>
        <div className="settings_header">
          <div className="center_container">
            <div className="text_black_big_bold">{t("settings")}</div>
          </div>
        </div>
        <div className="settings_body">
          <div className="settings_graphics">
            <div className="bottom_center_container">
              <div className="text_black_small">{t("shadows")}</div>
            </div>
            <div className="center_container">
              <ButtonGroup isAttached variant="outline" spacing="6" maxW="60%">
                <Button
                  onClick={() => handleShadows(false)}
                  style={{
                    cursor:
                      'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                    fontFamily: "Causten",
                  }}
                  variant={shadows ? "outline" : "solid"}
                  colorScheme="blue"
                  size="md"
                >
                  {t("no")}
                </Button>
                <Button
                  onClick={() => handleShadows(true)}
                  style={{
                    cursor:
                      'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                    fontFamily: "Causten",
                  }}
                  variant={shadows ? "solid" : "outline"}
                  colorScheme="blue"
                  size="md"
                >
                  {t("yes")}
                </Button>
              </ButtonGroup>
            </div>
          </div>
          <div className="settings_graphics">
            <div className="bottom_center_container">
              <div className="text_black_small">{t("animations")}</div>
            </div>
            <div className="center_container">
              <ButtonGroup isAttached variant="outline" spacing="6" maxW="60%">
                <Button
                  onClick={() => handleAnimations(false)}
                  style={{
                    cursor:
                      'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                    fontFamily: "Causten",
                  }}
                  variant={animations ? "outline" : "solid"}
                  colorScheme="blue"
                  size="md"
                >
                  {t("no")}
                </Button>
                <Button
                  onClick={() => handleAnimations(true)}
                  style={{
                    cursor:
                      'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                    fontFamily: "Causten",
                  }}
                  variant={animations ? "solid" : "outline"}
                  colorScheme="blue"
                  size="md"
                >
                  {t("yes")}
                </Button>
              </ButtonGroup>
            </div>
          </div>
          <div className="settings_graphics">
            <div className="bottom_center_container">
              <div className="text_black_small">{t("performance_mode")}</div>
            </div>
            <div className="center_container">
              <ButtonGroup isAttached variant="outline" spacing="6" maxW="60%">
                <Button
                  onClick={() => handlePerformanceMode(false)}
                  style={{
                    cursor:
                      'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                    fontFamily: "Causten",
                  }}
                  variant={performanceMode ? "outline" : "solid"}
                  colorScheme="blue"
                  size="md"
                >
                  {t("no")}
                </Button>
                <Button
                  onClick={() => handlePerformanceMode(true)}
                  style={{
                    cursor:
                      'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                    fontFamily: "Causten",
                  }}
                  variant={performanceMode ? "solid" : "outline"}
                  colorScheme="blue"
                  size="md"
                >
                  {t("yes")}
                </Button>
              </ButtonGroup>
            </div>
          </div>
          <div className="settings_graphics">
            <div className="bottom_center_container">
              <div className="text_black_small">{t("ultraperformance_mode")}</div>
            </div>
            <div className="center_container">
              <ButtonGroup isAttached variant="outline" spacing="6" maxW="60%">
                <Button
                  onClick={() => handleUltraPerformanceMode(false)}
                  style={{
                    cursor:
                      'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                    fontFamily: "Causten",
                  }}
                  variant={ultraPerformanceMode ? "outline" : "solid"}
                  colorScheme="blue"
                  size="md"
                >
                  {t("no")}
                </Button>
                <Button
                  onClick={() => handleUltraPerformanceMode(true)}
                  style={{
                    cursor:
                      'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                    fontFamily: "Causten",
                  }}
                  variant={ultraPerformanceMode ? "solid" : "outline"}
                  colorScheme="blue"
                  size="md"
                >
                  {t("yes")}
                </Button>
              </ButtonGroup>
            </div>
          </div>
          {/* <div className="settings_cursor">
            <div className="bottom_center_container">
              <div className="text_black_small">{t("cursor_size")}</div>
            </div>
            <div className="center_container">
              <ButtonGroup isAttached variant="outline" spacing="1" maxW="80%">
                <Button
                  onClick={() => handleCursor("left")}
                  style={{
                    cursor:
                      'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                    fontFamily: "Causten",
                  }}
                  variant={cursor === "left" ? "solid" : "outline"}
                  colorScheme="blue"
                  size="md"
                >
                  {t("small")}
                </Button>
                <Button
                  onClick={() => handleCursor("center")}
                  style={{
                    cursor:
                      'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                    fontFamily: "Causten",
                  }}
                  variant={cursor === "center" ? "solid" : "outline"}
                  colorScheme="blue"
                  size="md"
                >
                  {t("medium")}
                </Button>
                <Button
                  onClick={() => handleCursor("right")}
                  style={{
                    cursor:
                      'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                    fontFamily: "Causten",
                  }}
                  variant={cursor === "right" ? "solid" : "outline"}
                  colorScheme="blue"
                  size="md"
                >
                  {t("big")}
                </Button>
              </ButtonGroup>
            </div>
          </div> */}
          <div className="center_container">
            <div className="settings_music">
              <div className="bottom_center_container">
                <div className="text_black_small">{t("music_volume")}</div>
              </div>
              <div className="center_container">
                <Slider
                  id="music_volume_slider"
                  value={volumeMusicLocal}
                  aria-label="Volume"
                  variant={"filled"}
                  min={0}
                  max={100}
                  onChange={sliderMusicHandler}
                  style={{
                    cursor:
                      'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                  }}
                  focusThumbOnChange={false}
                >
                  <SliderTrack>
                    <SliderFilledTrack />
                  </SliderTrack>
                  <SliderThumb
                    boxSize={6}
                    boxShadow={"0px 0px 10px 1px #5090F0"}
                  >
                    <SoundEq color={"#3182CE"} />
                  </SliderThumb>
                </Slider>
              </div>
              {/* <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                                <VolumeDown />
                                <Slider 
                                    id="music_volume_slider"
                                    value={volumeMusicLocal} 
                                    maaria-label="Default" 
                                    valueLabelDisplay="off"
                                    marks={marks}
                                    min={0}
                                    max={100}
                                    onChange={sliderMusicHandler}
                                    style={{ cursor:'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto' }}
                                />
                                <VolumeUp />
                            </Stack> */}
            </div>
            {/* <div className = 'center_container'>
                            <div className = 'text_black_small'>
                                {t("deactivate_music")}
                            </div>
                        </div>
                        <div className = 'center_container'>
                            <FormGroup>
                                <FormControlLabel control={<Switch checked={mutedMusic} onChange={handleMutedMusic} />} label= {mutedMusic ? t("yes") : t("no")} />
                            </FormGroup>
                        </div> */}
          </div>
          <div className="center_container">
            <div className="settings_sounds">
              <div className="bottom_center_container">
                <div className="text_black_small">{t("sounds_volume")}</div>
              </div>
              <div className="center_container">
                <Slider
                  id="sounds_volume_slider"
                  value={volumeSoundsLocal}
                  aria-label="Volume"
                  min={0}
                  max={100}
                  onChange={sliderSoundsHandler}
                  style={{
                    cursor:
                      'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                  }}
                  focusThumbOnChange={false}
                >
                  <SliderTrack>
                    <SliderFilledTrack />
                  </SliderTrack>
                  <SliderThumb
                    boxSize={6}
                    boxShadow={"0px 0px 10px 1px #5090F0"}
                  >
                    <SoundEq color={"#3182CE"} />
                  </SliderThumb>
                </Slider>
              </div>
              {/* <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                                <VolumeDown />
                                <Slider 
                                    id="sounds_volume_slider"
                                    value={volumeSoundsLocal} 
                                    aria-label="Volume" 
                                    valueLabelDisplay="off"
                                    marks={marks}
                                    min={0}
                                    max={100}
                                    onChange={sliderSoundsHandler}
                                    style={{ cursor:'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto' }}
                                />
                                <VolumeUp />
                            </Stack> */}
            </div>
            {/* <div className = 'center_container'>
                            <div className = 'text_black_small'>
                                {t("deactivate_sounds")}
                            </div>
                        </div>
                        <div className = 'center_container'>
                            <FormGroup>
                                <FormControlLabel control={<Switch checked={mutedSounds} onChange={handleMutedSounds} />} label= {mutedSounds ? t("yes") : t("no")} />
                            </FormGroup>
                        </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
