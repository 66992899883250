import { useEffect, useRef, useState } from "react";
import "./MUP.css";
import { BiomeType } from "../../../_newapios/gamecontent/biomes";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { StudentType } from "../../../_newapios/user/student";
import { PlotType } from "../../../_newapios/gamecontent/plots";
import { setChosenPlot } from "../../../reducers/studentSlice";
import { toast } from "../../..";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

const MUPGlobal = ({ ...props }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const globalMapBoard =
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/MUP/Global+Map/Board.png";

  const biomePieces =
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/MUP/Global+Map/BiomePieces.png";

  const globalMapBalloon =
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/MUP/Global+Map/FramedBalloon.png";

  const globalMapStudentHouse =
    "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/MUP/Global+Map/StudentHouse.png";

  const [selectedBiome, setSelectedBiome] = useState<BiomeType | null>(null);
  const mainState = useAppSelector((state) => state.main);
  const myId: string = props.myId;
  const [plots, setPlots] = useState<PlotType[]>([]);
  const [isChoosingPlot, setIsChoosingPlot] = useState(false);
  const loginState = useAppSelector((state) => state.login);
  const me = mainState.students.find((student) => {
    return student.id === loginState.id;
  });

  const [housePosition, setHousePosition] = useState({
    top: "0%",
    right: "0%",
  });

  // const updateHousePosition = () => {
  //   const my_plot = mainState.plots.filter((plot) => me?.plot_id === plot.id);
  //   if (my_plot.length === 0) return;
  //   const biomeButton = document.getElementById(my_plot[0].biome);
  //   if (biomeButton) {
  //     const rect = biomeButton.getBoundingClientRect();
  //     setHousePosition({
  //       top: rect.top - rect.height * 0.75,
  //       left: rect.left + rect.width,
  //     });
  //   }
  // };

  const calcHousePosition = () => {
    const my_plot = mainState.plots.filter((plot) => me?.plot_id === plot.id);
    if (my_plot.length === 0) return;
    switch (my_plot[0].biome) {
      case "grassland":
        setHousePosition({
          top: "14%",
          right: "48.5%",
        });
        break;
      case "desert":
        setHousePosition({ top: "28%", right: "57.5%" });
        break;
      case "forest":
        setHousePosition({ top: "43.5%", right: "28%" });
        break;
      case "swamp":
        setHousePosition({ top: "46%", right: "79.5%" });
        break;
      case "savannah":
        setHousePosition({ top: "20.5%", right: "28%" });
        break;
      case "volcano":
        setHousePosition({ top: "24.5%", right: "15%" });
        break;
      case "beach":
        setHousePosition({ top: "52.5%", right: "40.5%" });
        break;
      case "jungle":
        setHousePosition({ top: "66%", right: "36.5%" });
        break;
      case "mountain":
        setHousePosition({ top: "36%", right: "40%" });
        break;
      case "snow":
        setHousePosition({ top: "33%", right: "49%" });
        break;
    }
  };

  const selectPlotHandler = (biome: BiomeType) => {
    const biomePlots = mainState.plots.filter(
      (plot) => plot.biome_id === biome.id
    );
    const freePlots = biomePlots.filter((plot) => {
      return !mainState.students.find((student) => student.plot_id === plot.id);
    });
    if (freePlots.length > 0) {
      dispatch(setChosenPlot(freePlots[0]));
    } else {
      toast({
        title: t("no_available_plots_in_biome"),
        status: "error",
        duration: 3000,
        variant: "subtle",
        isClosable: true,
      });
    }
  };
  console.log(isChoosingPlot);
  useEffect(() => {
    const me = mainState.students.find(
      (student) => student.id === myId
    ) as StudentType;
    setIsChoosingPlot(props.IS_TEACHER ? false : me.plot_id === null);
  }, [mainState.students]);

  useEffect(() => {
    //calcListSize();
  }, [selectedBiome]);

  useEffect(() => {
    calcPlots();
  }, [mainState.plots, mainState.students, selectedBiome]);

  const calcPlots = () => {
    const plots = mainState.plots
      .filter((plot) =>
        mainState.students.find((student) => student.plot_id === plot.id)
      )
      .filter((plot) => {
        if (!selectedBiome) return true;
        return plot.biome === selectedBiome.name;
      });
    setPlots(plots);
  };

  const calcListLength = (plots: PlotType[]) => {
    const studentsInList = mainState.students.filter((student) => {
      return plots.find((plot) => plot.id === student.plot_id);
    });

    var listLength = 0;
    studentsInList.forEach((student) => {
      if (student.username.length > 0) {
        listLength += Math.ceil(student.username.length / 13);
      }
    });
    console.log(listLength);
    const listType =
      listLength < 3 ? 1 : listLength < 7 ? 2 : listLength < 10 ? 3 : 4;
    switch (listType) {
      case 1:
        return "ListVeryShort";
      case 2:
        return "ListShortMedium";
      case 3:
        return "ListLong";
      default:
        return "ListVeryLong";
    }
  };

  useEffect(() => {
    calcHousePosition();
  }, [mainState.students]);

  const renderGlobalMapImages = () => {
    return (
      <div className="mup_image_container">
        <div className="mup_image">
          <img src={globalMapBoard} alt="globalMapBoard" />
        </div>
        <div className="mup_image">
          <img src={biomePieces} alt="biomePieces" />
        </div>
        <div className="mup_image">
          <img src={globalMapBalloon} alt="globalMapBalloon" />
        </div>
      </div>
    );
  };

  const renderGlobalMapButtons = () => {
    return (
      <div className="mup_buttons_container">
        {selectedBiome !== null && renderPlots()}

        {mainState.biomes.map((biome) => {
          return (
            <div
              id={biome.name}
              className={`mup_${biome.name}_button`}
              onClick={
                isChoosingPlot
                  ? () => selectPlotHandler(biome)
                  : () => setSelectedBiome(biome)
              }
            />
          );
        })}
        {!isChoosingPlot && (
          <div
            className="mup_image"
            style={{
              zIndex: "1",
              top: housePosition.top,
              right: housePosition.right,
              width: "fit-content",
              height: "fit-content",
              cursor:
                'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
            }}
            onClick={() => props.toggleMap(undefined, me?.plot_id)}
          >
            <img
              src={globalMapStudentHouse}
              alt="globalMapStudentHouse"
              style={{
                maxWidth: "8vw",
                maxHeight: "20vh",
              }}
            />
          </div>
        )}
      </div>
    );
  };

  const renderPlots = () => {
    const listImage =
      "https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/MUP/Global+Map/" +
      calcListLength(plots) +
      ".png";
    return (
      <div className="mup_list_container">
        <div
          className="mup_image"
          style={{ height: "auto", position: "relative" }}
        >
          <img id="list" src={listImage} alt="biome" />
        </div>
        <div className="mup_list_names">
          <div className="mup_list_names_container">
            {plots
              .filter((plot) => {
                if (!selectedBiome) return true;
                return plot.biome === selectedBiome.name;
              })
              .map((plot, plotIndex) => {
                const me = mainState.students.find(
                  (student) => student.id === myId
                ) as StudentType;
                const plotIsMine = props.IS_TEACHER
                  ? false
                  : me.plot_id === plot.id;
                const plotOwner = mainState.students.find(
                  (student) => student.plot_id === plot.id
                );
                return (
                  <motion.div
                    className="text_white_small"
                    whileHover={{
                      textShadow: "0px 0px 10px #000000",
                    }}
                    style={{
                      cursor:
                        'url("http://api.elemer.es/cursorscaler/?size=24&type=pointer"), auto',
                      // height:
                      //   Math.ceil((plotOwner?.username.length || 0) / 13) *
                      //     1.5 +
                      //   "em",
                    }}
                    key={plotIndex}
                    transition={{ type: "spring", stiffness: 400, damping: 20 }}
                    onClick={
                      plotIsMine
                        ? () => props.toggleMap(undefined, plot.id)
                        : () => props.toggleMap(plotOwner?.id, plot.id)
                    }
                  >
                    {plotOwner?.username}
                  </motion.div>
                );
              })}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {renderGlobalMapImages()}
      {renderGlobalMapButtons()}
    </>
  );
};

export default MUPGlobal;
