import { useTranslation } from "react-i18next";
import "./TeacherTutorial1.css";
import { Button } from "@chakra-ui/react";
import { LazyMotion, m } from "framer-motion";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks.ts";
import { setTeacherTutorialStep } from "../../../reducers/teacherSlice.ts";
import { FinishTutorialPartAPI } from "../../../_newapios/user/teacher.ts";


export const renderProgressBar = (tutorialStep: number, totalSteps: number) => {
  return (
    <div className="progress_bar">
      <div
        className="progress_bar_fill"
        style={{ width: `${tutorialStep * (100 / totalSteps)}%` }}
      ></div>
    </div>
  );
};

const TeacherTutorial1 = ({ ...props }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const loadFeatures = () =>
    import("../../../hooks/framerMotionHooks.ts").then((res) => res.default);
  const teacherState = useAppSelector((state) => state.teacher);
  const tutorialStep = teacherState.teacherTutorialStep;
  const tutorialPart = teacherState.teacherTutorial;
  const licenseName = teacherState.userLicense?.license.name;
  const isAdmin = teacherState.isAdmin;

  const handleNext = async () => {
    if (tutorialStep >= 6) {
      dispatch(setTeacherTutorialStep(0));
      await FinishTutorialPartAPI("1_2");
    }
    dispatch(setTeacherTutorialStep(tutorialStep + 1));
  };

  const calcVariableWidth = () => {
    switch (tutorialStep) {
      case 2:
        return ["18%", "70%"];
      case 3:
        return ["32%", "58%"];
      case 4:
        return ["43%", "43%"];
      case 5:
        return ["58%", "32%"];
      case 6:
        return ["16%", "55%"];
      default:
        return ["0%", "90%"];
    }
  };

  const renderTutorialWelcome = () => {
    return (
      <div className="teacher_tutorial_1_welcome_background">
        <div className="center_container">
          <LazyMotion features={loadFeatures}>
            <div className="teacher_tutorial_1_welcome_container">
              <div className="text_black_medium_bold">
                {t("tutorial_teacher_welcome")}
              </div>
              <img
                className="image"
                src="https://zynergic-bucket.s3.eu-west-3.amazonaws.com/Assets/Professor/Tutorial/Foto_render_archipielago.png"
                alt="image"
                style={{ width: "60%" }}
              />
              <div className="text_black_small">
                {t("tutorial_teacher_1_1")}
              </div>
              <Button
                className="teacher_tutorial_1_welcome_button"
                as={m.button}
                whileHover={{
                  scale: 1.01,
                  boxShadow: "0px 0px 8px 0px #6ee6a7",
                }}
                _hover={{ backgroundColor: "#6ee6a7" }}
                backgroundColor={"#6ee6a7"}
                borderRadius={25}
                size={"lg"}
                onClick={() => handleNext()}
              >
                <div className="text_white_small">{t("lets_go!")}</div>
              </Button>
            </div>
          </LazyMotion>
        </div>
      </div>
    );
  };

  // const renderProgressBar = () => {
  //   return (
  //     <div className="progress_bar">
  //       <div
  //         className="progress_bar_fill"
  //         style={{ width: `${tutorialStep * (100 / 6)}%` }}
  //       ></div>
  //     </div>
  //   );
  // };

  const renderButton = () => {
    const text =
      tutorialStep === 6
        ? t("finish")
        : tutorialStep === 1
          ? t("understood")
          : t("next");
    return (
      <LazyMotion features={loadFeatures}>
        <div className="teacher_tutorial_1_container_bottom">
          <div className="text_black_very_small">{tutorialStep + "/6"}</div>
          <Button
            className="teacher_tutorial_1_welcome_button"
            as={m.button}
            whileHover={{
              scale: 1.01,
              boxShadow: "0px 0px 8px 0px #5090F0",
            }}
            _hover={{ backgroundColor: "#5090F0" }}
            backgroundColor={"#5090F0"}
            borderRadius={25}
            size={"lg"}
            onClick={() => handleNext()}
          >
            <div className="text_white_small">{text}</div>
          </Button>
        </div>
      </LazyMotion>
    );
  };

  const renderTutorial1_1 = () => {
    return (
      <div className="teacher_tutorial_1_background">
        <div className="teacher_tutorial_1_1_background_black">
          <LazyMotion features={loadFeatures}>
            <div
              className="teacher_tutorial_1_1_container"
              style={isAdmin ? {} : { top: "30%" }}
            >
              <div className="teacher_tutorial_1_container_text">
                <div className="text_black_medium_bold">
                  {licenseName === "Pioneer"
                    ? isAdmin
                      ? t("teacher_tutorial_1_title_1_a")
                      : t("teacher_tutorial_1_title_1_b")
                    : t("teacher_tutorial_1_title_1_c")}
                </div>

                <div className="text_black_small">
                  {licenseName === "Pioneer"
                    ? isAdmin
                      ? t("tutorial_teacher_1_2a")
                      : t("tutorial_teacher_1_2b")
                    : t("tutorial_teacher_1_2c")}
                </div>
                {licenseName === "Pioneer" && (
                  <div className="text_black_small">
                    {isAdmin
                      ? t("tutorial_teacher_1_3a")
                      : t("tutorial_teacher_1_3b")}
                  </div>
                )}
                {renderButton()}
              </div>
              {renderProgressBar(tutorialStep, 6)}
            </div>
          </LazyMotion>
        </div>
      </div>
    );
  };

  const renderTutorial1_2Text = () => {
    switch (tutorialStep) {
      case 2:
        return (
          <div className="text_black_small">
            <b>{t("tutorial_teacher_1_4")}</b>
            <br /> {t("tutorial_teacher_1_5")}
          </div>
        );
      case 3:
        return (
          <div className="text_black_small">
            <b>{t("tutorial_teacher_1_6")}</b>
            <br /> {t("tutorial_teacher_1_7")}
          </div>
        );
      case 4:
        return (
          <div className="text_black_small">
            <b>{t("tutorial_teacher_1_8")}</b>
            <br /> {t("tutorial_teacher_1_9")}
          </div>
        );
      case 5:
        return (
          <div className="text_black_small">
            <b>{t("tutorial_teacher_1_10")}</b>
            <br /> {t("tutorial_teacher_1_11")}
          </div>
        );
      case 6:
        return (
          <div className="text_black_small">{t("tutorial_teacher_1_12")}</div>
        );
      default:
        return null;
    }
  };

  const renderTutorial1_2 = () => {
    return (
      <div className="teacher_tutorial_1_background">
        <div
          className="teacher_tutorial_1_2_background_black_1"
          style={tutorialStep === 6 ? { height: "76%", top: "24%" } : {}}
        />
        <div
          className="teacher_tutorial_1_2_background_black_2"
          style={tutorialStep === 6 ? { height: "16%" } : {}}
        />
        <div
          className="teacher_tutorial_1_2_background_black_variable_1"
          style={
            tutorialStep === 6
              ? { width: calcVariableWidth()[0], height: "8%", top: "16%" }
              : { width: calcVariableWidth()[0] }
          }
        />
        <div
          className="teacher_tutorial_1_2_background_black_variable_2"
          style={
            tutorialStep === 6
              ? { width: calcVariableWidth()[1], height: "8%", top: "16%" }
              : { width: calcVariableWidth()[1] }
          }
        />
        <div className="center_container">
          <div className="teacher_tutorial_1_2_container">
            <div className="teacher_tutorial_1_container_text">
              <div className="text_black_medium_bold">
                {tutorialStep === 2 && t("teacher_tutorial_1_title_2")}
                {tutorialStep === 3 && t("teacher_tutorial_1_title_2")}
                {tutorialStep === 4 && t("teacher_tutorial_1_title_2")}
                {tutorialStep === 5 && t("teacher_tutorial_1_title_2")}
                {tutorialStep === 6 && t("teacher_tutorial_1_title_3")}
              </div>

              {renderTutorial1_2Text()}
              {renderButton()}
            </div>
            {renderProgressBar(tutorialStep, 6)}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {tutorialStep === 0 &&
        teacherState.menu === "welcome" &&
        renderTutorialWelcome()}
      {tutorialStep === 1 &&
        teacherState.menu === "welcome" &&
        renderTutorial1_1()}
      {tutorialPart === "1_2" &&
        teacherState.menu === "menus" &&
        renderTutorial1_2()}
    </>
  );
};

export default TeacherTutorial1;
