import "./Professor.css";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import ProfessorSidebar from "./ProfessorSidebar";
import { useTranslation } from "react-i18next";
import ProfessorWelcome from "./ProfessorWelcome";
import MainSettings from "./class_creation/MainSettings";
import ProfessorMenus from "./menus/ProfessorMenus";
import Cookies from "universal-cookie";
import { useEffect } from "react";
import {
  GetAuthorsAPI,
  GetTeacherTutorialAPI,
} from "../../_newapios/user/teacher";
import {
  setAuthors,
  setTeacherTutorial,
  setTeacherTutorialStep,
} from "../../reducers/teacherSlice";
import { GetAllUnitsGPTAPI } from "../../_newapios/content/unit";
import ThemeSelection from "./class_creation/ThemeSelection";

import { GetAllEutonsAPI } from "../../_newapios/gamecontent/eutons";
import { setEutons } from "../../reducers/mainSlice";
import MarketPlaceMenus from "./marketplace/MarketPlaceMenus";
import WhatsappButton from "./WhatsappButton/WhatsappButton";
import TeacherTutorial1 from "./TeacherTutorials/TeacherTutorial1";
import TeacherTutorial2 from "./TeacherTutorials/TeacherTutorial2";
import TeacherTutorial4 from "./TeacherTutorials/TeacherTutorial4";

const ProfessorDashboard = () => {
  const { i18n } = useTranslation();

  const professorState = useAppSelector((state) => state.teacher);
  const dispatch = useAppDispatch();
  const cookies = new Cookies();
  const userLicense = professorState.userLicense;

  const getScenariosForGPT = async () => {
    const unitsGPT = await GetAllUnitsGPTAPI();
    console.log(unitsGPT);
  };

  const loadAuthors = async () => {
    const authors = await GetAuthorsAPI();
    console.log(authors);
    dispatch(setAuthors(authors));
  };

  const loadEutons = async () => {
    const eutons = await GetAllEutonsAPI();
    dispatch(setEutons(eutons));
  };

  const loadTeacherTutorial = async () => {
    const tutorial = await GetTeacherTutorialAPI();
    if (professorState.teacherTutorialStep === 0) {
      switch (tutorial) {
        case "1_1":
          dispatch(setTeacherTutorialStep(0));
          break;
        case "1_2":
          dispatch(setTeacherTutorialStep(2));
          break;
        case "2_1":
          dispatch(setTeacherTutorialStep(0));
          break;
        case "2_2":
          dispatch(setTeacherTutorialStep(1));
          break;
        case "4":
          dispatch(setTeacherTutorialStep(0));
          break;
        default:
          break;
      }
    }
    console.log(tutorial);
    dispatch(setTeacherTutorial(tutorial));
  };

  useEffect(() => {
    loadAuthors();
    loadEutons();
    getScenariosForGPT();
  }, []);

  useEffect(() => {
    loadTeacherTutorial();
  }, [professorState.teacherTutorialStep]);
  console.log(userLicense?.license)
  return (
    <div
      className={
        professorState.sidebarOpen
          ? "professor_sidebar_open"
          : "professor_sidebar_closed"
      }
    >
      {professorState.sidebarOpen && <ProfessorSidebar />}
      {professorState.menu === "welcome" && <ProfessorWelcome />}
      {professorState.menu === "classCreate" &&
        professorState.createClassStep === 0 && <MainSettings />}
      {professorState.menu === "classCreate" &&
        professorState.createClassStep === 1 && userLicense?.license.name !== "Free" && <ThemeSelection />}
      {professorState.menu === "menus" && <ProfessorMenus />}
      {professorState.menu === "marketplace" && <MarketPlaceMenus />}
      {(professorState.teacherTutorial === "1_1" ||
        professorState.teacherTutorial === "1_2") && <TeacherTutorial1 />}
      {(professorState.teacherTutorial === "2_1" ||
        professorState.teacherTutorial === "2_2") && <TeacherTutorial2 />}
      {(professorState.teacherTutorial === "4") && <TeacherTutorial4 />}
      <WhatsappButton />
    </div>
  );
};

export default ProfessorDashboard;
